import { CountiesRepository } from '../domain/county/CountyRepository';

type GetCountiessUseCase = Pick<CountiesRepository, 'counties' | 'getCounties'>;

const getCounties = (repo: GetCountiessUseCase) => {
  return repo.getCounties();
};

const getCountiesValueWithId = (repo: GetCountiessUseCase) => {
  if (repo.counties === null) return [];
  return repo.counties.map((item, index) => {
    return {
      ...item,
      id: index,
    };
  });
};

export { getCounties, getCountiesValueWithId };
