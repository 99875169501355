import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';

type GetStatusAnafFacturaRepository = Pick<
  E_facturaRepository,
  'getStatusAnafFactura' | 'eFacturaStatuses'
>;

const getStatusAnafFacturaFromCacheUseCase = (
  repo: GetStatusAnafFacturaRepository,
  invoiceId: number,
) => {
  if (repo.eFacturaStatuses !== null) {
    const eFacturaStatus = repo.eFacturaStatuses.find((item) => item.invoiceId === invoiceId);
    if (eFacturaStatus === undefined) return null;
    return eFacturaStatus;
  }
  return null;
};

const getStatusAnafFacturaUseCase = (repo: GetStatusAnafFacturaRepository, invoiceId: number) => {
  return repo.getStatusAnafFactura(invoiceId);
};

export { getStatusAnafFacturaUseCase, getStatusAnafFacturaFromCacheUseCase };
export type { GetStatusAnafFacturaRepository };
