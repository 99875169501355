import React, { FC, ReactElement, useEffect, useState } from 'react';
import { MenuElementCss, MenuSubElementCss } from './MenuElementCss';
import { Text, TextType } from '../dsm/Text';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import './MenuElement.css';
import { useMenuViewModel } from './MenuViewModel';
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrowDown.svg';
import { MenuItems, SubMenuUiItem } from '../../ui-model/menu/MenuUImodel';
import { featuresRepositoryImplementation } from '../../../data/features/FeaturesRepositoryImplementation';
import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import useTranslate from '../../translations/useTranslate';
import { useAuthRepositoryImplementation } from '../../../data/authenticationFlow/authRepositoryImplementation';

export type ProfileMenuProps = {
  name: string;
  isActive?: boolean;
  icon: ReactElement<any, any> | null;
  subMenus: Array<SubMenuUiItem> | null;
};

export const ProfileMenuElement: FC<ProfileMenuProps> = ({ isActive, name, subMenus, icon }) => {
  const translate = useTranslate();
  const authRepo = useAuthRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const { logoutUser } = useMenuViewModel(authRepo, featureRepo);

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const [expanded, setExpanded] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const logout = () => {
    if (logoutUser()) {
      window.location.href = '/';
    }
  };

  return (
    <SubmenuContainer
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
      tabIndex={0}
      onBlur={() => {
        setTimeout(() => {
          isMounted && setExpanded(false);
        }, 100);
      }}
    >
      <MenuElementCss
        className={'profile-item'}
        onClick={() => isMounted && setExpanded(!expanded)}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {icon}
        <TextExt type={TextType.BODY_2}>{translate(name)}</TextExt>
        <ArrowContainer>
          <CustomArrowIcon className={`${expanded && 'open'}`} />
        </ArrowContainer>
      </MenuElementCss>
      <SubmenuItemsConatiner
        className={`${expanded && 'open'}`}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {subMenus !== null &&
          subMenus.map((subMenu, index) => {
            return (
              <NavLinkExtended
                key={index}
                to={subMenu.to !== null ? subMenu.to : ''}
                className={
                  'navigation' +
                  (subMenu.to !== null && isActive
                    ? 'active'
                    : subMenu.menuId === MenuItems.LOGOUT
                    ? 'no-link'
                    : '')
                }
                onClick={() => {
                  if (subMenu.menuId === MenuItems.LOGOUT) {
                    logout();
                  }
                }}
              >
                <MenuSubElementCss className={'profile-item'}>
                  <SelectedIndicator className={'indicator'} />
                  {subMenu.icon}
                  <TextSub type={TextType.BODY_2}>{translate(subMenu.name)}</TextSub>
                </MenuSubElementCss>
              </NavLinkExtended>
            );
          })}
      </SubmenuItemsConatiner>
    </SubmenuContainer>
  );
};

export const NavLinkExtended = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  background-color: var(--dts_white);
  text-decoration: none;
`;

const TextExt = styled(Text)`
  text-align: left;
  vertical-align: top;
  margin-left: 10px;
  flex-grow: 1;
`;

const SelectedIndicator = styled.div`
  width: 4px;
  height: 40px;
  flex: none;
  order: 0;
  flex-grow: 0;

  &:active {
    background: var(--dts_default_blue);
  }
`;

const SubmenuContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: column;
    .profile-item {
      padding: 0px;
      gap: 8px;
    }
    padding-left: 16px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ArrowContainer = styled.span`
  flex-grow: 0;
  padding: 12px;
  line-height: 0;
`;

const CustomArrowIcon = styled(ArrowDownIcon)`
  padding: 5px;
  &.open {
    transform: rotate(180deg);
  }
`;

const SubmenuItemsConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: column;
    max-height: 0px;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    &.open {
      max-height: 200px;
      min-width: 239px;
      transition: max-height 0.3s ease-in;
      box-shadow: -1px 2px 11px 0px #00000024;
    }
    .profile-item {
      display: flex;
      width: 175px;
      align-items: center;
      gap: 16px;
      width: 175px;
    }
    svg {
      fill: var(--dts_black);
    }
    position: absolute;
    z-index: 9999;
    //Desktop
    @media (min-width: ${desktop}px) {
      bottom: 28px;
      left: 0;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      bottom: 28px;
      left: 0;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      bottom: 40px;
      left: 0px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const TextSub = styled(Text)``;
