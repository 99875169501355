import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Text, TextType } from '../components/dsm/Text';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmarkIcon.svg';
import { ReactComponent as RecomandationIcon } from '../../icons/RecomandationIcon.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { Button } from '../components/dsm/buttons/Button';
import {
  BillingType,
  MyCompanySubscriptionUiModel,
  PackageType,
  PackageUiModel,
} from '../ui-model/billing/PackageUiModel';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import BillingUpdateSubscriptionModal from './BillingUpdateSubscriptionModal';
import { useAuth } from '../../routeFiles/AuthContext';

export type BillingCardProps = {
  setRef: (ref: HTMLElement | null) => void;
  index: number;
  pck: PackageUiModel;
  isFti: boolean;
  enableTrial: boolean;
  username: string | undefined;
  recomandation?: number;
  mySubscription: MyCompanySubscriptionUiModel | undefined;
  maxEmployeeNumber: number | undefined;
  laborEmployeeNumber: number | undefined;
  onMouseOver: (index: number) => void;
  updateCompanyPackage: (resp: {
    packageType: PackageType;
    billingType: BillingType;
    billingPeriod: 0;
  }) => void;
};

type BillingNotificationDialog = OpenBillingNotificationDialog | ClosedBillingNotificationDialog;

type OpenBillingNotificationDialog = {
  kind: 'Open';
  title: string;
  packageName: string | undefined;
  description: string;
  endDate: string | undefined;
  actionDescription: string;
  buttonText: string;
  onClose: () => void;
  onClick: (packageType: number, billingType: number) => void;
};

type ClosedBillingNotificationDialog = { kind: 'Closed' };

export const BillingCard: FC<BillingCardProps> = (billingCards) => {
  const {
    setRef,
    index,
    pck,
    isFti,
    enableTrial,
    username,
    recomandation,
    mySubscription,
    maxEmployeeNumber,
    laborEmployeeNumber,
    onMouseOver,
    updateCompanyPackage,
  } = billingCards;

  const translate = useTranslate();
  const navigate = useNavigate();
  const { companyId } = useAuth();

  const [billingDowngradeDialog, setBillingDowngradeDialog] =
    React.useState<BillingNotificationDialog>({
      kind: 'Closed',
    });
  const [billingUpgradeDialog, setBillingUpgradeDialog] = React.useState<BillingNotificationDialog>(
    {
      kind: 'Closed',
    },
  );

  const updatePackageButton = (packageType: number, billingType: number) => {
    updateCompanyPackage({
      packageType: packageType,
      billingType: billingType,
      billingPeriod: 0,
    });
  };

  const closeBillingUpgradeDialog = () => {
    setBillingUpgradeDialog({ kind: 'Closed' });
  };

  const openBillingUpgradeDialog = () => {
    setBillingUpgradeDialog({
      kind: 'Open',
      title: translate(LANGUAGE_KEYS.UPGRADE_SUBSCRIPTION),
      packageName: translate(mySubscription?.title),
      description: translate(LANGUAGE_KEYS.DOWNGRADE_SUBSCRIPTION_DESCRIPTION, {
        user: username,
        employee_number: maxEmployeeNumber,
      }),
      endDate: mySubscription?.formatEndTime,
      actionDescription: '',
      buttonText: translate(LANGUAGE_KEYS.UPGRADE),
      onClose: closeBillingUpgradeDialog,
      onClick: (packageType: number, billingType: number) => {
        updatePackageButton(packageType, billingType);
      },
    });
  };

  const closeBillingDowngradeDialog = () => setBillingDowngradeDialog({ kind: 'Closed' });

  const goToSettingsFunction = () => {
    navigate(`/${companyId}/settings/users/workers`);
  };

  const openBillingDowngradeSettingsDialog = () => {
    setBillingDowngradeDialog({
      kind: 'Open',
      title: translate(LANGUAGE_KEYS.DOWNGRADE_SUBSCRIPTION),
      packageName: translate(mySubscription?.title),
      description: translate(LANGUAGE_KEYS.DOWNGRADE_SUBSCRIPTION_DESCRIPTION, {
        user: username,
        employee_number: maxEmployeeNumber,
      }),
      endDate: mySubscription?.formatEndTime,
      actionDescription: translate(LANGUAGE_KEYS.GO_TO_SETTINGS_DESCRIPTION, {
        max_employees: maxEmployeeNumber,
      }),
      buttonText: translate(LANGUAGE_KEYS.GO_TO_SETTINGS),
      onClose: closeBillingDowngradeDialog,
      onClick: goToSettingsFunction,
    });
  };

  const openBillingDowngradeDialog = () => {
    setBillingDowngradeDialog({
      kind: 'Open',
      title: translate(LANGUAGE_KEYS.DOWNGRADE_SUBSCRIPTION),
      packageName: translate(mySubscription?.title),
      description: translate(LANGUAGE_KEYS.DOWNGRADE_SUBSCRIPTION_DESCRIPTION, {
        user: username,
        employee_number: maxEmployeeNumber,
      }),
      endDate: mySubscription?.formatEndTime,
      actionDescription: translate(LANGUAGE_KEYS.WANT_TO_DOWNGRADE),
      buttonText: translate(LANGUAGE_KEYS.DOWNGRADE),
      onClose: closeBillingDowngradeDialog,
      onClick: (packageType: number, billingType: number) => {
        updatePackageButton(packageType, billingType);
      },
    });
  };

  const getCardButton = (pck: PackageUiModel) => {
    if (
      pck.packageType === recomandation &&
      mySubscription?.billingType === BillingType.TRIAL &&
      !pck.talkAction
    ) {
      return (
        <Button textType={TextType.CAPTION_BOLD} onClick={() => {}}>
          {translate(LANGUAGE_KEYS.DURING_TRYAL, {
            expireday: mySubscription.days,
          })}
        </Button>
      );
    } else if (mySubscription === undefined && !pck.talkAction && enableTrial) {
      return (
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            updatePackageButton(pck.packageType, pck.billingType);
          }}
        >
          {translate(LANGUAGE_KEYS.TRY_BUTTON)}
        </Button>
      );
    } else if (
      pck.packageType !== recomandation &&
      mySubscription?.packageType === PackageType.BASIC &&
      !pck.talkAction
    ) {
      return (
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            openBillingUpgradeDialog();
          }}
        >
          {translate(LANGUAGE_KEYS.UPGRADE)}
        </Button>
      );
    } else if (
      pck.packageType !== recomandation &&
      mySubscription?.packageType === PackageType.STANDARD &&
      !pck.talkAction
    ) {
      return (
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            if (laborEmployeeNumber !== undefined && maxEmployeeNumber !== undefined) {
              if (maxEmployeeNumber > laborEmployeeNumber) {
                openBillingDowngradeDialog();
              } else {
                openBillingDowngradeSettingsDialog();
              }
            }
          }}
        >
          {translate(LANGUAGE_KEYS.DOWNGRADE)}
        </Button>
      );
    } else if (pck.talkAction) {
      return (
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            window.location.href = 'mailto:info@dentech24.com';
          }}
        >
          {translate(LANGUAGE_KEYS.TALK_TO_US)}
        </Button>
      );
    } else {
      return (
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            updatePackageButton(pck.packageType, pck.billingType);
          }}
        >
          {translate(LANGUAGE_KEYS.PRICE_BUTTON, {
            afterprice: pck.afterPrice,
          })}
        </Button>
      );
    }
  };

  return (
    <BillingCardContainer
      ref={(r) => setRef(r)}
      className={`${pck.packageType === recomandation && 'active'} ${pck.talkAction && 'talk'} ${
        pck.packageType === PackageType.PRO && 'pro'
      }`}
      onMouseOver={() => {
        onMouseOver(index);
      }}
    >
      <Text type={TextType.TITLE_BOLD_1} ellipsis={true}>
        {translate(pck.title)}
      </Text>
      <Text type={TextType.BODY_2}>{translate(pck.subtitle)}</Text>
      <Text type={TextType.TITLE_BOLD_4} ellipsis={true}>
        {translate(LANGUAGE_KEYS.PACKIGE_PRICE, {
          packigeprice: pck.price,
          packigedays: pck.numberDays,
        })}
      </Text>
      <Text type={TextType.CAPTION_REGULAR} ellipsis={true}>
        {translate(LANGUAGE_KEYS.AFTERPRICE, {
          afterprice: pck.afterPrice,
        })}
      </Text>
      <div className='features'>
        {pck.features.length > 0 && (
          <PackageFeatures>
            {pck.features?.map((feature, index) => {
              return (
                <Feature key={index}>
                  <CustomCheckmarkIcon width={16} height={16} />
                  <Text type={TextType.CAPTION_REGULAR}>
                    {feature === LANGUAGE_KEYS.FEATURE_EMPLOYE_NUMBER &&
                      translate(feature, {
                        employee_number: maxEmployeeNumber,
                      })}
                    {feature !== LANGUAGE_KEYS.FEATURE_EMPLOYE_NUMBER && translate(feature)}
                  </Text>
                </Feature>
              );
            })}
          </PackageFeatures>
        )}
        {pck.soonFeatures.length > 0 && (
          <PackageFeatures className='soon'>
            {pck.soonFeatures?.map((feature, index) => {
              return (
                <Feature key={index}>
                  <ClockIcon width={12} height={14} />
                  <Text type={TextType.CAPTION_REGULAR}>{translate(feature)}</Text>
                </Feature>
              );
            })}
          </PackageFeatures>
        )}
        <div className='button-container'>{getCardButton(pck)}</div>
      </div>
      {pck.packageType === recomandation && pck.packageType !== PackageType.PRO && isFti && (
        <CustomRecomandationIcon />
      )}

      {billingDowngradeDialog.kind === 'Open' && (
        <BillingUpdateSubscriptionModal
          downgrade={true}
          title={billingDowngradeDialog.title}
          packageName={billingDowngradeDialog.packageName}
          description={billingDowngradeDialog.description}
          endDate={billingDowngradeDialog.endDate}
          actionDescription={billingDowngradeDialog.actionDescription}
          userName={username}
          packageType={pck.packageType}
          billingType={pck.billingType}
          onClose={billingDowngradeDialog.onClose}
          onClick={billingDowngradeDialog.onClick}
          buttonText={billingDowngradeDialog.buttonText}
        />
      )}

      {billingUpgradeDialog.kind === 'Open' && (
        <BillingUpdateSubscriptionModal
          downgrade={false}
          title={billingUpgradeDialog.title}
          packageName={billingUpgradeDialog.packageName}
          description={billingUpgradeDialog.description}
          endDate={billingUpgradeDialog.endDate}
          actionDescription={billingUpgradeDialog.actionDescription}
          userName={username}
          packageType={pck.packageType}
          billingType={pck.billingType}
          onClose={billingUpgradeDialog.onClose}
          onClick={billingUpgradeDialog.onClick}
          buttonText={billingUpgradeDialog.buttonText}
        />
      )}
    </BillingCardContainer>
  );
};

const BillingCardContainer = styled.div`
  min-width: 248px;
  max-width: 248px;
  padding: 36px;
  border: 0.5px solid var(--dts_dark_grey);
  border-radius: 4px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  .button {
    width: 100%;
  }
  position: relative;
  transition: all 0.5s ease;
  &.active {
    transform: translate3d(0px, -1px, 0px) scale(1.09);
    border-color: var(--dts_hover_blue);
  }

  &.talk {
    .button {
      background: var(--dts_panding);
      &:hover {
        opacity: 0.9;
      }
    }
  }
  &.pro {
    background: var(--dts_withe_background);
  }

  .features {
    display: flex;
    gap: 8px;
    flex-direction: column;
    flex-grow: 1;
    .button-container {
      flex: 1 1 20%;
      display: flex;
      align-items: flex-end;
    }
  }
`;

const PackageFeatures = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  &:not(.soon) {
    flex: 1;
  }
  &.soon {
    background: #eea53733;
    gap: 16px;
    padding: 16px;
  }
`;

const Feature = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CustomCheckmarkIcon = styled(CheckmarkIcon)`
  fill: var(--dts_success);
  flex-shrink: 0;
`;

const CustomRecomandationIcon = styled(RecomandationIcon)`
  position: absolute;
  right: 26px;
  top: 12px;
`;
