import type { AnyAction } from 'redux';
import * as actionTypes from './reportActionTypes';
import { ReportRepository } from '../../domain/reports/ReportRepository';

type ReportRepositoryState = Omit<
  ReportRepository,
  'getReportCases' | 'getReportCasesByIds' | 'accountPhases'
>;

const INITIAL_STATE: ReportRepositoryState = {
  reportCases: null,
  isLoading: false,
};

const reportReducer = (state: ReportRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_CASES:
      return { ...state, isLoading: true };
    case actionTypes.GET_REPORT_CASES_SUCCESS:
      return { ...state, isLoading: false, reportCases: action.reportCases };
    case actionTypes.GET_REPORT_CASES_BY_IDS:
      return { ...state, isLoading: true };
    case actionTypes.GET_REPORT_CASES_BY_IDS_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GET_REPORT_CASES_BY_IDS_FAILD:
      return { ...state, isLoading: false };
    case actionTypes.SAVE_ACCOUNT_PHASES:
      return { ...state, isLoading: true };
    case actionTypes.SAVE_ACCOUNT_PHASES_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.SAVE_ACCOUNT_PHASES_FAILD:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
export default reportReducer;
export type { ReportRepositoryState };
