import React from 'react';
import styled from 'styled-components';

import { Text, TextColor, TextType } from '../components/dsm/Text';
import img404 from '../../images/404.png';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ToggleHeader from '../components/generic/ToggleHeader';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { useErrorPageViewModel } from './ErrorPageViewModel';
import { useAuthRepositoryImplementation } from '../../data/authenticationFlow/authRepositoryImplementation';
import { useAuth } from '../../routeFiles/AuthContext';

function Page404() {
  const translate = useTranslate();

  const { isAuthenticated } = useAuth();

  const authRepo = useAuthRepositoryImplementation();

  const { logoutUser } = useErrorPageViewModel(authRepo);

  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();
  return (
    <>
      {isAuthenticated && (isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={false}
          title={undefined}
          buttonText={undefined}
          enabledClick={true}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      <ContentBody>
        <IconConatiner>
          <img src={img404} alt='404 image' />
        </IconConatiner>
        <TextConatiner>
          <Text type={TextType.TITLE_BOLD_2}>{translate(LANGUAGE_KEYS.PAGE_NOT_FOUND_TITLE)}</Text>
          <Text type={TextType.BODY_2}>{translate(LANGUAGE_KEYS.PAGE_NOT_FOUND_BODY)}</Text>
          {isAuthenticated && (
            <Text
              type={TextType.BODY_2}
              clr={TextColor.PRIMARY}
              className='link'
              onClick={() => {
                if (logoutUser()) {
                  window.location.href = '/login';
                }
              }}
            >
              {translate(LANGUAGE_KEYS.PAGE_NOT_FOUND_SIGN_OUT_TEXT)}
            </Text>
          )}
        </TextConatiner>
      </ContentBody>
    </>
  );
}

const ContentBody = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

const IconConatiner = styled.div`
  margin-bottom: 48px;
  img {
    max-width: 100%;
  }
`;

const TextConatiner = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    align-self: center;

    &.link {
      cursor: pointer;
      &:hover {
        color: var(--dts_hover_blue);
      }
    }
  }
`;

export default Page404;
