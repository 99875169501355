import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Text, TextType } from '../components/dsm/Text';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { LobbyEmptyView } from './LobbyEmptyView';
import { LobbyCard } from './LobbyCard';
import { LobbyCardsType } from '../ui-model/lobby/LobbyUiModel';
import { lobbyRepositoryImplementation } from '../../data/lobby/lobbyRepositoryImplementation';
import { useLobbyViewModel } from './LobbyViewModel';
import { useContactsRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import ToggleHeader from '../components/generic/ToggleHeader';
import ContentLayout from '../components/layout/ContentLayout';
import DesktopHeader from '../components/generic/DesktopHeader';
import { useAuthRepositoryImplementation } from '../../data/authenticationFlow/authRepositoryImplementation';

function Lobby() {
  const repo = lobbyRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const authRepo = useAuthRepositoryImplementation();
  const { userName, lobbyCompanies, answerWorkerInvitation } = useLobbyViewModel(
    repo,
    authRepo,
    contactRepo,
  );
  const navigate = useNavigate();
  const {
    isTabletSmall,
    isTabletLarge,
    isMobile,
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
  } = useDeviceParameters();

  const onClickViewPlans = () => {};

  const onClickCreateNewLab = () => {
    navigate('/plans');
  };

  const onclickEnter = (companyId: number) => {
    navigate(`/${companyId}`);
  };

  const onClickDecline = (companyId: number) => {
    answerWorkerInvitation({ companyId: companyId, apply: false });
  };

  const onClickAccept = (companyId: number) => {
    answerWorkerInvitation({ companyId: companyId, apply: true });
  };

  const translate = useTranslate();

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={'Welcome ' + userName + ' !'}
          buttonText=''
          enabledClick={false}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={'Welcome ' + userName + ' !'}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={<PlusIcon />}
        />
      )}
      <ContentLayout>
        <Text type={TextType.BODY_BOLD} ellipsis={true}>
          {translate(LANGUAGE_KEYS.MY_LABORATORIES)}
        </Text>
        {lobbyCompanies.length <= 0 && (
          <LobbyEmptyView
            onClickViewPlans={onClickViewPlans}
            onClickCreateNewLab={onClickCreateNewLab}
          />
        )}
        {lobbyCompanies.length > 0 && (
          <SecondSection
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            {lobbyCompanies.map((item, index) => {
              if (item.type === LobbyCardsType.SIMPLE) {
                return (
                  <LobbyCard
                    key={index}
                    type={item.type}
                    icon={item.icon}
                    companyColor={item.companyColor}
                    address={item.address}
                    collaborators={item.collaborators}
                    userRole={item.userRole}
                    inviteText={item.inviteText}
                    companyId={item.companyId}
                    companyName={item.companyName}
                    onclickEnter={onclickEnter}
                    onClickDecline={() => {}}
                    onClickAccept={() => {}}
                    onClickCreateNewLab={() => {}}
                  />
                );
              }
              if (item.type === LobbyCardsType.INVITATION) {
                return (
                  <LobbyCard
                    key={index}
                    type={item.type}
                    icon={item.icon}
                    companyColor={item.companyColor}
                    address={item.address}
                    collaborators={item.collaborators}
                    companyId={item.companyId}
                    companyName={item.companyName}
                    userRole={item.userRole}
                    inviteText={item.inviteText}
                    onClickDecline={onClickDecline}
                    onClickAccept={onClickAccept}
                    onClickCreateNewLab={() => {}}
                    onclickEnter={() => {}}
                  />
                );
              }
              if (item.type === LobbyCardsType.CREATE) {
                return (
                  <LobbyCard
                    key={index}
                    type={item.type}
                    icon={item.icon}
                    companyColor={item.companyColor}
                    address={item.address}
                    companyId={item.companyId}
                    companyName={item.companyName}
                    collaborators={item.collaborators}
                    userRole={item.userRole}
                    inviteText={item.inviteText}
                    onClickCreateNewLab={onClickCreateNewLab}
                    onclickEnter={() => {}}
                    onClickDecline={() => {}}
                    onClickAccept={() => {}}
                  />
                );
              }
            })}
          </SecondSection>
        )}
      </ContentLayout>
    </>
  );
}

const SecondSection = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-wrap: wrap;
    //Desktop
    @media (min-width: ${desktop}px) {
      gap: 16px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      gap: 16px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      gap: 8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export default Lobby;
