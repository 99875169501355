import { BASE_URL } from '../Server';
import { ContactData } from '../../domain/user-management/ContactData';
import { CompanyData, MyCompanyData } from '../../domain/user-management/CompanyData';
import {
  UpdateClient,
  SendWorkerInvitation,
  UpdateWorker,
  AnswerWorkerInvitation,
} from '../../domain/user-management/Contact';
import { NewCompany } from '../../domain/user-management/NewCompany';
import { Plan } from '../../domain/user-management/Plans';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';
import { fetchWithAuth } from '../utils/fetchWithAuth';

const getContacts = async (): Promise<ContactData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/contacts', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateClient = async (client: UpdateClient) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/modifyContact', {
      method: 'POST',
      body: JSON.stringify(client),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateWorker = async (worker: UpdateWorker) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/modifyContact', {
      method: 'POST',
      body: JSON.stringify(worker),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const sendWorkerInvitation = async (invitationData: SendWorkerInvitation) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/modifyContact', {
      method: 'POST',
      body: JSON.stringify(invitationData),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const answerInvitation = async (answerInv: AnswerWorkerInvitation) => {
  try {
    const response = await fetchWithAuth(
      BASE_URL + '/answerInvitation?companyId=' + answerInv.companyId,
      {
        method: 'POST',
        body: JSON.stringify({ apply: answerInv.apply }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const deleteContact = async (id: number) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/deleteContact/' + id, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const getMyCompanyData = async (): Promise<MyCompanyData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/getMyCompany', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const getCompanies = async (): Promise<CompanyData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/company', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const newCompany = async (newContact: NewCompany) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/addCompany', {
      method: 'POST',
      body: JSON.stringify(newContact),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateCompany = async (newContact: NewCompany) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/updateCompany', {
      method: 'POST',
      body: JSON.stringify(newContact),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const deleteCompany = async (id: number) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/deleteContract/' + id, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const createCompany = async (plan: Plan): Promise<MyCompanyData> => {
  try {
    const response = await fetchWithAuth(BASE_URL + `/createCompany`, {
      method: 'POST',
      body: JSON.stringify(plan),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export {
  getContacts,
  updateClient,
  updateWorker,
  answerInvitation,
  sendWorkerInvitation,
  deleteContact,
  getCompanies,
  getMyCompanyData,
  newCompany,
  updateCompany,
  deleteCompany,
  createCompany,
};
