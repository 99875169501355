import React, { useState } from 'react';
import {
  PhaseUiModel,
  PhaseUiModelPhase,
  PhaseUiModelWithAssignee,
} from '../../ui-model/PhaseUiModel';
import { Text, TextType } from '../../components/dsm/Text';
import styled from 'styled-components';
import { usePhaseRepositoryImplementation } from '../../../data/phase/phasesRepositoryImplementation';
import { TemplateUiModel } from '../../ui-model/worktype/TemplateUiModel';
import { useTemplateRepositoryImplementation } from '../../../data/worktype/templates/TemplateRepositoryImplementation';
import { useTemplatesViewModel } from '../../settings/worktypes/worktypeitem/TemplatesViewModel';
import { WorkerData } from '../../../domain/user-management/ContactData';
import Avatar from '../../components/generic/Avatar';
import Searchbar from '../../components/generic/Searchbar';
import { useNavigate, useParams } from 'react-router-dom';
import ToggleHeader from '../../components/generic/ToggleHeader';
import { useContactsRepositoryImplementation } from '../../../data/user-management/UsersRepositoryImplementation';
import { featuresRepositoryImplementation } from '../../../data/features/FeaturesRepositoryImplementation';
import { useCaseOverViewViewModel } from '../CaseOverViewViewModel';
import { Template } from '../../../domain/worktype/templates/Template';
import DateTimePicker from '../../components/dsm/DateTimePicker';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';
import { useCaseRepositoryImplementation } from '../../../data/cases/CaseRepositoryImplementation';
import { useMobileCaseViewModel } from './MobileCaseViewModel';
import { AlertType } from '../../../domain/alert/AlertManager';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { useAuth } from '../../../routeFiles/AuthContext';

export enum NewComponentType {
  PHASE,
  TEMPLATE,
  DATE,
  ASSIGN,
}

export type AddNewComponentProps = {
  caseId?: number;
  labworkId?: number;
  currentPhase: PhaseUiModelPhase | PhaseUiModel | null;
  actualLabwork: any;
};

export const MobileAddPhaseOrTemplate = (props: AddNewComponentProps) => {
  const translate = useTranslate();
  const { caseId, labworkId, currentPhase, actualLabwork } = props;

  const { id, componentType } = useParams();
  const { companyId } = useAuth();
  const navigate = useNavigate();

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const contactRepo = useContactsRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const repoTemplates = useTemplateRepositoryImplementation();
  const phaseRepo = usePhaseRepositoryImplementation();
  const caseRepo = useCaseRepositoryImplementation();

  const { workers, phases } = useCaseOverViewViewModel(contactRepo, featureRepo, phaseRepo);
  const { getSortTemplatesByWorkTypeId } = useTemplatesViewModel(repoTemplates);
  const { updateMobilePhase, updateMobileDate, updateMobileTemplate, updateMobileAssigne } =
    useMobileCaseViewModel(caseRepo);

  const templates: TemplateUiModel[] = getSortTemplatesByWorkTypeId(
    Number(actualLabwork?.workType?.id),
  );
  const [searchWorker, setSearchWorker] = useState<WorkerData[]>(workers);

  const getNavigateOption = () => {
    return navigate(`/${companyId}/case/${id}`, {
      state: {
        phaseOrder: currentPhase?.order,
        actualLabworkId: labworkId,
      },
    });
  };

  const onHandleChangeSearchBar = (e) => {
    if (e.target.value.length <= 0) {
      setSearchWorker(workers);
    } else {
      const list: WorkerData[] = workers.filter((item) => {
        if (item.name !== null && item.name.toUpperCase().includes(e.target.value.toUpperCase())) {
          return item;
        }
      });
      setSearchWorker(list);
    }
  };

  const onHandleCloseSearch = () => {
    setSearchWorker(workers);
  };

  const searchInputStyle = {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'var(--dts_black)',
    border: '1px solid transparent',
    boxShadow: 'none',
    outline: 'none',
    padding: '9px 35px 9px 8px',
    borderRadius: '3px',
  };

  const clickOnAssigne = (worker: WorkerData) => {
    if (labworkId !== null && currentPhase !== null) {
      updateMobileAssigne(
        caseId,
        labworkId,
        (currentPhase as PhaseUiModelPhase).id,
        (currentPhase as PhaseUiModelPhase).order,
        worker.id,
        0,
      ).then((resp) => {
        if (resp) {
          getNavigateOption();
        } else {
          showAlert(translate(LANGUAGE_KEYS.AUTOSAVE_FAILED), AlertType.FAILD);
        }
      });
    }
  };

  const renderAssign = () => {
    const assigneId =
      (currentPhase as PhaseUiModelWithAssignee) === null
        ? null
        : (currentPhase as PhaseUiModelWithAssignee).assignee === null
        ? null
        : (currentPhase as PhaseUiModelWithAssignee).assignee.id;

    return (
      <div>
        <Searchbar
          placeHolder={'Search'}
          onHandleChangeSearchBar={onHandleChangeSearchBar}
          searchInputStyle={searchInputStyle}
          onHandleCloseSearch={onHandleCloseSearch}
        />
        <TitleText type={TextType.BODY_2}>Employee</TitleText>
        <ItemsContainer>
          {searchWorker.map((worker, index) => {
            return (
              <WorkerRow
                key={index}
                onClick={() => {
                  clickOnAssigne(worker);
                }}
              >
                <Avatar
                  textType={TextType.CAPTION_BOLD}
                  fullName={worker.name}
                  size={{ outer: 32, inner: 30 }}
                  avatarStyle={{
                    background:
                      worker.user !== null && worker.user.profileColor !== null
                        ? worker.user.profileColor
                        : 'var(--dts_default_blue)',
                  }}
                />
                {assigneId !== null && assigneId === worker.id ? (
                  <Text type={TextType.BODY_BOLD}>{worker.name}</Text>
                ) : (
                  <Text type={TextType.BODY_REGULAR}>{worker.name}</Text>
                )}
              </WorkerRow>
            );
          })}
        </ItemsContainer>
      </div>
    );
  };

  const clickOnAddPhase = (phase) => {
    if (labworkId !== null && caseId !== null) {
      updateMobilePhase(
        caseId,
        labworkId,
        currentPhase === null || currentPhase.order === null ? 0 : currentPhase.order + 1,
        phase.id,
      ).then((resp) => {
        if (resp) {
          getNavigateOption();
        } else {
          showAlert(translate(LANGUAGE_KEYS.AUTOSAVE_FAILED), AlertType.FAILD);
        }
      });
    }
  };

  const renderPhases = () => {
    return (
      <div>
        <Text type={TextType.BODY_2}>{translate(LANGUAGE_KEYS.PHASE_NAME)}</Text>
        <ItemsContainer>
          {phases
            .reduce((result: any[], phase) => {
              phase.kind != 'Date' && result.push(phase);
              return result;
            }, [])
            .map((phase, index) => {
              return (
                <ItemText
                  type={TextType.BODY_REGULAR}
                  key={index}
                  onClick={() => {
                    clickOnAddPhase(phase);
                  }}
                  ellipsis={true}
                >
                  {phase.name}
                </ItemText>
              );
            })}
        </ItemsContainer>
      </div>
    );
  };

  const clickOnAddTemplate = (template: Template) => {
    if (caseId !== null && labworkId !== null) {
      updateMobileTemplate(
        caseId,
        labworkId,
        currentPhase === null || currentPhase.order === null ? 0 : currentPhase.order + 1,
        template,
      ).then((resp) => {
        if (resp) {
          getNavigateOption();
        } else {
          showAlert(translate(LANGUAGE_KEYS.AUTOSAVE_FAILED), AlertType.FAILD);
        }
      });
    }
  };

  const renderTemplates = () => {
    return (
      <div>
        <Text type={TextType.BODY_2}>{translate(LANGUAGE_KEYS.TEMPLATE_NAME)}</Text>
        <ItemsContainer>
          {templates!.map((template, index) => {
            return (
              <ItemText
                type={TextType.BODY_REGULAR}
                key={index}
                onClick={() => {
                  clickOnAddTemplate(template);
                }}
              >
                {template.name}
              </ItemText>
            );
          })}
        </ItemsContainer>
      </div>
    );
  };

  const clickOnAddDate = (date: Date) => {
    if (
      caseId !== null &&
      labworkId !== null &&
      currentPhase !== null &&
      currentPhase.order !== null
    ) {
      updateMobileDate(caseId, labworkId, currentPhase.order + 1, date).then((resp) => {
        if (resp) {
          getNavigateOption();
        } else {
          showAlert(translate(LANGUAGE_KEYS.AUTOSAVE_FAILED), AlertType.FAILD);
        }
      });
    }
  };

  const renderDate = () => {
    return (
      <div>
        <DateTimePicker
          date={new Date()}
          onSave={(date) => {
            clickOnAddDate(date);
          }}
          onCancel={() => {
            getNavigateOption();
          }}
        />
      </div>
    );
  };

  const renderNewComponent = () => {
    switch (Number(componentType)) {
      case NewComponentType.PHASE:
        return renderPhases();
      case NewComponentType.TEMPLATE:
        return renderTemplates();
      case NewComponentType.DATE:
        return renderDate();
      case NewComponentType.ASSIGN:
        return renderAssign();
      default:
        return <React.Fragment />;
    }
  };
  return (
    <>
      <ToggleHeader
        hasContent={true}
        title={`Labwork`}
        enabledClick={true}
        buttonText={undefined}
        onClick={() => {}}
        noButtonDesign={true}
        buttonIcon={undefined}
        secondary={true}
        clickLeftIcon={() => {
          getNavigateOption();
        }}
      />
      <Content>{renderNewComponent()}</Content>
    </>
  );
};

const Content = styled.div`
  position: relative;
  background: var(--dts_withe_background);
  overflow-x: hidden;
  height: calc(100% - 92px);
  padding: 0px 16px 16px 16px;
`;

const ItemsContainer = styled.div`
  background: var(--dts_white);
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  margin-top: 5px;
`;

const ItemText = styled(Text)`
  padding: 12px 0px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--dts_withe_background);
  }
`;

const TitleText = styled(Text)`
  margin-top: 24px;
`;

const WorkerRow = styled.div`
  padding: 12px 0px;
  display: flex;
  gap: 8px;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid var(--dts_withe_background);
  }
`;
