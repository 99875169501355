import { getExtensionsUseCase } from '../../../useCases/worktype/extension/getExtensionsUseCases';
import { ExtensionRepository } from '../../../domain/worktype/extension/ExtensionRepository';
import React from 'react';
import { CaseDetail, DetailsLabWork } from '../../ui-model/CaseDetail';
import useTranslate from '../../translations/useTranslate';

function useExtensionBoxViewModel(extensionRepository: ExtensionRepository) {
  const translate = useTranslate();

  const getExtensions = React.useCallback(
    function () {
      getExtensionsUseCase({
        getExtensions: extensionRepository.getExtensions,
      });
      console.log('useCaseDetailViewModel getExtensions');
    },
    [extensionRepository.getExtensions],
  );

  const mapExtrasFromExtensionList = React.useCallback(function (
    cdo: CaseDetail,
  ): Map<number, number> {
    const labworkExtras = new Map<number, number>();
    if (cdo.labworks !== null) {
      cdo.labworks.forEach((lw) => {
        getLabWorkExtensions(lw, labworkExtras, cdo);
      });
    }

    return labworkExtras;
  },
  []);

  const mapExtrasFromExtensionListByLabWork = React.useCallback(function (
    cdo: CaseDetail,
    labWorkId: number,
  ): Map<number, number> {
    const labworkExtras = new Map<number, number>();
    if (cdo.labworks) {
      const labWork = cdo.labworks.find((lb) => lb.labWorkId == labWorkId);
      if (labWork) {
        getLabWorkExtensions(labWork, labworkExtras, cdo);
      }
    }

    return labworkExtras;
  },
  []);

  function getLabWorkExtensions(
    lw: DetailsLabWork,
    labworkExtras = new Map<number, number>(),
    cdo: CaseDetail,
  ) {
    lw.extras.forEach((lwe) => {
      if (lwe.kind === 'Selectable') {
        lwe.values.forEach((lwev) => {
          if (lwev.selected) {
            // console.log(`Add ${lwev.id} to the extras list`);
            if (labworkExtras.has(lwev.id)) {
              labworkExtras.set(lwev.id, labworkExtras.get(lwev.id)! + 1);
            } else {
              labworkExtras.set(lwev.id, 1);
            }
          }
        });
      } else if (lwe.kind === 'Simple' && lwe.active) {
        // console.log(`Add ${lwe.id} to the extras list`);
        if (labworkExtras.has(lwe.id)) {
          labworkExtras.set(lwe.id, labworkExtras.get(lwe.id)! + 1);
        } else {
          labworkExtras.set(lwe.id, 1);
        }
      }
    });

    if (cdo.tooths !== null) {
      [...cdo.tooths.lower, ...cdo.tooths.upper]
        .filter((t) => {
          return t.labwork === lw.labWorkId;
        })
        .forEach((t) => {
          // console.log(`Add  to the extras teeeeth ${t.quarter}${t.teethp}`);
          // console.log(t.ext);
          t.ext.forEach((lwe) => {
            if (lwe.kind === 'Selectable') {
              lwe.values.forEach((lwev) => {
                if (lwev.selected) {
                  // console.log(`Add ${lwev.id} to the extras teeeeth list`);
                  if (labworkExtras.has(lwev.id)) {
                    labworkExtras.set(lwev.id, labworkExtras.get(lwev.id)! + 1);
                  } else {
                    labworkExtras.set(lwev.id, 1);
                  }
                }
              });
            } else if (lwe.kind === 'Simple' && lwe.active) {
              // console.log(`Add ${lwe.id} to the extras teeeeth list`);
              if (labworkExtras.has(lwe.id)) {
                labworkExtras.set(lwe.id, labworkExtras.get(lwe.id)! + 1);
              } else {
                labworkExtras.set(lwe.id, 1);
              }
            }
          });
        });
    }
  }

  return {
    extensions: extensionRepository.extensions?.map((extension) => ({
      ...extension,
      name: translate(extension.name),
    })),
    mapExtrasFromExtensionList,
    mapExtrasFromExtensionListByLabWork,
    getExtensions,
  };
}

export { useExtensionBoxViewModel };
