import React from 'react';
import { Package } from '../../domain/billing/Package';
import { updateCompanyPackageUseCase } from '../../useCases/billing/updateCompanyPackageUseCase';
import { BillingRepository } from '../../domain/billing/BillingRepository';
import { getMaxCollaboratorsUseCase } from '../../useCases/billing/getMaxCollaboratorsUseCase';
import {
  activateEfacturaByAccauntatUseCase,
  activateEfacturaByUserUseCase,
  resetEfacturaAuthValuesUseCase,
  saveEfacturaAuthCodeUseCase,
  saveEfacturaAuthErrorUseCase,
} from '../../useCases/e-factura/newAuthUseCase';
import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';
import {
  checkActivationTokenUseCase,
  generateEActivationTokenUseCase,
} from '../../useCases/e-factura/generateEActivationTokenUseCase';
import { CompaniesRepository } from '../../domain/user-management/UsersRepository';
import { getMyCompanyDataUseCase } from '../../useCases/user-management/getCompaniesUseCase';
import { uploadEFacturaUseCase } from '../../useCases/e-factura/uploadEFacturaUseCase';
import {
  getStatusAnafFacturaFromCacheUseCase,
  getStatusAnafFacturaUseCase,
} from '../../useCases/e-factura/getStatusAnafFacturaUseCase';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

const useEfacturaViewModel = (
  billingRepo: BillingRepository,
  authRepo: AuthRepository,
  e_facturaRepo: E_facturaRepository,
  companiesRepo: CompaniesRepository,
) => {
  const saveEfacturaAuthCode = React.useCallback((code: string) => {
    console.log('saveEfacturaAuthCode: ' + code);
    saveEfacturaAuthCodeUseCase(e_facturaRepo, code);
  }, []);
  const saveEfacturaAuthError = React.useCallback((error: string) => {
    console.log('saveEfacturaAuthError: ' + error);
    saveEfacturaAuthErrorUseCase(e_facturaRepo, error);
  }, []);
  const resetEfacturaAuthValues = React.useCallback(() => {
    console.log('resetEfacturaAuthValuesUseCase');
    resetEfacturaAuthValuesUseCase(e_facturaRepo);
  }, []);

  const updateCompanyPackage = React.useCallback((params: Package) => {
    return updateCompanyPackageUseCase(billingRepo, params);
  }, []);

  const getMaxCollaborators = React.useCallback(() => {
    return getMaxCollaboratorsUseCase(billingRepo);
  }, []);

  const generateEActivationToken = React.useCallback(() => {
    return generateEActivationTokenUseCase(e_facturaRepo);
  }, []);

  const activateEfacturaByAccauntat = React.useCallback(
    (activationToken: string, eCode: string) => {
      return activateEfacturaByAccauntatUseCase(e_facturaRepo, activationToken, eCode);
    },
    [],
  );

  const checkActivationToken = React.useCallback((param: { companyId: number; token: string }) => {
    return checkActivationTokenUseCase(e_facturaRepo, param);
  }, []);

  const activateEfacturaByUser = React.useCallback((code: string) => {
    return activateEfacturaByUserUseCase(e_facturaRepo, code).then((resp) => {
      getMyCompanyDataUseCase(companiesRepo);
      return resp;
    });
  }, []);

  const uploadEFactura = React.useCallback((invoiceId: number) => {
    return uploadEFacturaUseCase(e_facturaRepo, invoiceId);
  }, []);

  const getStatusAnafFacturaFromCache = (invoiceId: number) => {
    return getStatusAnafFacturaFromCacheUseCase(e_facturaRepo, invoiceId);
  };

  const getStatusAnafFactura = React.useCallback((invoiceId: number) => {
    return getStatusAnafFacturaUseCase(e_facturaRepo, invoiceId);
  }, []);

  return {
    eCode: e_facturaRepo.code,
    eError: e_facturaRepo.error,
    myCompanyEtoken: companiesRepo.myCompanyEtoken,
    username: authRepo.name === null || authRepo.name === null ? '' : authRepo.name,
    updateCompanyPackage,
    getMaxCollaborators,
    generateEActivationToken,
    activateEfacturaByAccauntat,
    checkActivationToken,
    activateEfacturaByUser,
    uploadEFactura,
    getStatusAnafFacturaFromCache,
    getStatusAnafFactura,
    saveEfacturaAuthCode,
    saveEfacturaAuthError,
    resetEfacturaAuthValues,
  };
};

export { useEfacturaViewModel };
