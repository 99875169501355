import type { AnyAction } from 'redux';
import * as actionTypes from './lobbyActionTypes';
import { LobbyRepository } from '../../domain/lobby/LobbyRepository';

type LobbyRepositoryState = Omit<LobbyRepository, 'getLobbyCompanies' | 'enterLaboratory'>;

const INITIAL_STATE: LobbyRepositoryState = {
  lobbyCompanies: null,
  isLoading: false,
  isUpdating: false,
};

const lobbyReducer = (state: LobbyRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_LOBBY_COMPANIES:
      return { ...state, isLoading: true };
    case actionTypes.GET_LOBBY_COMPANIES_SUCCESS:
      if (JSON.stringify(state.lobbyCompanies) !== JSON.stringify(action.payload)) {
        return { ...state, isLoading: false, lobbyCompanies: action.lobbyCompanies };
      }
      return { ...state, isLoading: false };
    case actionTypes.GET_LOBBY_COMPANIES_FAILD:
      return { ...state, isLoading: false, lobbyCompanies: [] };
    default:
      return state;
  }
};
export default lobbyReducer;
export type { LobbyRepositoryState };
