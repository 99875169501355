import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';
import {
  handleLoginAction,
  setLaboratoryAction,
  logoutAction,
  updateUserAction,
  addUserAction,
  setPasswordAction,
  resetPasswordAction,
  startSetLaboratoryAction,
} from '../../data/authenticationFlow/authActions';
import {
  Credentials,
  LoggedCompanyData,
  PasswordData,
} from '../../domain/authenticationFlow/AuthDataTypes';

import { AuthRepositoryState } from './authReducer';
import { RootState } from '../utils/store';

const authSelector = (state: RootState) => state.auth;

const useAuthRepositoryImplementation = (): AuthRepository => {
  const dispatch = useDispatch();

  const {
    token,
    userId,
    email,
    language,
    firstName,
    lastName,
    name,
    phoneNumber,
    profileColor,
    isFtiFinished,
    isLoadingCompanyData,
    companyId,
    companyName,
    color,
    address,
    userRole,
    validSubscription,
    packageType,
    billingType,
    subscriptionEndTime,
  } = useSelector<RootState, AuthRepositoryState>(authSelector);

  const handleLogin = React.useCallback(
    (c: Credentials) => handleLoginAction(c)(dispatch),
    [dispatch],
  );

  const updateUser = React.useCallback((data) => updateUserAction(data)(dispatch), [dispatch]);

  const logoutUser = () => {
    return logoutAction(dispatch);
  };

  const setLaboratory = (companyData: LoggedCompanyData | null) => {
    return setLaboratoryAction(companyData)(dispatch);
  };
  const startSetLaboratory = () => {
    startSetLaboratoryAction(dispatch);
  };

  const addUser = React.useCallback(
    (username: string, isEnabledMailList: boolean) =>
      addUserAction(username, isEnabledMailList)(dispatch),
    [dispatch],
  );

  const setPassword = React.useCallback(
    (password: PasswordData, isRecover: boolean) =>
      setPasswordAction(password, isRecover)(dispatch),
    [dispatch],
  );

  const resetPassword = React.useCallback(
    (email: string) => resetPasswordAction(email)(dispatch),
    [dispatch],
  );

  return {
    token,
    userId,
    email,
    language,
    firstName,
    lastName,
    name,
    phoneNumber,
    profileColor,
    isFtiFinished,
    isLoadingCompanyData,
    companyId,
    companyName,
    color,
    address,
    userRole,
    validSubscription,
    packageType,
    billingType,
    subscriptionEndTime,
    handleLogin,
    updateUser,
    logoutUser,
    startSetLaboratory,
    setLaboratory,
    addUser,
    setPassword,
    resetPassword,
  };
};

export { useAuthRepositoryImplementation };
