import {
  FINISHED,
  FINISHED_W_ERROR_CLUMSY,
  FINISHED_W_ERROR_INATTENTIVE,
  FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE,
  OPEN,
  STARTED,
} from '../../../data/cases/detail/PhaseStatus';

function getPhaseStatusStyleNameUseCase(status: number | null): string {
  if (status === null) return '';
  switch (status) {
    case FINISHED:
      return 'Finished';
    case FINISHED_W_ERROR_CLUMSY:
    case FINISHED_W_ERROR_INATTENTIVE:
    case FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE:
      return 'Error';
    case OPEN:
      return 'Open';
    case STARTED:
      return 'Started';
    default:
      return '';
  }
}

export { getPhaseStatusStyleNameUseCase };
