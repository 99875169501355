import type { AnyAction } from 'redux';
import {
  AlertManager,
  SHOW_ALERT,
  HIDE_ALERT,
  SHOW_PAYMENT_EXPIRE_MODAL,
  HIDE_PAYMENT_EXPIRE_MODAL,
} from '../../domain/alert/AlertManager';

export enum AlertType {
  FAILD = 0,
  SUCCESS = 1,
  NO_ALLERT = -1,
}

type AlertState = Omit<
  AlertManager,
  'showAlert' | 'hideAlert' | 'showPaymentExpireModal' | 'hidePaymentExpireModal'
>;

const initState: AlertState = {
  message: '',
  alertType: AlertType.NO_ALLERT,
  isOpenExpireAlert: false,
  userRole: -1,
};

const defaultText = 'Please add a good message to the dispatch!';

const alertReducer = (state: AlertState = initState, action: AnyAction) => {
  switch (action.type) {
    case HIDE_ALERT:
      return {
        ...state,
        message: '',
        alertType: AlertType.NO_ALLERT,
      };
    case SHOW_ALERT:
      return {
        ...state,
        message: action.message !== undefined ? action.message : defaultText,
        alertType: action.actionType,
      };
    case SHOW_PAYMENT_EXPIRE_MODAL:
      return {
        ...state,
        alertType: action.actionType,
        isOpenExpireAlert: true,
        userRole: action.userRole,
      };
    case HIDE_PAYMENT_EXPIRE_MODAL:
      return {
        ...state,
        alertType: action.actionType,
        isOpenExpireAlert: false,
      };
    default:
      return state;
  }
};

export default alertReducer;
export type { AlertState };
