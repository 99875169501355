import type { AnyAction } from 'redux';
import * as actionTypes from './extensionActionTypes';
import { ExtensionRepository } from '../../../domain/worktype/extension/ExtensionRepository';
import * as generalActionTypes from '../../generalActionTypes';

type ExtensionRepositoryState = Omit<
  ExtensionRepository,
  'getExtensions' | 'updateExtension' | 'updateTeethExtension'
>;

const INITIAL_STATE: ExtensionRepositoryState = {
  extensions: null,
  isLoading: false,
  isUpdating: false,
};

const extensionReducer = (state: ExtensionRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_EXTENSIONS:
      return { ...state, isLoading: true };
    case actionTypes.GET_EXTENSIONS_SUCCESS:
      return { ...state, isLoading: false, extensions: action.extensions };
    case actionTypes.GET_EXTENSIONS_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_EXTENSIONS:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_EXTENSIONS_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_EXTENSIONS_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case generalActionTypes.RESET_LABOR_DATA:
      return { ...state, extensions: null, isLoading: false, isUpdating: false };
    default:
      return state;
  }
};
export default extensionReducer;
export type { ExtensionRepositoryState };
