import React from 'react';
// import { ReactComponent as CalendarIcon } from '../../icons/menu/CalendarMenuIcon.svg';
import { ReactComponent as CasesIcon } from '../../icons/menu/CasesMenuIcon.svg';
import { ReactComponent as InvoiceIcon } from '../../icons/menu/InvoiceMenuIcon.svg';
// import { ReactComponent as FAQIcon } from '../../icons/menu/FAQMenuIcon.svg';
// import { ReactComponent as SettingsIcon } from '../../icons/menu/SettingsMenuIcon.svg';
// import { ReactComponent as NotificationIcon } from '../../icons/menu/NotificationMenuIcon.svg';
// import { ReactComponent as PreferenceIcon } from '../../icons/menu/PreferenceMenuIcon.svg';
// import { ReactComponent as ManageAcountIcon } from '../../icons/menu/ManageAcountMenuIcon.svg';
import { ReactComponent as BillingDataIcon } from '../../icons/menu/BillingDataMenuIcon.svg';
import { ReactComponent as CollaboratorsIcon } from '../../icons/menu/CollaboratorsMenuIcon.svg';
import { ReactComponent as CompanyDataIcon } from '../../icons/menu/CompanyDataMenuIcon.svg';
import { ReactComponent as PhasesIcon } from '../../icons/menu/PhasesMenuIcon.svg';
import { ReactComponent as WorkTypesIcon } from '../../icons/menu/WorkTypesMenuIcon.svg';
import { ReactComponent as LabsIcon } from '../../icons/menu/LabsMenuIcon.svg';
import { ReactComponent as LogoutIcon } from '../../icons/menu/LogoutMenuIcon.svg';
import Avatar from '../../presentation/components/generic/Avatar';
import {
  MenuItems,
  MenuItem,
  SubMenuItem,
  MenuType,
  SubMenuUiItem,
  MenuUiItem,
} from '../../presentation/ui-model/menu/MenuUImodel';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { FeatureId, SubscriptionType } from '../../domain/features/Features';
import { To } from 'react-router-dom';
import { LANGUAGE_KEYS } from '../../presentation/translations/languageKeys';

const userData = { userName: '', companyName: '', userRole: 1 };

type GetFeatureUseCase = Pick<
  FeaturesRepository,
  'getEnabledFeaturesBeforeLab' | 'getEnabledFeatures'
>;

const menuItems: Array<MenuItem> = [
  {
    menuId: MenuItems.DASHBOARD,
    featureId: FeatureId.DASHBOARD_VISIBILITY,
    name: userData.companyName,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB, MenuType.MOBILE_LAB],
    to: '',
    icon: <Avatar fullName={userData.companyName} size={{ outer: 24, inner: 22 }} />,
    visibilityExpireSubscription: true,
    isSettings: false,
  },
  // {
  //   menuId: MenuItems.CALLENDAR,
  //   featureId: FeatureId.CALLENDAR_VISIBILITY,
  //   enabledDevices: [MenuType.DESKTOP_LAB, MenuType.MOBILE_LAB],
  //   name: LANGUAGE_KEYS.CALENDAR,
  //   to: '/callendar',
  //   icon: <CalendarIcon width={24} height={24} />,
  // },
  {
    menuId: MenuItems.CASES,
    featureId: FeatureId.REPORTS_VISIBILITY,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB],
    name: LANGUAGE_KEYS.CASES,
    to: '/reports',
    icon: <CasesIcon width={24} height={24} />,
    visibilityExpireSubscription: true,
    isSettings: false,
  },
  {
    menuId: MenuItems.INVOICE,
    featureId: FeatureId.INVOICE_VISIBILITY,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB, MenuType.MOBILE_LAB],
    name: LANGUAGE_KEYS.INVOICE,
    to: '/invoice',
    icon: <InvoiceIcon width={24} height={24} />,
    visibilityExpireSubscription: true,
    isSettings: false,
  },
  // {
  //   menuId: MenuItems.FAQ,
  //   featureId: FeatureId.FAQ_VISIBILITY,
  //   enabledDevices: [MenuType.DESKTOP_LAB, MenuType.MOBILE_LAB],
  //   name: LANGUAGE_KEYS.FAQ,
  //   to: '/faq',
  //   icon: <FAQIcon width={24} height={24} />,
  // },
  // {
  //   menuId: MenuItems.SETTINGS,
  //   featureId: FeatureId.SETTINGS_VISIBILITY_SUPERVISOR,
  //   enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB],
  //   subMenus: [
  //     // MenuItems.GENERAL,
  //     MenuItems.WORKTYPES,
  //     MenuItems.PHASES,
  //     MenuItems.COLLABORATORS,
  //     MenuItems.COMAPANY_DATA,
  //     MenuItems.BILLING,
  //   ],
  //   name: LANGUAGE_KEYS.SETTINGS,
  //   to: '/settings',
  //   icon: <SettingsIcon width={24} height={24} />,
  //   visibilityExpireSubscription: true,
  // },
  {
    menuId: MenuItems.PROFILE,
    featureId: FeatureId.PROFILE_VISIBILITY,
    enabledDevices: [MenuType.PROFILE],
    subMenus: [
      MenuItems.MY_PROFILE,
      MenuItems.NOTIFICATIONS,
      MenuItems.PREFERENCES,
      MenuItems.MANAGE_ACOUNTS,
      MenuItems.LABS,
      MenuItems.LOGOUT,
    ],
    to: '-',
    name: LANGUAGE_KEYS.MY_PROFILE,
    icon: <Avatar fullName={userData.userName} size={{ outer: 31, inner: 29 }} />,
    visibilityExpireSubscription: true,
    isSettings: false,
  },
  {
    name: LANGUAGE_KEYS.WORKTYPES,
    to: '/settings/worktype',
    featureId: FeatureId.SETTINGS_VISIBILITY_OWNER,
    icon: <WorkTypesIcon />,
    menuId: MenuItems.WORKTYPES,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB],
    visibilityExpireSubscription: true,
    isSettings: true,
  },
  {
    menuId: MenuItems.PHASES,
    featureId: FeatureId.SETTINGS_VISIBILITY_SUPERVISOR,
    name: LANGUAGE_KEYS.PHASES,
    to: '/settings/phases',
    icon: <PhasesIcon />,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB],
    visibilityExpireSubscription: true,
    isSettings: true,
  },
  {
    menuId: MenuItems.COLLABORATORS,
    featureId: FeatureId.SETTINGS_VISIBILITY_OWNER,
    name: LANGUAGE_KEYS.MENU_COLABBRATORS,
    to: '/settings/users',
    icon: <CollaboratorsIcon />,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB],
    visibilityExpireSubscription: true,
    isSettings: true,
  },
  {
    menuId: MenuItems.COMAPANY_DATA,
    featureId: FeatureId.SETTINGS_VISIBILITY_OWNER,
    name: LANGUAGE_KEYS.COMPANY_DATA,
    to: '/settings/mycompany',
    icon: <CompanyDataIcon />,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB],
    visibilityExpireSubscription: false,
    isSettings: true,
  },
  {
    menuId: MenuItems.BILLING,
    featureId: FeatureId.SETTINGS_VISIBILITY_OWNER,
    name: LANGUAGE_KEYS.MENU_BILLING,
    to: '/settings/billing',
    icon: <BillingDataIcon />,
    enabledDevices: [MenuType.DESKTOP_LAB],
    visibilityExpireSubscription: true,
    isSettings: true,
  },
];

const subMenuItems: Array<SubMenuItem> = [
  {
    menuId: MenuItems.GENERAL,
    featureId: FeatureId.SETTINGS_VISIBILITY_ALL,
    name: LANGUAGE_KEYS.GENERAL,
    to: '/settings',
    icon: null,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB, MenuType.MOBILE_LAB],
    visibilityExpireSubscription: true,
  },
  // {
  //   menuId: MenuItems.NOTIFICATIONS,
  //   featureId: FeatureId.PROFILE_VISIBILITY,
  //   name: LANGUAGE_KEYS.NOTIFICATIONS,
  //   to: '/notifications',
  //   icon: <NotificationIcon width={24} height={24} />,
  // },
  // {
  //   menuId: MenuItems.PREFERENCES,
  //   featureId: FeatureId.PROFILE_VISIBILITY,
  //   name: LANGUAGE_KEYS.PREFERENCES,
  //   to: '/preferences',
  //   icon: <PreferenceIcon width={24} height={24} />,
  // },
  // {
  //   menuId: MenuItems.MANAGE_ACOUNTS,
  //   featureId: FeatureId.PROFILE_VISIBILITY,
  //   name: LANGUAGE_KEYS.MANAGE_ACOUNTS,
  //   to: '/manageAcounts',
  //   icon: <ManageAcountIcon width={24} height={24} />,
  // },
  {
    menuId: MenuItems.MY_PROFILE,
    featureId: FeatureId.PROFILE_VISIBILITY,
    name: LANGUAGE_KEYS.MY_PROFILE,
    to: '/myprofile',
    icon: null,
    enabledDevices: [MenuType.PROFILE],
    visibilityExpireSubscription: true,
  },
  {
    menuId: MenuItems.LABS,
    featureId: FeatureId.PROFILE_VISIBILITY,
    name: LANGUAGE_KEYS.LABS,
    to: '/',
    icon: <LabsIcon width={24} height={24} />,
    enabledDevices: [MenuType.PROFILE],
    visibilityExpireSubscription: true,
  },
  {
    menuId: MenuItems.LOGOUT,
    featureId: FeatureId.PROFILE_VISIBILITY,
    name: LANGUAGE_KEYS.LOGOUT,
    to: null,
    icon: <LogoutIcon width={24} height={24} />,
    enabledDevices: [MenuType.PROFILE],
    visibilityExpireSubscription: true,
  },
];

const noMenuItems: Array<{
  to: To | null;
  featureId: number;
  enabledDevices: Array<MenuType>;
  visibilityExpireSubscription: boolean;
}> = [
  {
    to: '/case/:id/*',
    featureId: FeatureId.CASE_VISIBILITY,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB, MenuType.MOBILE_LAB],
    visibilityExpireSubscription: true,
  },
  {
    to: '/case/:id/newComponent/:componentType',
    featureId: FeatureId.CASE_VISIBILITY,
    enabledDevices: [MenuType.MOBILE_LAB],
    visibilityExpireSubscription: false,
  },
  {
    to: '/reports/reportsoverview',
    featureId: FeatureId.REPORTS_OVERVIEW_VISIBILITY,
    enabledDevices: [MenuType.DESKTOP_LAB, MenuType.TABLET_LAB],
    visibilityExpireSubscription: false,
  },
];

function getMenuList(
  deviceType: MenuType,
  featuresList: Array<{ featureId: number; enabled: boolean }>,
  companyName: string | null,
  companyId: number | null,
) {
  const menuList: Array<MenuUiItem> = [];
  menuItems
    .filter((menu) => menu.enabledDevices.includes(deviceType))
    .map((menuItem) => {
      const feature = featuresList.find((feature) => feature.featureId === menuItem.featureId);
      if (feature !== undefined && feature !== null && feature.enabled) {
        const subMenus: Array<SubMenuUiItem> = [];
        if (menuItem.subMenus !== undefined) {
          menuItem.subMenus.forEach((submenuId) => {
            const subMenu = subMenuItems
              .filter((menu) => menu.enabledDevices.includes(deviceType))
              .find((element) => element.menuId === submenuId);
            if (subMenu !== null && subMenu !== undefined) {
              const subMenuFeature = featuresList.find(
                (feature) => feature.featureId === subMenu.featureId,
              );
              if (
                subMenuFeature !== undefined &&
                subMenuFeature !== null &&
                subMenuFeature.enabled
              ) {
                subMenus.push({
                  name: subMenu.name,
                  to: companyId !== null ? `/${companyId}${subMenu.to}` : `${subMenu.to}`,
                  icon: subMenu.icon,
                  isActive: subMenu.isActive,
                  menuId: subMenu.menuId,
                  visibilityExpireSubscription: subMenu.visibilityExpireSubscription,
                });
              }
            }
          });
        }
        menuList.push({
          menuId: menuItem.menuId,
          name:
            menuItem.menuId === MenuItems.DASHBOARD && companyName !== null
              ? companyName
              : menuItem.name,
          to:
            companyId !== null
              ? menuItem.to !== '' || menuItem.menuId === MenuItems.DASHBOARD
                ? `/${companyId}${menuItem.to}`
                : ''
              : `${menuItem.to}`,
          icon:
            menuItem.menuId === MenuItems.DASHBOARD && companyName !== null ? (
              <Avatar fullName={companyName} size={{ outer: 24, inner: 22 }} />
            ) : (
              menuItem.icon
            ),
          subMenus: subMenus,
          visibilityExpireSubscription: menuItem.visibilityExpireSubscription,
          isSettings: menuItem.isSettings,
        });
      }
    });
  return menuList;
}

function getProfileList(
  deviceType: MenuType,
  featuresList: Array<{ featureId: number; enabled: boolean }>,
  userName: string | null,
  companyId: number | null,
  profileBackground: string,
) {
  const menuList: Array<MenuUiItem> = [];
  menuItems
    .filter((menu) => menu.enabledDevices.includes(deviceType))
    .map((menuItem) => {
      const feature = featuresList.find((feature) => feature.featureId === menuItem.featureId);
      if (feature !== undefined && feature !== null && feature.enabled) {
        const subMenus: Array<SubMenuUiItem> = [];
        if (menuItem.subMenus !== undefined) {
          menuItem.subMenus.forEach((submenuId) => {
            const subMenu = subMenuItems.find((element) => element.menuId === submenuId);
            if (subMenu !== null && subMenu !== undefined) {
              const subMenuFeature = featuresList.find(
                (feature) => feature.featureId === subMenu.featureId,
              );
              if (
                subMenuFeature !== undefined &&
                subMenuFeature !== null &&
                subMenuFeature.enabled
              ) {
                subMenus.push({
                  name: subMenu.name,
                  to:
                    subMenu.menuId === MenuItems.MY_PROFILE && companyId !== null
                      ? `/${companyId}${subMenu.to}`
                      : `${subMenu.to}`,
                  icon:
                    subMenu.menuId === MenuItems.MY_PROFILE && userName !== null ? (
                      <Avatar
                        fullName={userName}
                        size={{ outer: 24, inner: 22 }}
                        avatarStyle={{ background: profileBackground }}
                      />
                    ) : (
                      subMenu.icon
                    ),
                  isActive: subMenu.isActive,
                  menuId: subMenu.menuId,
                  visibilityExpireSubscription: subMenu.visibilityExpireSubscription,
                });
              }
            }
          });
        }
        menuList.push({
          menuId: menuItem.menuId,
          name: menuItem.name,
          to: menuItem.to,
          icon:
            menuItem.menuId === MenuItems.PROFILE && userName !== null ? (
              <Avatar
                fullName={userName}
                size={{ outer: 24, inner: 22 }}
                avatarStyle={{ background: profileBackground }}
              />
            ) : (
              menuItem.icon
            ),
          subMenus: subMenus,
          visibilityExpireSubscription: menuItem.visibilityExpireSubscription,
          isSettings: menuItem.isSettings,
        });
      }
    });

  return menuList;
}

const getDesktopMenuUseCase = (
  featuresRepo: GetFeatureUseCase,
  userRole: number | null,
  companyName: string | null,
  companyId: number | null,
) => {
  if (companyId === null) {
    const featureList = featuresRepo.getEnabledFeaturesBeforeLab(0);
    const menuList = getMenuList(MenuType.DESKTOP, featureList, companyName, null);
    return menuList;
  }
  if (companyName === null) {
    companyName = 'Your laboratory';
  }
  if (userRole !== null && companyName !== null && companyId !== null) {
    const featureList = featuresRepo.getEnabledFeatures(userRole, SubscriptionType.FREE);
    const menuList = getMenuList(MenuType.DESKTOP_LAB, featureList, companyName, companyId);
    return menuList;
  } else {
    return [];
  }
};

const getTabletMenuUseCase = (
  featuresRepo: GetFeatureUseCase,
  userRole: number | null,
  companyName: string | null,
  companyId: number | null,
) => {
  if (companyId === null) {
    const featureList = featuresRepo.getEnabledFeaturesBeforeLab(0);
    const menuList = getMenuList(MenuType.TABLET, featureList, companyName, null);
    return menuList;
  }
  if (companyName === null) {
    companyName = 'Your laboratory';
  }
  if (userRole !== null && companyName !== null && companyId !== null) {
    const featureList = featuresRepo.getEnabledFeatures(userRole, SubscriptionType.FREE);
    const menuList = getMenuList(MenuType.TABLET_LAB, featureList, companyName, companyId);
    return menuList;
  } else {
    return [];
  }
};

const getMobileMenuUseCase = (
  featuresRepo: GetFeatureUseCase,
  userRole: number | null,
  companyName: string | null,
  companyId: number | null,
) => {
  if (companyId === null) {
    const featureList = featuresRepo.getEnabledFeaturesBeforeLab(0);
    return getMenuList(MenuType.MOBILE, featureList, companyName, null);
  }
  if (companyName === 'null') {
    companyName = 'Your laboratory';
  }
  if (userRole !== null && companyName !== null && companyId !== null) {
    const featureList = featuresRepo.getEnabledFeatures(userRole, SubscriptionType.FREE);
    return getMenuList(MenuType.MOBILE_LAB, featureList, companyName, companyId);
  }
  return [];
};

const getProfileMenuUseCase = (
  featuresRepo: GetFeatureUseCase,
  userName: string | null,
  profileBackground: string,
  companyId: number | null,
) => {
  const featureList = featuresRepo.getEnabledFeaturesBeforeLab(0);
  if (userName !== null) {
    return getProfileList(MenuType.PROFILE, featureList, userName, companyId, profileBackground);
  } else {
    return [];
  }
};

const isEnabledRouteByPathName = (
  featuresRepo: GetFeatureUseCase,
  userRole: number,
  pathName: string,
  deviceType: MenuType,
  validSubscription: boolean,
) => {
  const menu = menuItems
    .filter((menu) => menu.enabledDevices.includes(deviceType))
    .find((menu) => menu.to === pathName);
  const featureList = featuresRepo.getEnabledFeatures(userRole, SubscriptionType.FREE);
  if (menu !== undefined) {
    const feature = featureList.find((feature) => feature.featureId === menu.featureId);
    if (feature !== undefined) {
      if (validSubscription) {
        return feature.enabled;
      } else {
        return feature.enabled && menu.visibilityExpireSubscription;
      }
    }
  }
  const noMenuItem = noMenuItems
    .filter((menu) => menu.enabledDevices.includes(deviceType))
    .find((menu) => menu.to === pathName);
  if (noMenuItem !== undefined) {
    const feature = featureList.find((feature) => feature.featureId === noMenuItem.featureId);
    if (feature !== undefined) {
      if (validSubscription) {
        return feature.enabled;
      } else {
        return feature.enabled && noMenuItem.visibilityExpireSubscription;
      }
    }
  }
  const subMenuItem = subMenuItems
    .filter((menu) => menu.enabledDevices.includes(deviceType))
    .find((menu) => menu.to === pathName);
  if (subMenuItem !== undefined) {
    const feature = featureList.find((feature) => feature.featureId === subMenuItem.featureId);
    if (feature !== undefined) {
      if (validSubscription) {
        return feature.enabled;
      } else {
        return feature.enabled && subMenuItem.visibilityExpireSubscription;
      }
    }
  }

  return false;
};

export {
  getDesktopMenuUseCase,
  getTabletMenuUseCase,
  getMobileMenuUseCase,
  getProfileMenuUseCase,
  isEnabledRouteByPathName,
};
