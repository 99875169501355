import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Text, TextType } from '../components/dsm/Text';

import { useDeviceParameters } from '../hooks/useDeviceParameters';

import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import ToggleHeader from '../components/generic/ToggleHeader';
import DesktopHeader from '../components/generic/DesktopHeader';
import ContentLayout from '../components/layout/ContentLayout';
import BillingNotificationModal from './BillingNotificationModal';
import { useBillingRepositoryImplementation } from '../../data/billing/billingRepositoryImplementation';
import { useBillingViewModel } from './BillingViewModel';
import { BillingType, MyCompanySubscriptionUiModel } from '../ui-model/billing/PackageUiModel';
import { useAuthRepositoryImplementation } from '../../data/authenticationFlow/authRepositoryImplementation';
import { useContactsRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import { AlertType } from '../../domain/alert/AlertManager';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { useAuth } from '../../routeFiles/AuthContext';

type BillingNotificationDialog = OpenBillingNotificationDialog | ClosedBillingNotificationDialog;

type OpenBillingNotificationDialog = {
  kind: 'Open';
  onClose: () => void;
  cancelSubscription: () => void;
};

type ClosedBillingNotificationDialog = { kind: 'Closed' };

function Billing() {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { companyId } = useAuth();

  const repo = useBillingRepositoryImplementation();
  const authRepo = useAuthRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const { username, getCompanySubscription, updateCompanyPackage } = useBillingViewModel(
    repo,
    authRepo,
    contactRepo,
  );

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const [subscription, setSubscription] = useState<MyCompanySubscriptionUiModel | null>(null);

  useEffect(() => {
    getCompanySubscription().then((resp) => {
      setSubscription(resp);
    });
  }, []);

  const [cancelSubscriptionDialog, setCancelSubscriptionDialog] =
    React.useState<BillingNotificationDialog>({
      kind: 'Closed',
    });

  const closeCancelSubscriptionDialog = () => setCancelSubscriptionDialog({ kind: 'Closed' });

  const cancelSubscription = () => {
    if (companyId && subscription) {
      updateCompanyPackage({
        id: companyId,
        packageType: subscription.packageType,
        billingType: BillingType.CANCELD,
        billingPeriod: subscription.billingType,
      }).then((resp) => {
        closeCancelSubscriptionDialog();
        if (!resp) {
          showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
        }
      });
    }
  };

  const openCancelSubscriptionDialog = () => {
    setCancelSubscriptionDialog({
      kind: 'Open',
      onClose: closeCancelSubscriptionDialog,
      cancelSubscription: cancelSubscription,
    });
  };

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.MENU_BILLING)}
          buttonText=''
          enabledClick={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.MENU_BILLING)}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <BillingConatiner>
          <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.YOUR_PLAN)}</Text>
          <Information>
            <Details className='column'>
              <Text type={TextType.TITLE_BOLD_2}>{translate(subscription?.title)}</Text>
              {subscription?.billingType === BillingType.TRIAL && (
                <Text type={TextType.CAPTION_REGULAR}>
                  {translate(LANGUAGE_KEYS.TRIAL_EXPIRE, {
                    expireday: subscription.days,
                  })}
                </Text>
              )}
              {subscription?.billingType === BillingType.PAYED && (
                <Text type={TextType.CAPTION_REGULAR}>
                  {/* {translate(LANGUAGE_KEYS.TRIAL_EXPIRE, {
                    expireday: 6,
                  })} */}
                  Monthly Billing : {subscription.price} Ron/month
                </Text>
              )}
              {subscription?.billingType === BillingType.NOT_PAYED && (
                <Text type={TextType.CAPTION_REGULAR}>
                  {translate(LANGUAGE_KEYS.TRIAL_EXPIRE, {
                    expireday: 6,
                  })}
                </Text>
              )}
            </Details>
            <Action>
              <Text
                type={TextType.CAPTION_REGULAR}
                onClick={() => {
                  navigate(`manage-subscription`);
                }}
              >
                {translate(LANGUAGE_KEYS.MANAGE_SUBSCRIPTION)}
              </Text>
            </Action>
          </Information>
          <Information>
            <Details className='row'>
              <Text type={TextType.CAPTION_BOLD}>
                {translate(LANGUAGE_KEYS.NEXT_BILLING_DATE, {
                  nextbillingdate: subscription?.formatEndTime,
                })}
              </Text>
            </Details>
            <Action>
              <Text
                type={TextType.CAPTION_REGULAR}
                onClick={() => {
                  openCancelSubscriptionDialog();
                }}
              >
                {translate(LANGUAGE_KEYS.CANCEL_SUBSCRIPTION)}
              </Text>
              {cancelSubscriptionDialog.kind === 'Open' && subscription !== null && (
                <BillingNotificationModal
                  title={translate(LANGUAGE_KEYS.CANCEL_SUBSCRIPTION)}
                  packageName={subscription.title}
                  description={translate(LANGUAGE_KEYS.CANCEL_SUBSCRIPTION_DESCRIPTION, {
                    user: username,
                    packigename: translate(subscription?.title),
                    packigeexpiredate: subscription?.formatEndTime,
                  })}
                  endDate={subscription.formatEndTime}
                  startDate={subscription.formatStartTime}
                  userName={username}
                  onClose={cancelSubscriptionDialog.onClose}
                  onClick={cancelSubscriptionDialog.cancelSubscription}
                  buttonText={translate(LANGUAGE_KEYS.CANCEL_SUBSCRIPTION_BUTTON)}
                />
              )}
            </Action>
          </Information>
        </BillingConatiner>
      </ContentLayout>
    </>
  );
}

const BillingConatiner = styled.div`
  border-radius: 3px;
  padding: 24px;
  background: var(--dts_white);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Information = styled.div`
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--dts_light_grey);
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
`;

const Details = styled.div`
  display: flex;
  flex-grow: 1;
  &.row {
    flex-direction: row;
    gap: 8px;
  }
  &.column {
    flex-direction: column;
    gap: 4px;
  }
`;
const Action = styled.span`
  cursor: pointer;
  p {
    color: var(--dts_primary);
    &:hover {
      color: var(--dts_hover_blue);
    }
  }
`;

export default Billing;
