import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { token } = useAuth();

  // Ha nincs token, akkor átirányít a login oldalra
  if (!token) {
    return <Navigate to='/login' />;
  }

  // Ha van token, akkor megjeleníti a védett oldalt
  return element;
};

export default ProtectedRoute;
