import React from 'react';

import { ReportRepository } from '../../../../domain/reports/ReportRepository';
import {
  CompaniesRepository,
  ContactsRepository,
} from '../../../../domain/user-management/UsersRepository';
import { WorkTypeRepository } from '../../../../domain/worktype/WorkTypeRepository';
import { getReportsCasesByIdsUseCase } from '../../../../useCases/reports/expense/getReportsCasesByIdsUseCase';
import { getCompanyNameUseCase } from '../../../../useCases/user-management/getCompaniesUseCase';
import {
  getWorkertNameUseCase,
  getWorkertProfileColorUseCase,
} from '../../../../useCases/user-management/getContactsUseCase';
import { getErrorStatusByStatusUseCase } from '../../../../useCases/getErrorStatusUseCase';
import { accountPhaseUseCase } from '../../../../useCases/reports/payoff/accountPhasesUseCase';
import { ReportCasesByIds, LaboratoryWork } from '../../../../domain/reports/Report';
import { WorkPhaseGroup } from '../../../../domain/WorkPhaseGroup';
import { SolvedPayOff } from '../../../../domain/reports/PayOff';
import {
  PayOffUIModel,
  PayOffLabworkUi,
  PayOffPhaseUi,
  PayOffCollaborator,
} from '../../../ui-model/reports/PayOffUIModel';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

const usePayOffViewModel = (
  repo: ReportRepository,
  companiesRepo: CompaniesRepository,
  workTypeRepo: WorkTypeRepository,
  contactRepo: ContactsRepository,
) => {
  const translate = useTranslate();
  const collaborators: PayOffCollaborator[] = [
    {
      collaboratorId: -1,
      collaboratorName: translate(LANGUAGE_KEYS.ALL),
      collaboratorColor: 'var(--dts_default_blue)',
      phaseIds: [],
      caseIds: [],
      labworkIds: [],
    },
  ];

  const getPayOffsByIds = React.useCallback(
    (params) => {
      return getReportsCasesByIdsUseCase(repo, params).then((res) => {
        return { data: setPayOffUiModelList(res.data), statusCode: res.statusCode };
      });
    },
    [
      workTypeRepo.getWorkTypes,
      workTypeRepo.workTypes,
      companiesRepo.getCompanies,
      companiesRepo.companies,
      contactRepo.getContacts,
      contactRepo.contacts,
    ],
  );

  const getCollaborators = () => {
    return collaborators;
  };

  const getTeethExtentions = (teethList): Map<number, { size: number; name: string }> => {
    const labworkExtras = new Map<number, { size: number; name: string }>();
    teethList.forEach((teeth) => {
      teeth.extensionList.forEach((extension) => {
        if (labworkExtras.has(extension.id)) {
          labworkExtras.set(extension.id, {
            size: labworkExtras.get(extension.id)!.size + 1,
            name: labworkExtras.get(extension.id)!.name,
          });
        } else {
          labworkExtras.set(extension.id, { size: 1, name: extension.name });
        }
      });
    });
    return labworkExtras;
  };

  const getPayOffLabworkhases = (
    list: Array<WorkPhaseGroup> | null,
    labworkId: number,
    caseId: number,
  ): Array<PayOffPhaseUi> => {
    if (list === null) return [];
    const payOffPhase: Array<PayOffPhaseUi> = list
      ?.filter((p) => p.type === 0)
      .map((p) => {
        if (
          p.assigneeId !== undefined &&
          p.id !== undefined &&
          p.assigneeId !== null &&
          p.id !== null
        ) {
          if (collaborators?.some((c) => c.collaboratorId === p.assigneeId)) {
            collaborators
              ?.find((c) => c.collaboratorId === p.assigneeId)
              ?.phaseIds.push({ id: p.id, order: p.order, accounted: p.accounted });
            if (
              collaborators
                ?.find((c) => c.collaboratorId === p.assigneeId)
                ?.labworkIds?.some((l) => l !== labworkId)
            ) {
              collaborators
                ?.find((c) => c.collaboratorId === p.assigneeId)
                ?.labworkIds.push(labworkId);
            }

            if (
              collaborators
                ?.find((c) => c.collaboratorId === p.assigneeId)
                ?.caseIds?.some((c) => c !== caseId)
            ) {
              collaborators?.find((c) => c.collaboratorId === p.assigneeId)?.caseIds.push(caseId);
            }
          } else {
            const item: PayOffCollaborator = {
              collaboratorId: p.assigneeId,
              collaboratorName: getWorkertNameUseCase(p.assigneeId, contactRepo),
              collaboratorColor: getWorkertProfileColorUseCase(p.assigneeId, contactRepo),
              caseIds: [caseId],
              labworkIds: [labworkId],
              phaseIds: [{ id: p.id, order: p.order, accounted: p.accounted }],
            };
            collaborators.push(item);
          }
        }
        let status: string | null = null;
        if (p.status !== undefined) {
          status = getErrorStatusByStatusUseCase(p.status);
        }
        return {
          order: p.order,
          phaseId: p.id,
          collaboratorAvatar:
            p.assigneeId === undefined || p.assigneeId === null
              ? undefined
              : getWorkertNameUseCase(p.assigneeId, contactRepo),
          collaboratorId: p.assigneeId,
          collaboratorProfileColor:
            p.assigneeId === undefined || p.assigneeId === null
              ? 'var(--dts_default_blue)'
              : getWorkertProfileColorUseCase(p.assigneeId, contactRepo),
          phaseName: p.name,
          wrongPhase: status !== null ? translate(status) : null,
          phaseEndDate:
            p.finishDate === null ? null : getFormattedDateWithHourAndMinutes(p.finishDate),
          accounted: p.accounted,
        };
      });
    return payOffPhase;
  };

  const getPayOffCaseLabwork = (
    list: Array<LaboratoryWork>,
    caseId: number,
  ): Array<PayOffLabworkUi> => {
    const labworks = list.map((item) => {
      const subCat =
        item.extensionList?.find((e) => e.groupType === 1) !== undefined
          ? `, ${item.extensionList?.find((e) => e.groupType === 1)?.name}`
          : ``;
      const title =
        translate(workTypeRepo.workTypes?.find((w) => w.id === item.workType)?.name) +
        translate(subCat);
      const labworkExtras = getTeethExtentions(item.teethList);
      item.extensionList
        ?.filter((e) => e.groupType !== 1)
        .forEach((e) => {
          labworkExtras.set(e.id, { size: 1, name: e.name });
        });
      let subTitle = '';
      labworkExtras.forEach((value) => {
        const num = value.size > 1 ? `${value.size}x` : '';
        if (subTitle == '') {
          subTitle = `${num} ${translate(value.name)}`;
        } else {
          subTitle = `${subTitle}, ${num} ${translate(value.name)}`;
        }
      });
      let teeths: Array<any> = [];
      if (item.teethList !== null) {
        teeths = item.teethList.map((teeth) => {
          return { id: teeth.id, connection: teeth.connection };
        });
      }

      const phases = getPayOffLabworkhases(item.phases, item.labWorkId, caseId);
      return {
        labworkId: item.labWorkId,
        title: title,
        subTitle: subTitle,
        teeths: teeths,
        phases: phases,
      };
    });

    return labworks;
  };

  function setPayOffUiModelList(list: Array<ReportCasesByIds>) {
    const payOffUiModelList: Array<PayOffUIModel> = [];
    list.forEach((c) => {
      payOffUiModelList.push({
        caseId: c.id,
        clientName: c.clientName,
        companyName: getCompanyNameUseCase(Number(c.id), {
          getCompanies: companiesRepo.getCompanies,
          companies: companiesRepo.companies,
          getMyCompanyData: companiesRepo.getMyCompanyData,
        }),
        patientName: c.patientName,
        labworks: getPayOffCaseLabwork(c.labWorks, c.id),
      });
    });
    return {
      model: payOffUiModelList,
      collaborators: collaborators.sort((a, b) => {
        if (a.collaboratorId === -1 || b.collaboratorId === -1) {
          return 1;
        }
        if (
          a.phaseIds.every((p) => p.accounted === 1) &&
          !b.phaseIds.every((p) => p.accounted === 1)
        ) {
          return 1;
        }
        return -1;
      }),
    };
  }

  const accountPhases = React.useCallback(
    (param: SolvedPayOff) => {
      return accountPhaseUseCase(repo, param);
    },
    [repo.accountPhases],
  );

  return {
    getPayOffsByIds,
    getCollaborators,
    accountPhases,
  };
};

const getFormattedDateWithHourAndMinutes = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${month}/${day}/${year} ${hour}:${minutes}`;
};

export { usePayOffViewModel };
