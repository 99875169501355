import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { TeethRepository } from '../../../domain/worktype/teeth/TeethRepository';
import { getTeethAction, updateTeethAction } from './teethActions';
import { TeethRepositoryState } from './teethReducer';
import { UpdateTeethTypeInfo } from '../../../domain/worktype/teeth/UpdateTeethTypeInfo';
import { RootState } from '../../utils/store';

const teethSelector = (state: RootState) => state.teeth;

const useTeethRepositoryImplementation = (): TeethRepository => {
  const { teeth, isLoading, isUpdating } = useSelector<RootState, TeethRepositoryState>(
    teethSelector,
  );

  const dispatch = useDispatch();

  const getTeeth = React.useCallback(() => getTeethAction()(dispatch), [dispatch]);

  const updateTeeth = React.useCallback(
    (workTypeId: number, teethList: UpdateTeethTypeInfo[]) =>
      updateTeethAction(workTypeId, teethList)(dispatch),
    [dispatch],
  );

  return {
    teeth,
    isLoading,
    isUpdating,
    getTeeth,
    updateTeeth,
  };
};

export { useTeethRepositoryImplementation };
