import {
  getCaseById,
  getCases,
  newCase,
  // updateCase,
  uploadCaseDetail,
  deleteCase,
} from './casesService';
// import { Case } from '../../domain/Case';
import * as actionTypes from './casesActionTypes';
import { caseDetailUploadFinished, caseDetailUploadStarted } from './detail/caseDetailReducer';
import { checkErrorStatus } from '../ErrorHandling';

const getCasesAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_CASES });
  return getCases().then((cases) => {
    if (checkErrorStatus(cases.code, dispatch)) {
      dispatch({ type: actionTypes.GET_CASES_SUCCESS, case: cases.cases });
      return cases.cases;
    } else {
      dispatch({ type: actionTypes.GET_CASES_FAILD, error: cases.code });
      return cases.cases;
    }
  });
};

const getCaseByIdAction = (caseId: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_CASE_BY_ID });
  return getCaseById(caseId).then((c) => {
    if (checkErrorStatus(c.code, dispatch)) {
      dispatch({ type: actionTypes.GET_CASE_BY_ID_SUCCESS, case: c.case });
      return c.case;
    } else {
      dispatch({ type: actionTypes.GET_CASE_BY_ID_FAILD, error: c.code });
      return c.case;
    }
  });
};

const newCaseAction = (onSuccess: (id: number) => void) => (dispatch: any) => {
  dispatch({ type: actionTypes.NEW_CASE });

  return newCase().then((c) => {
    getCases()
      .then((cases) => {
        if (checkErrorStatus(cases.code, dispatch)) {
          dispatch({ type: actionTypes.GET_CASES_SUCCESS, case: cases.cases });
          return cases.cases;
        } else {
          dispatch({ type: actionTypes.GET_CASES_FAILD, error: cases.code });
          return cases.cases;
        }
      })
      .then(() => {
        if (checkErrorStatus(c.code, dispatch) && c.data) {
          dispatch({ type: actionTypes.NEW_CASE_SUCCESS });
          onSuccess(c.data);
        } else {
          dispatch({ type: actionTypes.NEW_CASE_FAILED, error: c.code });
        }
      });
  });
};

// const updateCaseAction = (c: Case) => (dispatch: any) => {
//   dispatch({ type: actionTypes.UPDATE_CASE });

//   return updateCase(c).then((res) => {
//     if (res) {
//       dispatch({ type: actionTypes.UPDATE_CASE_SUCCESS });
//     } else {
//       dispatch({ type: actionTypes.UPDATE_CASE_FAILD, error: 11 });
//     }

//     return;
//   });
// };

const uploadCaseDetailActions =
  (caseId: number, stackOfChanges: Array<any>[]) => (dispatch: any) => {
    dispatch(caseDetailUploadStarted());
    return uploadCaseDetail(caseId, stackOfChanges).then((resp) => {
      const rev = stackOfChanges[stackOfChanges.length - 1][0];
      dispatch(caseDetailUploadFinished(rev));
      if (checkErrorStatus(resp.code, dispatch)) {
        return true;
      } else {
        return false;
      }
    });
  };

const deleteCaseActions = (deleteCases: Array<number>) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_CASE });
  return deleteCase(deleteCases).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.DELETE_CASE_SUCCESS });
      getCasesAction();
      return resp.data;
    } else {
      dispatch({ type: actionTypes.DELETE_CASE_FAILED, error: 11 });
      return false;
    }
  });
};

const closeReopenCaseActions =
  (caseId: number, stackOfChanges: Array<any>[]) => (dispatch: any) => {
    dispatch(caseDetailUploadStarted());
    return uploadCaseDetail(caseId, stackOfChanges).then((resp) => {
      const rev = stackOfChanges[stackOfChanges.length - 1][0];
      dispatch(caseDetailUploadFinished(rev));
      if (checkErrorStatus(resp.code, dispatch)) {
        return true;
      } else {
        return false;
      }
    });
  };

export {
  getCasesAction,
  getCaseByIdAction,
  newCaseAction,
  // updateCaseAction,
  uploadCaseDetailActions,
  deleteCaseActions,
  closeReopenCaseActions,
};
