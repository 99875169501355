import type { AnyAction } from 'redux';
import * as actionTypes from './E_facturaActionTypes';
import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';

type E_facturaRepositoryState = Omit<
  E_facturaRepository,
  | 'generateEActivationToken'
  | 'activateEfacturaByAccauntat'
  | 'checkActivationToken'
  | 'activateEfacturaByUser'
  | 'refreshEToken'
  | 'uploadEFactura'
  | 'getStatusAnafFactura'
  | 'getStatusAnafFactura'
  | 'saveEfacturaAuthCode'
  | 'saveEfacturaAuthError'
  | 'resetEfacturaAuthValues'
>;

const INITIAL_STATE: E_facturaRepositoryState = {
  eFacturaStatuses: null,
  error: window.localStorage.getItem('eError'),
  code: window.localStorage.getItem('eCode'),
};

const e_facturaReducer = (state: E_facturaRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_E_FACTURA_STATUS:
      return { ...state, counties: action.counties };
    case actionTypes.GET_E_FACTURA_STATUS_SUCCESS:
      if (
        state.eFacturaStatuses !== null &&
        !state.eFacturaStatuses.some((item) => item.invoiceId === action.invoiceId)
      ) {
        return {
          ...state,
          eFacturaStatuses: [
            ...state.eFacturaStatuses,
            { invoiceId: action.invoiceId, eInvoiceSatus: action.status },
          ],
        };
      }
      return { ...state };
    case actionTypes.GET_E_FACTURA_STATUS_FAILD:
      return { ...state };
    case actionTypes.SAVE_ANAF_AUTH_RESPONSE_CODE:
      return { ...state, error: null, code: action.code };
    case actionTypes.SAVE_ANAF_AUTH_RESPONSE_ERROR:
      return { ...state, error: action.error, code: null };
    case actionTypes.ANAF_AUTH_VALUE_DELETE:
      window.localStorage.removeItem('eError');
      window.localStorage.removeItem('eCode');
      return { ...state, error: null, code: null };
    default:
      return state;
  }
};
export default e_facturaReducer;
export type { E_facturaRepositoryState };
