import React, { useState } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../components/dsm/Text';
import InputField from '../../components/dsm/InputField';
import FormAction from '../components/FormAction';
import { useNavigate } from 'react-router';
import {
  InputFieldErrorType,
  InputFieldType,
  getInputErrorText,
  isValidInputField,
} from '../components/FieldUtils';
import { useSignUpFlowViewModel } from './SignUpFlowViewModel';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { AlertType } from '../../../domain/alert/AlertManager';
import Recaptcha from './Recaptcha';
import { useGoogleRepositoryImplementation } from '../../../data/google/googleRepositoryImplementation';
import { useCaptchaViewModel } from './CaptchaViewModel';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { MobileBackButton } from '../../components/generic/MobileBackButton';
import { DeviceSizeProps } from '../../DeviceInformations';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import { useAuthRepositoryImplementation } from '../../../data/authenticationFlow/authRepositoryImplementation';

const ResetPassword = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const captchaRepo = useGoogleRepositoryImplementation();
  const { captchaValidation, getCaptchaSiteKey } = useCaptchaViewModel(captchaRepo);

  const resetPasswordFields = [
    {
      labelText: translate(LANGUAGE_KEYS.EMAIL),
      labelFor: 'email-address',
      id: 'email-address',
      name: 'email',
      type: 'email',
      autoComplete: 'email',
      isRequired: true,
      placeholder: 'username@domain.ro',
    },
  ];

  const authRepo = useAuthRepositoryImplementation();
  const { resetPassword } = useSignUpFlowViewModel(authRepo);
  const fields = resetPasswordFields;
  const fieldsState = {};
  fields.forEach((field) => (fieldsState[field.id] = ''));
  const [resetPasswordState, setResetPasswordState] = useState(fieldsState);
  const [dialogTitle, setDialogTitle] = useState('Reset Password');
  const welcomeText = translate(LANGUAGE_KEYS.RESET_PASSWORD_PAGE_DESCRIPTION);
  const [errorText, setErrorText] = useState('');
  const [captchaIsValid, setCaptchaIsValid] = useState<boolean>(false);

  const handleChange = (e) => {
    setResetPasswordState({ ...resetPasswordState, [e.target.id]: e.target.value });
  };

  const onChangeCaptcha = (captchaToken: string) => {
    // captchaValidation(captchaToken, window.location.hostname);
    setCaptchaIsValid(captchaToken !== null);
  };

  const handleSubmit = () => {
    const email = resetPasswordState['email-address'];
    if (email !== null && isValidInputField(InputFieldType.EMAIL_ADDRESS, email)) {
      resetPassword(email).then((resp) => {
        if (resp.hasError) {
          showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
          return;
        }
        setDialogTitle('');
      });
    } else {
      setErrorText(translate(getInputErrorText(InputFieldErrorType.INVALID_EMAIL_RESET_PASSWORD)));
    }
  };

  return (
    <>
      {isMobile && <MobileBackButton />}
      <ResetPasswordContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
        className={`${dialogTitle === '' && 'notfication'}`}
      >
        <ContentContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
          className={`${dialogTitle === '' && 'notfication'}`}
        >
          {dialogTitle !== '' ? (
            <>
              <TitleText type={TextType.TITLE_BOLD_2}>
                {translate(LANGUAGE_KEYS.RESET_PASSWORD)}
              </TitleText>
              <CustomForm>
                <InformationText
                  mobile_small={mobileSmallSize}
                  mobile={mobileSize}
                  tablet_small={tabletSmallSize}
                  tablet_large={tabletLargeSize}
                  desktop={desktopSize}
                  type={TextType.BODY_2}
                  className={`${errorText !== '' && 'error'}`}
                >
                  {errorText === '' ? welcomeText : errorText}
                </InformationText>
                <InputFields>
                  {resetPasswordFields.map((field) => {
                    return (
                      <InputField
                        key={field.id}
                        handleChange={handleChange}
                        value={resetPasswordState[field.id]}
                        labelText={field.labelText}
                        labelFor={field.labelFor}
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        isRequired={field.isRequired}
                        placeholder={field.placeholder}
                        error={errorText !== ''}
                        autoComplete={field.autoComplete}
                        strengthPassword={true}
                      />
                    );
                  })}
                </InputFields>
                <Recaptcha siteKey={getCaptchaSiteKey()} onChangeCaptcha={onChangeCaptcha} />
              </CustomForm>
              <FormAction
                handleSubmit={handleSubmit}
                text={translate(LANGUAGE_KEYS.RESET_PASSWORD)}
                action='submit'
                type='Button'
                disabled={captchaIsValid}
              />
              <FormAction
                handleSubmit={() => {}}
                text={translate(LANGUAGE_KEYS.CANCEL)}
                action={null}
                type='Text'
                disabled={null}
              />
            </>
          ) : (
            <>
              <InformationText
                className='after-send-email'
                type={TextType.BODY_2}
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
              >
                {translate(LANGUAGE_KEYS.RESET_PASSWORD_FOLLOWUP_TEXT, {
                  email: resetPasswordState['email-address'],
                })}
              </InformationText>
              <FormAction
                handleSubmit={() => {
                  navigate('/login');
                }}
                text={translate(LANGUAGE_KEYS.BACK_TO_LOGIN)}
                action={null}
                type='Text'
                disabled={null}
              />
            </>
          )}
        </ContentContainer>
      </ResetPasswordContainer>
    </>
  );
};

const ResetPasswordContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center;
    flex-direction: column;
    &.notfication {
      justify-content: center;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      height: calc(100% - 68px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ContentContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 304px;
    min-width: 304px;
    &.notfication {
      margin-top: 0px;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      margin-top: 68px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-top: 20px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomForm = styled.form`
  margin-bottom: 24px;
`;

const TitleText = styled(Text)`
  margin-bottom: 24px;
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const InformationText = styled(Text)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    text-align: left;
    margin-bottom: 16px;
    &.error {
      color: var(--dts_red);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      &.after-send-email{
        margin-top: 190px;
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export default ResetPassword;
