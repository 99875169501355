import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { MenuType } from '../presentation/ui-model/menu/MenuUImodel';
import Page403 from '../presentation/alert/Page403';

interface ProtectedCompanyRouteProps {
  element: React.ReactElement;
  pathName: string;
  deviceType: MenuType;
  isEnabledRoute: (
    userRole: number,
    pathname: string,
    deviceType: MenuType,
    validSubscription: boolean,
  ) => boolean;
}

const ProtectedCompanyRoute: React.FC<ProtectedCompanyRouteProps> = ({
  element,
  pathName,
  deviceType,
  isEnabledRoute,
}) => {
  const { companyId, userRole, validSubscription } = useAuth();

  if (!companyId || userRole === null) {
    return <Navigate to='/' />;
  }

  if (
    !isEnabledRoute(userRole, pathName, deviceType, validSubscription !== null && validSubscription)
  ) {
    return <Page403 />;
  }

  return element;
};

export default ProtectedCompanyRoute;
