import type { AnyAction } from 'redux';
import {
  ContactsRepository,
  CompaniesRepository,
} from '../../domain/user-management/UsersRepository';
import * as actionTypes from './usersActionTypes';
import * as generalActionTypes from '../generalActionTypes';

type ContactsRepositoryState = Omit<
  ContactsRepository,
  | 'getContacts'
  | 'updateClient'
  | 'deleteContact'
  | 'updateWorker'
  | 'sendWorkerInvitation'
  | 'answerWorkerInvitation'
>;
const INITIAL_STATE_CONTACT: ContactsRepositoryState = {
  contacts: null,
  isLoading: false,
  isUpdating: false,
};

type CompaniesRepositoryState = Omit<
  CompaniesRepository,
  | 'getCompanies'
  | 'newCompany'
  | 'updateCompany'
  | 'updateMyCompanyData'
  | 'deleteCompany'
  | 'createCompany'
  | 'getMyCompanyData'
>;
const INITIAL_STATE_COMPANIES: CompaniesRepositoryState = {
  companies: null,
  myCompanyEtoken: null,
  isLoading: false,
  isUpdating: false,
};

const contactsReducer = (
  state: ContactsRepositoryState = INITIAL_STATE_CONTACT,
  action: AnyAction,
) => {
  switch (action.type) {
    case actionTypes.GET_CONTACT:
      return { ...state, isLoading: true };
    case actionTypes.GET_CONTACT_SUCCESS:
      return { ...state, isLoading: false, contacts: action.contacts };
    case actionTypes.GET_CONTACT_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_WORKER:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_WORKER_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_WORKER_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.SEND_WORKER_INVITATION:
      return { ...state, isLoading: true };
    case actionTypes.SEND_WORKER_INVITATION_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.SEND_WORKER_INVITATION_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.ANSWER_WORKER_INVITATION:
      return { ...state, isLoading: true };
    case actionTypes.ANSWER_WORKER_INVITATION_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.ANSWER_WORKER_INVITATION_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_CLIENT:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_CLIENT_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_CLIENT_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.DELETE_CONTACT:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_CONTACT_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_CONTACT_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case generalActionTypes.RESET_LABOR_DATA:
      return { ...state, contacts: null, isLoading: false, isUpdating: false };
    default:
      return state;
  }
};

const companiesReducer = (
  state: CompaniesRepositoryState = INITIAL_STATE_COMPANIES,
  action: AnyAction,
) => {
  switch (action.type) {
    case actionTypes.GET_COMPANIES:
      return { ...state, isLoading: true };
    case actionTypes.GET_COMPANIES_SUCCESS:
      return { ...state, isLoading: false, companies: action.companies };
    case actionTypes.GET_COMPANIES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.GET_MY_COMPANY_DATA:
      return { ...state, isLoading: true };
    case actionTypes.GET_MY_COMPANY_DATA_SUCCESS:
      return { ...state, isLoading: false, myCompanyEtoken: action.myCompanyEtoken };
    case actionTypes.GET_MY_COMPANY_DATA_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.NEW_COMPANIES:
      return { ...state, isLoading: true };
    case actionTypes.NEW_COMPANIES_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.NEW_COMPANIES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_MY_COMPANY_DATA:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_MY_COMPANY_DATA_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_MY_COMPANY_DATA_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_COMPANIES:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_COMPANIES_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_COMPANIES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.DELETE_COMPANIES:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_COMPANIES_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_COMPANIES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.CREATE_COMPANY:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.CREATE_COMPANY_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case generalActionTypes.RESET_LABOR_DATA:
      return {
        ...state,
        companies: null,
        myCompanyEtoken: null,
        isLoading: false,
        isUpdating: false,
      };
    default:
      return state;
  }
};

export { contactsReducer, companiesReducer };
export type { ContactsRepositoryState, CompaniesRepositoryState };
