import { CaseByIdData, CasesData } from '../../domain/Case';
import { BASE_URL } from '../Server';
import { CaseResponse } from '../../domain/CaseResponse';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';

const getCases = async (): Promise<CasesData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/cases', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      data.forEach((c) => {
        c.labWorks.forEach((lw) => {
          if (lw.phases !== null) {
            lw.phases.sort((a, b) => (a.order > b.order ? 1 : -1));
          }
        });
      });
      return { code: 200, message: 'Success', cases: data };
    } else {
      return { code: response.status, message: 'Faild', cases: [] };
    }
  } catch (error) {
    return { code: error.status, message: error, cases: [] };
  }
};

const getCaseById = async (caseId: number): Promise<CaseByIdData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/cases/' + caseId, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return { code: 200, message: 'Success', case: data };
    } else {
      return { code: response.status, message: 'Faild', case: undefined };
    }
  } catch (error) {
    return { code: error.status, message: error, case: undefined };
  }
};

const newCase = async (): Promise<{ code: number; message: string; data: number }> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/addCase', {
      method: 'POST',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: -1 };
    }
  } catch (error) {
    return { code: error.status, message: error, data: -1 };
  }
};

const uploadCaseDetail = async (
  caseId: number,
  stackOfChanges: Array<any>[],
): Promise<CaseResponse> => {
  const body: { caseId: number; actions: Array<any>[] } = {
    caseId: caseId,
    actions: stackOfChanges,
  };
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/case/update', {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: undefined };
    }
  } catch (error) {
    return { code: error.status, message: error, data: undefined };
  }
};

const deleteCase = async (
  deleteCases: Array<number>,
): Promise<{ code: number; message: string; data: boolean | null }> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/cases/delete', {
      method: 'POST',
      body: JSON.stringify(deleteCases),
    });
    if (response.ok) {
      const data = await response.json();
      return { code: data.code, message: data.message, data: data.data };
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export { getCases, getCaseById, newCase, uploadCaseDetail, deleteCase };
