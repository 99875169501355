import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useInvoiceViewModel } from './InvoiceViewModel';
import { useInvoiceRepositoryImplementation } from '../../data/invoice/InvoiceRepositoryImplementation';
import { InvoiceListItem } from './InvoiceListItem';
import { InvoiceListItemUi } from '../ui-model/invoice/InvoiceUiModel';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import EmptyView from '../components/generic/EmptyView';
import ToggleHeader from '../components/generic/ToggleHeader';
import DesktopHeader from '../components/generic/DesktopHeader';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import ContentLayout from '../components/layout/ContentLayout';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import E_factura from '../e-factura/E-factura';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { useE_facturaRepositoryImplementation } from '../../data/e-factura/E_facturaRepositoryImplementation';
import { useAuth } from '../../routeFiles/AuthContext';

function InvoiceList() {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { companyId, userRole } = useAuth();
  const repo = useInvoiceRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const { getInvoices, updateInvoice, isEnabledEFacturaFeatureAction, deleteInvoice } =
    useInvoiceViewModel(repo, featureRepo, e_factuarRepository);
  const {
    isTabletSmall,
    isTabletLarge,
    isMobile,
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
  } = useDeviceParameters();

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const [invoiceList, setInvoiceList] = useState<Array<InvoiceListItemUi>>([]);
  const [sortASC, setSortASC] = useState<boolean>(true);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateInvoiceList();
  }, []);

  const updateInvoiceList = () => {
    getInvoices().then((resp) => {
      if (resp.statusCode === 200) {
        setInvoiceList(
          resp.invoiedata.sort((a, b) => a.clientCompany.localeCompare(b.clientCompany)),
        );
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
      }
    });
  };

  const handleSort = () => {
    if (sortASC) {
      setInvoiceList(invoiceList.sort((a, b) => b.clientCompany.localeCompare(a.clientCompany)));
      setSortASC(false);
    } else {
      setInvoiceList(invoiceList.sort((a, b) => a.clientCompany.localeCompare(b.clientCompany)));
      setSortASC(true);
    }
  };

  const openInvoiceDetailPage = (
    invoiceId: number,
    cases: Array<number>,
    invoiceNumber: string,
  ) => {
    navigate(`/${companyId}/invoice/` + invoiceId, {
      state: {
        cases: cases,
        breadCrumb: [
          {
            name: translate(LANGUAGE_KEYS.BILLING_INVOICE),
            link: `/${companyId}/invoice`,
          },
          {
            name: `${invoiceNumber}`,
            link: '',
          },
        ],
      },
    });
  };

  const onHandlePaid = (invoiceId: number, status: number) => {
    updateInvoice({ invoiceId: invoiceId, status: status }).then((resp) => {
      if (resp.statusCode === 200) {
        setInvoiceList(
          resp.invoiedata.sort((a, b) => a.clientCompany.localeCompare(b.clientCompany)),
        );
        if (status) {
          showAlert(translate(LANGUAGE_KEYS.ALERT_PAYMENT_SUCCESS), AlertType.SUCCESS);
        } else {
          showAlert(translate(LANGUAGE_KEYS.ALERT_WITHDRAW_PAYMENT_SUCCESS), AlertType.SUCCESS);
        }
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_SOMETHING_WENT_WRONG), AlertType.FAILD);
      }
    });
  };

  const onHandledeleteInvoice = (invoiceId: number) => {
    deleteInvoice(invoiceId).then((resp) => {
      if (resp) {
        showAlert(translate(LANGUAGE_KEYS.DELETE_SUCCESSFULL), AlertType.SUCCESS);
        updateInvoiceList();
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
      }
    });
  };

  return (
    <>
      <Content
        ref={ref}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {(isTabletLarge || isTabletSmall || isMobile) && (
          <ToggleHeader
            hasContent={true}
            title={translate(LANGUAGE_KEYS.BILLING_INVOICE)}
            enabledClick={undefined}
            buttonText={undefined}
            onClick={() => {}}
            buttonIcon={undefined}
            noButtonDesign={false}
          />
        )}
        {!isTabletLarge && !isTabletSmall && !isMobile && (
          <HeaderWithEFactura>
            <DesktopHeader
              hasContent={true}
              title={translate(LANGUAGE_KEYS.BILLING_INVOICE)}
              enabledClick={false}
              buttonText={undefined}
              onClick={() => {}}
              buttonIcon={undefined}
            />
            {userRole !== null && isEnabledEFacturaFeatureAction(userRole) && (
              <EfacturaConatiner>
                <E_factura
                  isInvoice={false}
                  invociceName={null}
                  invoiceId={null}
                  isLoadedFactura={null}
                  disableButtonAction={false}
                  updateStatus={() => {}}
                />
              </EfacturaConatiner>
            )}
          </HeaderWithEFactura>
        )}
        <ContentLayout>
          {invoiceList !== null && invoiceList.length > 0 ? (
            <ListItemsContainer>
              {invoiceList.map((invoice, index) => {
                return (
                  <InvoiceListItem
                    key={index}
                    index={index}
                    model={invoice}
                    handleSort={handleSort}
                    handleOpenDetails={openInvoiceDetailPage}
                    sortASC={sortASC}
                    handelePaid={onHandlePaid}
                    deleteInvoice={onHandledeleteInvoice}
                    refParent={ref}
                  />
                );
              })}
            </ListItemsContainer>
          ) : (
            <EmptyView allHeightOtherItems={135} />
          )}
        </ContentLayout>
      </Content>
    </>
  );
}

const Content = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    height: calc(100% - 200px);
    position: relative;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const HeaderWithEFactura = styled.div`
  display: flex;
  align-items: center;
`;

const ListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EfacturaConatiner = styled.div`
  padding: 0px 36px;
  margin-top: 40px;
  margin-bottom: 16px;
`;

export default InvoiceList;
