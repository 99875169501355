import { BASE_URL } from '../../Server';
import { TemplatesData } from '../../../domain/worktype/templates/Template';
import { TemplatePhases } from '../../../domain/worktype/templates/TemplatePhases';
import { NewTemplate } from '../../../domain/worktype/templates/NewTemplate';
import { UpdateTemplate } from '../../../domain/worktype/templates/UpdateTemplate';
import { UpdateTemplateOrderOrActive } from '../../../domain/worktype/templates/UpdateTemplateOrderOrActive';
import { TemplateBySubWorkType } from '../../../domain/worktype/templates/TemplateBySubWorkType';
import { fetchWithAuthAndCompanyId } from '../../utils/fetchWithAuthAndCompanyId';

const getTemplates = async (): Promise<TemplatesData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/template', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return { code: 200, message: 'Success', data: data };
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const newTemplate = async (newTemplate: NewTemplate) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/addTemplate', {
      method: 'POST',
      body: JSON.stringify(newTemplate),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const duplicateTemplate = async (id: number) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/template/duplicate/${id}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const deleteTemplate = async (id: number) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/template/delete/${id}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateTemplate = async (template: UpdateTemplate) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/template/update', {
      method: 'POST',
      body: JSON.stringify(template),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateTemplateOrderOrActive = async (templates: UpdateTemplateOrderOrActive[]) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/template/updateOrderOrActive', {
      method: 'POST',
      body: JSON.stringify({ templates: templates }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const addTemplateBySubWorkType = async (templateBySubWorkType: TemplateBySubWorkType) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/addTemplateBySubWorkType', {
      method: 'POST',
      body: JSON.stringify(templateBySubWorkType),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const removeTemplateFromSubWorkType = async (templateID: number, extensionID: number | null) => {
  let requestURL;
  if (extensionID === null) {
    requestURL = `/removeTemplateFromSubcategory?templateId=${templateID}`;
  } else {
    requestURL = `/removeTemplateFromSubcategory?templateId=${templateID}&extensionId=${extensionID}`;
  }
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + requestURL, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const addPhasesToTemplate = async (templatePhases: TemplatePhases) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/template/addPhases', {
      method: 'POST',
      body: JSON.stringify(templatePhases),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export {
  getTemplates,
  newTemplate,
  duplicateTemplate,
  deleteTemplate,
  updateTemplate,
  updateTemplateOrderOrActive,
  addTemplateBySubWorkType,
  removeTemplateFromSubWorkType,
  addPhasesToTemplate,
};
