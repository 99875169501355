import type { AnyAction } from 'redux';
import * as actionTypes from './phaseActionTypes';
import { PhaseRepository } from '../../domain/PhaseRepository';
import * as generalActionTypes from '../generalActionTypes';

type PhaseRepositoryState = Omit<
  PhaseRepository,
  'getPhases' | 'newPhase' | 'deletePhase' | 'updatePhase'
>;

const INITIAL_STATE: PhaseRepositoryState = {
  phases: undefined,
  isLoading: false,
  isUpdating: false,
};

const phaseReducer = (state: PhaseRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_PHASES:
      return { ...state, isLoading: true };
    case actionTypes.GET_PHASES_SUCCESS:
      return { ...state, isLoading: false, phases: action.phases };
    case actionTypes.GET_PHASES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.NEW_PHASES:
      return { ...state, isLoading: true };
    case actionTypes.NEW_PHASES_SUCCESS:
      return { ...state, isLoading: false }; // TODO ????
    case actionTypes.DELETE_PHASE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_PHASE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_PHASE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_PHASE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_PHASE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_PHASE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case generalActionTypes.RESET_LABOR_DATA:
      return { ...state, phases: null, isUpdating: false, isLoading: false };
    default:
      return state;
  }
};
export default phaseReducer;
export type { PhaseRepositoryState };
