import { Tooth } from './Tooth';
import React, { useContext } from 'react';
import { Tooths } from '../../../ui-model/CaseDetail';
import styled from 'styled-components';
import { CaseWorkSheetToothPanoramaRow } from './CaseWorkSheetToothPanoramaComponent';
import { CaseDetailContext } from '../../CaseDetailProvider';
import { ToothPanoramaPContext } from './ToothPanoramaProvider';
import { useDispatch } from 'react-redux';
import {
  setCaseDetailSelectedTooths,
  setCaseDetailTooths,
} from '../../../../data/cases/detail/caseDetailReducer';
import { mapExtrasFromExtensionList } from '../../../../domain/Extra';
import {
  SELECTABLE_CATEGORY,
  SELECTABLE_EXTENSION,
  SIMPLE_EXTENSION,
} from '../../../../domain/worktype/extension/Extension';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';
import { useAlertManagerImplementation } from '../../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../../alert/AlertViewModel';
import { useAuth } from '../../../../routeFiles/AuthContext';

export function CaseWorkSheetToothPanoramaComp() {
  const { caseDetailObj, validSubscription } = useContext(CaseDetailContext);
  const [dragedConnectionElement, setDragedConnectionElement] = useContext(ToothPanoramaPContext);
  const dispatch = useDispatch();
  const { userRole } = useAuth();
  const { isTabletSmall } = useDeviceParameters();

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  function setTooths(t: Tooths) {
    dispatch(setCaseDetailTooths(t));
  }

  // TOOTH SELECTION FUNCTIONS
  const [dragedElement, setDragedElement] = React.useState({
    labWork: -1,
    toothType: -1,
    draggedTeethp: -1,
    draggedQuarter: -1,
    draggedUpper: false,
  });

  const [zindex, setZindex] = React.useState(0);

  /**
   * This function applying a connection to a hovered tooth if a connection was hold down -> it was stored in the context dragedConnectionElement
   * Since each tooth contains its connection towards the center (tooths on the left quarters (1,4) are storing their right connection) we need
   * a logic to distinguish which side to apply the connection when we are dragging from left to right and vice versa. This is really essential.
   * We should handle the exception when we apply connection from 21 to 11 or 11 to 21. each time it should add the connection to the 11
   **/
  function applyToothConnectionType(
    upper: boolean,
    teethp: number,
    quarter: number,
    nextToothConnectionType: number,
  ) {
    console.log(
      'applyToothConnectionType from ' +
        dragedConnectionElement.quarter +
        '-' +
        dragedConnectionElement.teeth +
        ' to ' +
        quarter +
        '-' +
        teethp,
    );
    if (isTabletSmall || !caseDetailObj.workSheetEditable) {
      return;
    }

    const newTooths = JSON.parse(JSON.stringify(caseDetailObj.tooths));

    // if we go from bigger number to lower number we add an offset so we wont add connection to teeth we did not hovered with the mpuse
    const offset = dragedConnectionElement.teeth > teethp ? 1 : 0;

    // Select the left side quarter in case we are at the middle
    let _quarter = quarter;
    if (dragedConnectionElement.teeth === teethp && teethp === 1) {
      _quarter = upper ? 1 : 4;
    }

    let clickedTeeth;
    if (upper) {
      clickedTeeth = newTooths.upper.find(
        (t) => t.teethp - offset === teethp && t.quarter === _quarter,
      );
    } else {
      clickedTeeth = newTooths.lower.find(
        (t) => t.teethp - offset === teethp && t.quarter === _quarter,
      );
    }
    clickedTeeth.leftConnectionType = nextToothConnectionType;

    // we set the last applyed connection position to know for the next one the direction
    setDragedConnectionElement({
      labWork: dragedConnectionElement.labWork,
      leftConnectionType: dragedConnectionElement.leftConnectionType,
      teeth: teethp,
      quarter: quarter,
    });
    setTooths({ lower: newTooths.lower, upper: newTooths.upper });
  }

  function applyToothType(
    upper: boolean,
    teethp: number,
    quarter: number,
    nextToothType: number,
    draggedTeethp: number,
    draggedQuarter: number,
    draggedUpper: boolean,
  ) {
    if (isTabletSmall || !caseDetailObj.workSheetEditable) {
      return;
    }

    const newTooths = JSON.parse(JSON.stringify(caseDetailObj.tooths));

    let clickedTeeth;
    if (upper) {
      clickedTeeth = newTooths.upper.find((t) => t.teethp === teethp && t.quarter === quarter);
    } else {
      clickedTeeth = newTooths.lower.find((t) => t.teethp === teethp && t.quarter === quarter);
    }

    clickedTeeth.type = nextToothType;
    clickedTeeth.labwork = clickedTeeth.type === 0 ? -1 : caseDetailObj.selectedLabWork;
    clickedTeeth.leftConnectionType = 0;

    const _labWork = caseDetailObj.labworks.find((lw) => lw.labWorkId === clickedTeeth.labwork);
    const extraList =
      _labWork?.workType?.teethTypeList?.find((v) => {
        return v.id === nextToothType;
      })?.extensionList || [];

    if (draggedTeethp != -1) {
      if (draggedUpper) {
        clickedTeeth.ext = newTooths.upper.find(
          (t) => t.teethp === draggedTeethp && t.quarter === draggedQuarter,
        ).ext;
      } else {
        clickedTeeth.ext = newTooths.lower.find(
          (t) => t.teethp === draggedTeethp && t.quarter === draggedQuarter,
        ).ext;
      }
    } else {
      clickedTeeth.ext = mapExtrasFromExtensionList(
        extraList,
        [],
        [SELECTABLE_CATEGORY, SELECTABLE_EXTENSION, SIMPLE_EXTENSION],
      );
    }

    setTooths({ lower: newTooths.lower, upper: newTooths.upper });
  }

  function changeToothType(
    teethp: number,
    quarter: number,
    dragedElement: {
      labWork: number;
      toothType: number;
      draggedTeethp: number;
      draggedQuarter: number;
      draggedUpper: boolean;
    },
    labworkId: number,
    upper: boolean,
  ) {
    if (isTabletSmall || !caseDetailObj.workSheetEditable) {
      return;
    }
    if (
      dragedConnectionElement.leftConnectionType > -1 &&
      dragedConnectionElement.labWork === labworkId
    ) {
      setZindex(10); // steal the "focus" from the connection panel
      applyToothConnectionType(upper, teethp, quarter, dragedConnectionElement.leftConnectionType);
    }

    if (
      dragedElement.toothType === -1 ||
      (labworkId > -1 && caseDetailObj.selectedLabWork !== labworkId)
    ) {
      return;
    }

    applyToothType(
      upper,
      teethp,
      quarter,
      dragedElement.toothType,
      dragedElement.draggedTeethp,
      dragedElement.draggedQuarter,
      dragedElement.draggedUpper,
    );
  }

  function applyNextToothType(
    teethp: number,
    quarter: number,
    type: number,
    labworkId: number,
    upper: boolean,
  ) {
    if (
      caseDetailObj.labworks.length === 0 ||
      (labworkId !== caseDetailObj.selectedLabWork && labworkId !== -1)
    ) {
      return;
    }
    if (
      caseDetailObj.selectedTeeth.teeth !== teethp ||
      caseDetailObj.selectedTeeth.quarter !== quarter
    ) {
      dispatch(setCaseDetailSelectedTooths({ teeth: teethp, quarter: quarter }));
      return;
    }

    const tt = caseDetailObj.labworks.find((toothtype) => {
      return toothtype.labWorkId === caseDetailObj.selectedLabWork;
    });

    const actualIndex = tt?.workType.teethTypes.indexOf(type);

    const teethTypeSizeOnWorkType =
      tt!.workType.teethTypes.length > 0 ? tt!.workType.teethTypes.length : 0;
    const nextIndex =
      actualIndex !== null && actualIndex >= 0 && actualIndex < teethTypeSizeOnWorkType - 1
        ? actualIndex + 1
        : 0;
    applyToothType(upper, teethp, quarter, tt!.workType.teethTypes[nextIndex], -1, -1, false);
  }

  return (
    <CaseWorkSheetToothPanoram
      onMouseLeave={() => {
        setZindex(0);
        setDragedElement({
          labWork: -1,
          toothType: -1,
          draggedTeethp: -1,
          draggedQuarter: -1,
          draggedUpper: false,
        });
        setDragedConnectionElement({ labWork: -1, leftConnectionType: -1, teeth: -1, quarter: -1 });
      }}
      onMouseDown={() => setZindex(10)}
      onMouseUp={() => setZindex(0)}
      style={{ zIndex: zindex + '' }}
    >
      <CaseWorkSheetToothPanoramaRow>
        <CaseWorkSheetToothPanoramaTooths>
          {caseDetailObj !== null &&
            caseDetailObj.tooths !== null &&
            caseDetailObj.tooths.upper.map((value, i) => {
              return (
                <Tooth
                  key={i}
                  teeth={value.teethp}
                  quarter={value.quarter}
                  width={value.width}
                  height={97}
                  type={value.type}
                  upper={true}
                  opacity={
                    value.labwork === caseDetailObj.selectedLabWork || value.labwork === -1
                      ? '1'
                      : '0.7'
                  }
                  selected={
                    caseDetailObj.selectedTeeth.teeth === value.teethp &&
                    caseDetailObj.selectedTeeth.quarter === value.quarter
                  }
                  // selected={true}
                  verticalOffset={value.selectionVerticalOffset}
                  onClick={() => {
                    if (validSubscription) {
                      applyNextToothType(
                        value.teethp,
                        value.quarter,
                        value.type,
                        value.labwork,
                        true,
                      );
                    } else {
                      showPaymentExpireModal(userRole);
                    }
                  }}
                  onMouseDown={() => {
                    if (isTabletSmall || !validSubscription) return;
                    if (caseDetailObj.workSheetEditable) {
                      setDragedElement({
                        labWork: value.labwork,
                        toothType: value.type,
                        draggedTeethp: value.teethp,
                        draggedQuarter: value.quarter,
                        draggedUpper: true,
                      });
                    }
                  }}
                  onMouseUp={() => {
                    if (isTabletSmall || !validSubscription) return;
                    if (caseDetailObj.workSheetEditable) {
                      setDragedElement({
                        labWork: -1,
                        toothType: -1,
                        draggedTeethp: -1,
                        draggedQuarter: -1,
                        draggedUpper: false,
                      });
                    }
                  }}
                  onMouseEnter={() => {
                    if (isTabletSmall || !validSubscription) return;
                    if (caseDetailObj.workSheetEditable) {
                      changeToothType(
                        value.teethp,
                        value.quarter,
                        dragedElement,
                        value.labwork,
                        true,
                      );
                    }
                  }}
                  editable={true}
                />
              );
            })}
        </CaseWorkSheetToothPanoramaTooths>
      </CaseWorkSheetToothPanoramaRow>

      <CaseWorkSheetToothPanoramaRow>
        <CaseWorkSheetToothPanoramaTooths>
          {caseDetailObj !== null &&
            caseDetailObj.tooths !== null &&
            caseDetailObj.tooths !== null &&
            caseDetailObj.tooths.lower.map((value, i) => {
              return (
                <Tooth
                  key={i}
                  teeth={value.teethp}
                  quarter={value.quarter}
                  width={value.width}
                  height={104}
                  type={value.type}
                  upper={false}
                  opacity={value.labwork === caseDetailObj.selectedLabWork ? '1' : '0.7'}
                  selected={
                    caseDetailObj.selectedTeeth.teeth === value.teethp &&
                    caseDetailObj.selectedTeeth.quarter === value.quarter
                  }
                  // selected={true}
                  verticalOffset={value.selectionVerticalOffset}
                  onClick={() => {
                    if (validSubscription) {
                      applyNextToothType(
                        value.teethp,
                        value.quarter,
                        value.type,
                        value.labwork,
                        false,
                      );
                    } else {
                      showPaymentExpireModal(userRole);
                    }
                  }}
                  onMouseDown={() => {
                    if (isTabletSmall || !validSubscription) return;
                    if (caseDetailObj.workSheetEditable) {
                      setDragedElement({
                        labWork: value.labwork,
                        toothType: value.type,
                        draggedTeethp: value.teethp,
                        draggedQuarter: value.quarter,
                        draggedUpper: false,
                      });
                    }
                  }}
                  onMouseUp={() => {
                    if (isTabletSmall || !validSubscription) return;
                    if (caseDetailObj.workSheetEditable) {
                      setDragedElement({
                        labWork: -1,
                        toothType: -1,
                        draggedTeethp: -1,
                        draggedQuarter: -1,
                        draggedUpper: false,
                      });
                    }
                  }}
                  onMouseEnter={() => {
                    if (isTabletSmall || !validSubscription) return;
                    if (caseDetailObj.workSheetEditable) {
                      changeToothType(
                        value.teethp,
                        value.quarter,
                        dragedElement,
                        value.labwork,
                        false,
                      );
                    }
                  }}
                  editable={true}
                />
              );
            })}
        </CaseWorkSheetToothPanoramaTooths>
      </CaseWorkSheetToothPanoramaRow>
    </CaseWorkSheetToothPanoram>
  );
}

const CaseWorkSheetToothPanoram = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 100%;

  flex: none;
  order: 1;
  flex-grow: 0;
`;

const CaseWorkSheetToothPanoramaTooths = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  justify-content: center;
  width: 100%;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
`;
