import { WorkTypeUiModel } from './worktype/WorkTypeUiModel';
import { Extra } from '../../domain/Extra';
import { Template } from '../../domain/worktype/templates/Template';
import { Gender } from '../../domain/Gender';
import { WorkPhaseGroup } from '../../domain/WorkPhaseGroup';
import { PhaseUiModel } from './PhaseUiModel';

export class CaseDetail {
  id: number | null;
  clientName: string | null;
  clientId: number | null;
  clientCompanyId: number | null;
  patientName: string | null;
  description: string | null;
  patientAge: number | null;
  patientGender: Gender['id'] | null;
  selectedLabWork: number | null;
  selectedTeeth: { teeth: number; quarter: number } | null;
  status: number | null;
  workSheetEditable: boolean | null;
  tooths: Tooths | null;
  labworks: DetailsLabWork[] | null;

  static newInstanceFrom(cd: CaseDetail | null) {
    const _cd = new CaseDetail();

    if (cd === null) {
      _cd.id = null;
      _cd.clientName = null;
      _cd.clientId = null;
      _cd.clientCompanyId = null;
      _cd.description = null;
      _cd.patientName = null;
      _cd.patientAge = null;
      _cd.patientGender = null;
      _cd.selectedLabWork = null;
      _cd.selectedTeeth = null;
      _cd.status = null;
      _cd.workSheetEditable = null;
      _cd.tooths = null;
      _cd.labworks = null;
    } else {
      _cd.id = cd.id;
      _cd.clientName = cd.clientName ?? null;
      _cd.clientId = cd.clientId ?? null;
      _cd.clientCompanyId = cd.clientCompanyId ?? null;
      _cd.description = cd.description ?? null;
      _cd.patientName = cd.patientName ?? null;
      _cd.patientAge = cd.patientAge ?? null;
      _cd.patientGender = cd.patientGender ?? null;
      _cd.selectedLabWork = cd.selectedLabWork ?? null;
      _cd.selectedTeeth = cd.selectedTeeth ?? null;
      _cd.status = cd.status ?? null;
      _cd.workSheetEditable = cd.workSheetEditable ?? null;
      _cd.tooths = cd.tooths ?? null;
      _cd.labworks = cd.labworks ?? null;
    }
    return _cd;
  }
}

export class Tooths {
  upper: {
    id?: number;
    width: number;
    teethp: number;
    type: number;
    labwork: number;
    quarter: number;
    leftConnectionType: number;
    cwidth: string;
    height: string;
    left: string;
    top: string;
    ext: Array<Extra>;
    selectionVerticalOffset: number;
  }[];
  lower: {
    id?: number;
    width: number;
    teethp: number;
    type: number;
    labwork: number;
    quarter: number;
    leftConnectionType: number;
    cwidth: string;
    height: string;
    left: string;
    top: string;
    ext: Array<Extra>;
    selectionVerticalOffset: number;
  }[];
}

export class DetailsLabWork {
  labWorkId: number;
  workType: WorkTypeUiModel | null;
  phases?: WorkPhaseGroup[] | null;
  editedPhases?: Array<PhaseUiModel> | null;
  labworkName?: string;
  extensions: number[];
  extras: Array<Extra>;

  constructor(
    labWorkId: number,
    workType: WorkTypeUiModel,
    extensions: number[],
    extras: Array<Extra>,
  ) {
    this.labWorkId = labWorkId;
    this.workType = workType;
    this.extensions = extensions;
    this.extras = extras;
  }
}

export type SelectedTemplate = ExistingTemplate | NewTemplate;

type ExistingTemplate = { kind: 'Existing'; id: Template['id'] };
type NewTemplate = { kind: 'New'; name: Template['name'] };
