import React from 'react';
import styled from 'styled-components';

import { InputText } from '../dsm/InputText';

type SearchbarProps = {
  placeHolder: string;
  onHandleChangeSearchBar: (e) => void;
  onHandleCloseSearch: () => void;
  searchInputStyle: React.CSSProperties;
};

const Searchbar = (props: SearchbarProps) => {
  const { placeHolder, onHandleChangeSearchBar, onHandleCloseSearch, searchInputStyle } = props;
  return (
    <SearchContainer className='input-search'>
      <InputText
        placeHolder={placeHolder}
        width='100%'
        initValue={''}
        onChange={onHandleChangeSearchBar}
        customStyle={searchInputStyle}
        isSearch={true}
        onKeyDown={() => {}}
        clickClose={onHandleCloseSearch}
        onBlur={() => {}}
      />
    </SearchContainer>
  );
};

const Container = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
`;

const SearchContainer = styled(Container)`
  padding: 6px 8px 6px 8px;
  min-height: 24px;

  &.input-search {
    padding: 0px;
    background: var(--dts_white);
    background: white;
  }
`;

export default Searchbar;
