import React, { FC } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../../components/dsm/Text';
import { PayOffPhaseCard } from './PayOffPhaseCard';
import { PayOffLabworkUi, PayOffCollaborator } from '../../../ui-model/reports/PayOffUIModel';

export type PayOffLabworkCardProps = {
  payOffLabworkModel: PayOffLabworkUi;
  solvedPhase: (
    labworkId: number,
    isPaid: boolean,
    solvedPhaseIds: Map<string, number>,
    unSolvedPhaseIds: Map<string, number>,
  ) => void;
  selectedCollaborator: PayOffCollaborator;
};

export const PayOffLabworkCard: FC<PayOffLabworkCardProps> = (payOffLabworkCardProps) => {
  const { payOffLabworkModel, solvedPhase, selectedCollaborator } = payOffLabworkCardProps;

  const handleSolvedPhase = (phaseId: number, order: number) => {
    const solvedPhaseIds = new Map<string, number>();
    const unSolvedPhaseIds = new Map<string, number>();
    let isPaid = false;
    payOffLabworkModel.phases?.forEach((phase) => {
      if (phase.phaseId !== null) {
        if (phaseId === phase.phaseId && order === phase.order) {
          if (phase.accounted === 1) {
            unSolvedPhaseIds.set(phase.phaseId.toString(), phase.order);
            isPaid = false;
          } else {
            solvedPhaseIds.set(phase.phaseId.toString(), phase.order);
            isPaid = true;
          }
        }
      }
    });
    solvedPhase(payOffLabworkModel.labworkId, isPaid, solvedPhaseIds, unSolvedPhaseIds);
  };

  return (
    <RowBody>
      <Text type={TextType.CAPTION_BOLD}>{payOffLabworkModel.title}</Text>
      <Text type={TextType.CAPTION_REGULAR}>{payOffLabworkModel.subTitle}</Text>
      <TeethsContainer>
        {payOffLabworkModel.teeths !== null &&
          payOffLabworkModel.teeths !== null &&
          payOffLabworkModel.teeths.map((teeth, index) => {
            return (
              <TeethBox key={index}>
                <Text type={TextType.CAPTION_2_BOLD_12}>{teeth.id}</Text>
                {teeth.connection && <Connection />}
              </TeethBox>
            );
          })}
      </TeethsContainer>
      {payOffLabworkModel.phases !== null && payOffLabworkModel.phases.length > 0 && (
        <div>
          {payOffLabworkModel.phases.map((phase, index) => {
            if (
              (selectedCollaborator.collaboratorId === phase.collaboratorId &&
                selectedCollaborator.phaseIds?.some(
                  (c) => c.id === phase.phaseId && c.order === phase.order,
                )) ||
              selectedCollaborator.collaboratorId === -1
            ) {
              return (
                <PayOffPhaseCard
                  key={index}
                  payOffPhasekModel={phase}
                  solvedPhase={handleSolvedPhase}
                />
              );
            }
          })}
        </div>
      )}
    </RowBody>
  );
};

const RowBody = styled.div`
  padding: 24px;
  background: var(--dts_white);
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--dts_withe_background);
`;

const TeethsContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 4px;
  flex-grow: 1;
  min-heigth: 24px;
  margin-bottom: 8px;
`;

const TeethBox = styled.div`
  padding: 6px 8px;
  border-radius: 5px;
  background: var(--dts_light_grey);
  position: relative;
`;

const Connection = styled.span`
  position: absolute;
  width: 4px;
  height: 4px;
  background: var(--dts_light_grey);
  right: -4px;
  top: 50%;
`;
