import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../data/utils/store';

interface AuthContextType {
  token: string | null;
  userId: number | null;
  isAuthenticated: boolean;
  language: string | null;
  isLoadingCompanyData: boolean | null;
  companyId: number | null;
  userRole: number | null;
  companyName: string | null;
  isFtiFinished: boolean | null;
  firstName: string | null;
  lastName: string | null;
  profileColor: string | null;
  phoneNumber: string | null;
  email: string | null;
  validSubscription: boolean | null;
  packageType: number | null;
  billingType: number | null;
  subscriptionEndTime: number | null;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const token = auth.token;
  const userId = auth.userId;
  const isAuthenticated = token !== null;
  const language = auth.language;
  const isLoadingCompanyData = auth.isLoadingCompanyData;
  const companyId = auth.companyId;
  const userRole = auth.userRole;
  const companyName = auth.companyName;
  const isFtiFinished = auth.isFtiFinished;
  const firstName = auth.firstName;
  const lastName = auth.lastName;
  const profileColor = auth.profileColor;
  const phoneNumber = auth.phoneNumber;
  const email = auth.email;
  const validSubscription = auth.validSubscription;
  const packageType = auth.packageType;
  const billingType = auth.billingType;
  const subscriptionEndTime = auth.subscriptionEndTime;

  return (
    <AuthContext.Provider
      value={{
        token,
        userId,
        isAuthenticated,
        language,
        isLoadingCompanyData,
        companyId,
        userRole,
        companyName,
        isFtiFinished,
        firstName,
        lastName,
        profileColor,
        phoneNumber,
        email,
        validSubscription,
        packageType,
        billingType,
        subscriptionEndTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
