import { LoggedCompanyData } from '../../domain/authenticationFlow/AuthDataTypes';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

type laborUseCasesRepository = Pick<AuthRepository, 'setLaboratory' | 'startSetLaboratory'>;

const startSetLaboratoryUseCase = (repo: laborUseCasesRepository) => {
  repo.startSetLaboratory();
};

const setLaboratoryUseCases = (
  repo: laborUseCasesRepository,
  companyData: LoggedCompanyData | null,
) => {
  return repo.setLaboratory(companyData);
};

export { startSetLaboratoryUseCase, setLaboratoryUseCases };
export type { laborUseCasesRepository };
