import {
  AuthenticateResponse,
  CreateAccountData,
  Credentials,
  LoginResponse,
  PasswordData,
  SetPasswordData,
  UpdateUserData,
  UpdateUserResponse,
} from '../../domain/authenticationFlow/AuthDataTypes';
import { BASE_URL } from '../Server';
import { fetchWithAuth } from '../utils/fetchWithAuth';

const handleLogin = async (c: Credentials): Promise<boolean | LoginResponse> => {
  try {
    const response = await fetch(BASE_URL + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(c),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const updateUser = async (data: UpdateUserData): Promise<UpdateUserResponse> => {
  try {
    const response = await fetchWithAuth(BASE_URL + '/updateUser', {
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

function addUser(createAccount: {
  email: string;
  isEnabledMailList: boolean;
}): Promise<CreateAccountData> {
  return fetch(BASE_URL + '/addUser', {
    method: 'POST',
    body: JSON.stringify(createAccount),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: 'Something went wrong', data: null };
    });
}

function setPassword(password: PasswordData): Promise<AuthenticateResponse> {
  return fetch(BASE_URL + '/updatePassword', {
    method: 'POST',
    body: JSON.stringify(password),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return {
        code: res.status !== null ? res.status : 200,
        toke: res.toke,
        userId: res.userId,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phoneNumber: res.phoneNumber,
        language: res.language,
        profileColor: res.profileColor,
        isFtiFinished: res.isFtiFinished,
      };
    })
    .catch((error) => {
      return {
        code: error.status,
        toke: '',
        userId: -1,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        language: '',
        profileColor: '',
        isFtiFinished: false,
      };
    });
}

function resetPassword(requestPassword: { email: string }): Promise<SetPasswordData> {
  return fetch(BASE_URL + '/requestResetPassword', {
    method: 'POST',
    body: JSON.stringify(requestPassword),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: 'Something went wrong', data: null };
    });
}

export { handleLogin, updateUser, addUser, setPassword, resetPassword };
