import { BASE_URL } from '../../Server';
import { UpdateTeethTypeInfo } from '../../../domain/worktype/teeth/UpdateTeethTypeInfo';
import { TeethData } from '../../../domain/worktype/teeth/Teeth';
import { fetchWithAuthAndCompanyId } from '../../utils/fetchWithAuthAndCompanyId';

const getTeeth = async (): Promise<TeethData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/teeth', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return { code: 200, message: 'Success', data: data };
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateTeethTypeInfo = async (workTypeId: number, teethList: UpdateTeethTypeInfo[]) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/updateTeethTypeInfo', {
      method: 'POST',
      body: JSON.stringify({ id: workTypeId, items: teethList }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export { getTeeth, updateTeethTypeInfo };
