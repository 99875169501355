import React from 'react';
import { TeethRepository } from '../../../../domain/worktype/teeth/TeethRepository';
import { WorkTypeRepository } from '../../../../domain/worktype/WorkTypeRepository';
import { getSortTeethUseCase } from '../../../../useCases/worktype/teeth/getTeethsUseCases';
import { updateTeethUseCase } from '../../../../useCases/worktype/teeth/updateTeethUseCase';
import { UpdateTeethTypeInfo } from '../../../../domain/worktype/teeth/UpdateTeethTypeInfo';
import { TeethUiModel } from '../../../ui-model/worktype/TeethUiModel';
import { TeethType } from '../../../../domain/worktype/teeth/TeethType';
import useTranslate from '../../../translations/useTranslate';

function useTeethViewModel(workTypeRepo: WorkTypeRepository, repo: TeethRepository) {
  const translate = useTranslate();

  const getTeethsByWorkTypeId = (id: number): TeethUiModel[] | [] => {
    if (workTypeRepo.workTypes === null) return [];
    const teethTypes = workTypeRepo.workTypes.find((wt) => wt.id === id);
    return getTeethUi(teethTypes === undefined ? null : teethTypes.teethTypeList).map((teeth) => ({
      ...teeth,
      name: translate(teeth.name),
    }));
  };

  const getSortTeethsByWorkTypeId = (id: number): TeethUiModel[] | [] => {
    if (workTypeRepo.workTypes === null) return [];
    const teethTypes = workTypeRepo.workTypes.find((wt) => wt.id === id);
    return getTeethUi(
      getSortTeethUseCase(teethTypes === undefined ? null : teethTypes.teethTypeList).map(
        (teeth) => ({
          ...teeth,
          name: translate(teeth.name),
          extensionList: teeth.extensionList?.map((item) => ({
            ...item,
            name: translate(item.name),
          })),
        }),
      ),
    );
  };

  function getTeethUi(teethList: TeethType[] | null): TeethUiModel[] {
    if (teethList === null) {
      return [];
    }
    const teethUiList: TeethUiModel[] = [];
    teethList?.forEach((teeth) => {
      if (teeth.id !== null && teeth.name !== null) {
        teethUiList.push(
          new TeethUiModel(teeth.id, teeth.name, teeth.active ? true : false, teeth.order),
        );
      }
    });

    return teethUiList;
  }

  const updateTeeth = React.useCallback(
    function (worktypeId: number, workTypeRepo: WorkTypeRepository, teethList: TeethUiModel[]) {
      updateTeethUseCase(
        { updateTeeth: repo.updateTeeth },
        workTypeRepo,
        worktypeId,
        chnageTeethUiModelToUpdateTeethTypeInfo(teethList),
      );
    },
    [repo.updateTeeth],
  );

  return {
    isLoading: repo.isLoading,
    isUpdating: repo.isUpdating,
    getTeethsByWorkTypeId,
    getSortTeethsByWorkTypeId,
    updateTeeth,
  };
}

function chnageTeethUiModelToUpdateTeethTypeInfo(teethList: TeethUiModel[]) {
  return teethList.map((teeth) => {
    return new UpdateTeethTypeInfo(
      teeth.id,
      teeth.active === null ? 0 : teeth.active ? 1 : 0,
      teeth.order === null ? -1 : teeth.order,
    );
  });
}

export { useTeethViewModel };
