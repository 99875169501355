import React from 'react';
import styled from 'styled-components';

import { BaseDialog } from './BaseDialog';
import { Text, TextType } from '../dsm/Text';
import { Button } from '../dsm/buttons/Button';

export type SimpleConfirmationProps = {
  modalName: string;
  description: string;
  firstButtonText: string;
  secondButtonText: string;
  value: number;
  onHandleAction: (id: number) => void;
  onClose: () => void;
};

const SimpleConfirmationDialog = (props: SimpleConfirmationProps) => {
  const {
    modalName,
    description,
    firstButtonText,
    secondButtonText,
    value,
    onHandleAction,
    onClose,
  } = props;

  return (
    <BaseDialog
      isOpen={true}
      title={modalName}
      titleTextType={TextType.BODY_BOLD}
      onClose={onClose}
      containerStyle={{ width: '406px' }}
      header={true}
      isScroll={false}
    >
      <Text type={TextType.BODY_REGULAR}>{description}</Text>
      <ButtonsContainer>
        <Button secondary='secondary' onClick={onClose}>
          {firstButtonText}
        </Button>
        <Button
          onClick={() => {
            onHandleAction(value);
            onClose();
          }}
        >
          {secondButtonText}
        </Button>
      </ButtonsContainer>
    </BaseDialog>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export default SimpleConfirmationDialog;
