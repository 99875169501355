import {
  LoggedCompanyData,
  LoginResponse,
  UpdateUserDataResponse,
} from '../../domain/authenticationFlow/AuthDataTypes';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const SET_LABORATORY_START = 'LABOR_LOGIN_START';
export const SET_LABORATORY_SUCCESS = 'LABOR_LOGIN_SUCCESS';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILD = 'ADD_USER_FAILD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILD = 'SET_PASSWORD_FAILD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILD = 'RESET_PASSWORD_FAILD';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILD = 'AUTHENTICATE_USER_FAILD';

export const loginSuccess = (loginResponse: LoginResponse) => {
  // Mentjük el a token-t a localStorage-be
  localStorage.setItem('token', loginResponse.toke);
  localStorage.setItem('userId', loginResponse.userId + '');
  localStorage.setItem('email', loginResponse.email);
  localStorage.setItem('language', loginResponse.language);
  localStorage.setItem('firstName', loginResponse.firstName);
  localStorage.setItem('lastName', loginResponse.lastName);
  localStorage.setItem('name', loginResponse.lastName + ' ' + loginResponse.firstName);
  localStorage.setItem('phoneNumber', loginResponse.phoneNumber);
  localStorage.setItem('profileColor', loginResponse.profileColor);
  localStorage.setItem('isFtiFinished', loginResponse.isFtiFinished + '');

  return {
    type: LOGIN_SUCCESS,
    payload: loginResponse,
  };
};

export const updateUserSuccess = (updateUserResponse: UpdateUserDataResponse) => {
  localStorage.setItem('userId', updateUserResponse.id + '');
  localStorage.setItem('email', updateUserResponse.email);
  localStorage.setItem('language', updateUserResponse.language);
  localStorage.setItem('firstName', updateUserResponse.firstname);
  localStorage.setItem('lastName', updateUserResponse.lastname);
  localStorage.setItem('name', updateUserResponse.lastname + ' ' + updateUserResponse.firstname);
  localStorage.setItem('phoneNumber', updateUserResponse.tellNumber);
  localStorage.setItem('profileColor', updateUserResponse.profileColor + '');
  return {
    type: UPDATE_USER_SUCCESS,
    payload: updateUserResponse,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('email');
  localStorage.removeItem('language');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('name');
  localStorage.removeItem('phoneNumber');
  localStorage.removeItem('profileColor');
  localStorage.removeItem('isFtiFinished');
  return {
    type: LOGOUT,
  };
};

export const setLaboratory = (companyData: LoggedCompanyData | null) => {
  return {
    type: SET_LABORATORY_SUCCESS,
    payload: { companyData: companyData },
  };
};

export const setSubscription = (subscriptionData: {
  validSubscription: boolean;
  packageType: number;
  billingType: number;
  subscriptionEndTime: number;
}) => {
  return {
    type: SET_SUBSCRIPTION,
    payload: { subscriptionData: subscriptionData },
  };
};
