import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DropdownIcon } from '../../../icons/dropDownIcon.svg';
import { Text, TextType } from './Text';
import Tooltip from './Tooltip';

export type SearchableDropdownProps = {
  values: Array<SearchableDropdownValue>;
  selected: SearchableDropdownValue | null;
  onChange: (value: SearchableDropdownValue) => void;
  onClickText: (value: SearchableDropdownValue | null) => void;
  highlightedOption?: HighlightedOption;
};

export type SearchableDropdownValue = { key?: number; label: string };
type HighlightedOption = { onClick: () => void; label: string; icon?: JSX.Element };

// TODO: Unify SearchableDropdown and Dropdown
const SearchableDropdown = (props: SearchableDropdownProps) => {
  const { values, selected, onChange, onClickText, highlightedOption } = props;

  const [filteredValues, setFilteredValues] = useState<SearchableDropdownProps['values']>(
    props.values,
  );
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const optionRefs = useRef<Array<HTMLElement>>([]);
  const [visibleTooltips, setVisibleTooltips] = useState<Array<number>>([]);

  /**
   * Update filtered list on "values" or "selected" change and call onChange
   */
  useEffect(() => {
    setFilteredValues(
      values.filter((value) =>
        value.label.toLowerCase().includes(selected?.label.toLowerCase() || ''),
      ),
    );
  }, [selected]);

  useEffect(() => {
    optionRefs.current.forEach((itemRef, index) => {
      if (itemRef?.scrollWidth > itemRef?.clientWidth && !visibleTooltips.includes(index)) {
        setVisibleTooltips((prevVisibleTooltips) => [...prevVisibleTooltips, index]);
      }
    });
  }, [isOpen, optionRefs]);

  const handleOptionClick = (value: SearchableDropdownValue) => {
    setIsOpen(false);
    onChange(value);
  };

  const handleClickText = (value: SearchableDropdownValue | null) => {
    onClickText(value);
  };

  const renderHighlightedOption = useMemo(() => {
    return highlightedOption ? (
      <Option className='highlighted' onMouseDown={highlightedOption.onClick}>
        <Text type={TextType.CAPTION_REGULAR_2}>{highlightedOption.label}</Text>
        {highlightedOption.icon}
      </Option>
    ) : (
      <React.Fragment />
    );
  }, [highlightedOption]);

  const renderOptions = useMemo(() => {
    return (selected?.key ? values : filteredValues).map((value, index) => {
      return (
        <Tooltip
          key={index}
          content={value.label}
          disabled={!visibleTooltips.includes(index)}
          placement='right'
        >
          <Option
            className={selected?.key === value?.key ? 'selected' : ''}
            onMouseDown={() => handleOptionClick(value)}
          >
            <Text
              ref={(el) => {
                if (el) optionRefs.current[index] = el;
              }}
              type={TextType.CAPTION_BOLD}
              ellipsis={true}
            >
              {value.label}
            </Text>
          </Option>
        </Tooltip>
      );
    });
  }, [filteredValues, visibleTooltips]);

  return (
    <Container>
      <NameTextConatiner onClick={() => handleClickText(selected)}>
        <NameText type={TextType.CAPTION_BOLD} ellipsis={true}>
          {selected?.label}
        </NameText>
      </NameTextConatiner>
      <SelectorLine />
      <DropdownIconContainer
        ref={inputRef}
        onClick={() => setIsOpen(!isOpen)}
        onBlur={() => setIsOpen(false)}
      >
        <DropdownIconStyled className={isOpen ? 'open' : ''} />
      </DropdownIconContainer>
      {isOpen && (filteredValues.length > 0 || highlightedOption) && (
        <OptionsContainer>
          <Options className='custom-scrollbar'>
            {renderHighlightedOption}
            {renderOptions}
          </Options>
        </OptionsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  user-select: none;
  display: flex;
  border-radius: 3px;
`;

const NameTextConatiner = styled.div`
  padding: 8px;
  border-radius: 3px 1px 1px 3px;
  background-color: var(--dts_default_blue);
  min-width: 175px;
  max-width: 175px;
`;

const NameText = styled(Text)`
  color: var(--dts_white);
`;

const OptionsContainer = styled.div`
  width: 100%;
  position: absolute;
  background: var(--dts_white);
  box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  margin-top: 2px;
  padding: 24px;
  box-sizing: border-box;
  z-index: 100;
  top: 100%;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 356px;
  overflow-y: scroll;
`;

const Option = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: var(--dts_black);
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
  }

  :hover,
  &.selected {
    background-color: var(--dts_withe_background);
  }

  &.highlighted {
    p {
      color: var(--dts_white);
    }
  }
`;

const SelectorLine = styled.span`
  width: 1px;
  background-color: var(--dts_default_blue);
  border-left: var(--dts_white);
  opacity: 0.4;
`;

const DropdownIconContainer = styled.div`
  padding: 4px 8px 4px 8px;
  border-radius: 1px 3px 3px 1px;
  background-color: var(--dts_default_blue);
  cursor: pointer;
`;

const DropdownIconStyled = styled(DropdownIcon)`
  width: 12px;
  height: 8px;
  path {
    fill: var(--dts_white);
  }
  &.open {
    transform: rotate(180deg);
  }
`;

export default SearchableDropdown;
