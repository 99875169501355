import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateRepository } from '../../../domain/worktype/templates/TemplateRepository';
import { TemplatePhases } from '../../../domain/worktype/templates/TemplatePhases';
import { NewTemplate } from '../../../domain/worktype/templates/NewTemplate';
import { UpdateTemplate } from '../../../domain/worktype/templates/UpdateTemplate';
import { UpdateTemplateOrderOrActive } from '../../../domain/worktype/templates/UpdateTemplateOrderOrActive';
import { TemplateBySubWorkType } from '../../../domain/worktype/templates/TemplateBySubWorkType';
import { TemplateRepositoryState } from './templateReducer';
import {
  getTemplatesAction,
  newTemplatesAction,
  duplicateTemplateAction,
  deleteTemplateAction,
  updateTemplateAction,
  updateTemplateOrderOrActiveAction,
  addTemplateBySubWorkTypeAction,
  removeTemplateFromSubWorkTypeAction,
  addPhasesToTemplateAction,
} from './templateAction';
import { RootState } from '../../utils/store';

const templateSelector = (state: RootState) => state.template;

const useTemplateRepositoryImplementation = (): TemplateRepository => {
  const { templates, isLoading, isUpdating } = useSelector<RootState, TemplateRepositoryState>(
    templateSelector,
  );

  const dispatch = useDispatch();

  const getTemplates = React.useCallback(() => getTemplatesAction()(dispatch), [dispatch]);

  const newTemplate = React.useCallback(
    (newTemplateItem: NewTemplate) => newTemplatesAction(newTemplateItem)(dispatch),
    [dispatch],
  );

  const duplicateTemplate = React.useCallback(
    (id: number) => duplicateTemplateAction(id)(dispatch),
    [dispatch],
  );

  const deleteTemplate = React.useCallback(
    (id: number) => deleteTemplateAction(id)(dispatch),
    [dispatch],
  );

  const updateTemplate = React.useCallback(
    (template: UpdateTemplate) => updateTemplateAction(template)(dispatch),
    [dispatch],
  );

  const updateTemplateOrderOrActive = React.useCallback(
    (templates: UpdateTemplateOrderOrActive[]) =>
      updateTemplateOrderOrActiveAction(templates)(dispatch),
    [dispatch],
  );

  const addTemplateBySubWorkType = React.useCallback(
    (templateBySubWorkType: TemplateBySubWorkType) =>
      addTemplateBySubWorkTypeAction(templateBySubWorkType)(dispatch),
    [dispatch],
  );

  const removeTemplateFromSubWorkType = React.useCallback(
    (templateID: number, extensionID: number | null) =>
      removeTemplateFromSubWorkTypeAction(templateID, extensionID)(dispatch),
    [dispatch],
  );

  const addPhasesToTemplate = React.useCallback(
    (templatePhases: TemplatePhases) => addPhasesToTemplateAction(templatePhases)(dispatch),
    [dispatch],
  );

  return {
    templates,
    isLoading,
    isUpdating,
    getTemplates,
    newTemplate,
    duplicateTemplate,
    deleteTemplate,
    updateTemplate,
    updateTemplateOrderOrActive,
    addTemplateBySubWorkType,
    removeTemplateFromSubWorkType,
    addPhasesToTemplate,
  };
};

export { useTemplateRepositoryImplementation };
