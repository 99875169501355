import { Button } from '../components/dsm/buttons/Button';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { CaseDetailContext } from './CaseDetailProvider';
import { useCaseDetailFooterPopUpViewModel } from './CaseDetailFooterPopUpViewModel';
import { DRAFT } from './caseStates';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { useAuth } from '../../routeFiles/AuthContext';

export function CaseDetailFooterPopUp() {
  const translate = useTranslate();
  const { caseDetailObj, repo: caseRepo, validSubscription } = useContext(CaseDetailContext);
  const featureRepo = featuresRepositoryImplementation();

  const { createCase, isEnabledPublichAction } = useCaseDetailFooterPopUpViewModel(
    caseRepo,
    featureRepo,
  );
  const { userRole } = useAuth();

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  if (
    caseDetailObj !== null &&
    caseDetailObj.status === DRAFT &&
    userRole !== null &&
    isEnabledPublichAction(userRole)
  ) {
    return (
      <CaseDetailFooterPopUpContainer>
        <Content>
          <Buttons>
            <Button
              onClick={() => {
                if (validSubscription) {
                  createCase(caseDetailObj);
                } else {
                  showPaymentExpireModal(userRole);
                }
              }}
              style={{ minWidth: '120px', padding: '10px 40px' }}
            >
              {translate(LANGUAGE_KEYS.PUBLISH)}
            </Button>
          </Buttons>
        </Content>
      </CaseDetailFooterPopUpContainer>
    );
  } else {
    return null;
  }
}

const CaseDetailFooterPopUpContainer = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 1px;
  position: absolute;
  width: 100%;
  height: 85px;
  right: 0;
  bottom: 0;
  z-index: 190;
  /* Dark gray */
  border: 1px solid var(--dts_light_grey);
  /* shadow */
  filter: drop-shadow(-1px 2px 11px rgba(0, 0, 0, 0.14));
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px;
  height: 36px;
  /* DTS_White */
  background: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;

  // width: 392px;
  // height: 36px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;
