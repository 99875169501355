import { UpdateUserData } from '../../domain/authenticationFlow/AuthDataTypes';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

type updateUserUseCasesRepository = Pick<AuthRepository, 'updateUser'>;

const updateUserUseCases = (repo: updateUserUseCasesRepository, data: UpdateUserData) => {
  return repo.updateUser(data);
};

export { updateUserUseCases };
export type { updateUserUseCasesRepository };
