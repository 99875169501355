import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { WorkTypeRepository } from '../../domain/worktype/WorkTypeRepository';
import { getWorkTypeAction, updateWorkTypeAction } from './workTypeActions';
import { WorkTypeRepositoryState } from './workTypeReducer';
import UpdateWorTypeInfo from '../../domain/worktype/UpdateWorTypeInfo';
import { RootState } from '../utils/store';

const workTypeSelector = (state: RootState) => state.workType;

const useWorkTypeRepositoryImplementation = (): WorkTypeRepository => {
  const { workTypes, isLoading, isUpdating } = useSelector<RootState, WorkTypeRepositoryState>(
    workTypeSelector,
  );

  const dispatch = useDispatch();

  const getWorkTypes = React.useCallback(() => getWorkTypeAction()(dispatch), [dispatch]);

  const updateWorkTypes = React.useCallback(
    (worktypeList: UpdateWorTypeInfo[]) => updateWorkTypeAction(worktypeList)(dispatch),
    [dispatch],
  );

  return {
    workTypes,
    isLoading,
    isUpdating,
    getWorkTypes,
    updateWorkTypes,
  };
};

export { useWorkTypeRepositoryImplementation };
