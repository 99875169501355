import React from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../components/dsm/Text';
import FormAction from '../components/FormAction';
import { useLocation } from 'react-router';
import checkEmail from '../../../images/CheckEmail.png';
import { MobileBackButton } from '../../components/generic/MobileBackButton';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../../DeviceInformations';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';

const CheckEmail = () => {
  const translate = useTranslate();
  const location = useLocation();
  const { state } = location;
  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  return (
    <>
      {isMobile && <MobileBackButton />}
      <CheckEmailContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <ContentContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <TitleText type={TextType.TITLE_BOLD_2}>
            {translate(LANGUAGE_KEYS.CHECK_YOUR_INBOX)}
          </TitleText>
          <DescriptionText type={TextType.BODY_2}>
            {translate(LANGUAGE_KEYS.CLICK_ON_THE_LINK, {
              email: state !== null && state.email !== null && state.email,
            })}
          </DescriptionText>
          <CustomImg src={checkEmail} alt='check mail image' width={200} height={200} />
          <SendAgainText type={TextType.BODY_2}>
            {translate(LANGUAGE_KEYS.CANT_FIND_MAIL_INBOX_SPAM)}{' '}
            <span onClick={() => {}}>{translate(LANGUAGE_KEYS.CLICK_HERE)}</span>{' '}
            {translate(LANGUAGE_KEYS.TO_SEND_AGAIN)}
          </SendAgainText>
          <WrongAddressText>
            <Text type={TextType.CAPTION_REGULAR}>
              {translate(LANGUAGE_KEYS.WRONG_ADDRESS)}
              <FormAction
                handleSubmit={() => {}}
                text={translate(LANGUAGE_KEYS.LOG_OUT)}
                action={null}
                type='Text'
                disabled={null}
              />
              {translate(LANGUAGE_KEYS.LOG_OUT_TO_SIGN_IN_WITH_DIFFERENT)}
            </Text>
          </WrongAddressText>
        </ContentContainer>
      </CheckEmailContainer>
    </>
  );
};

const CheckEmailContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    height: calc(100% - 68px);
    display: flex;
    align-items: center;
    flex-direction: column;
    //Desktop
    @media (min-width: ${desktop}px) {
      height: calc(100% - 68px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ContentContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 288px;
    min-width: 288px;
    //Desktop
    @media (min-width: ${desktop}px) {
      margin-top: 68px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-top: 20px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const TitleText = styled(Text)`
  margin-bottom: 68px;
`;

const DescriptionText = styled(Text)`
  text-align: left;
  margin-bottom: 24px;
`;

const SendAgainText = styled(Text)`
  margin-top: 16px;
  span {
    color: var(--dts_default_blue);
    cursor: pointer;
    &:hover {
      color: var(--dts_hover_blue);
    }
  }
`;

const WrongAddressText = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const CustomImg = styled.img`
  margin: auto;
`;

export default CheckEmail;
