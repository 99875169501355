import { RootState } from '../utils/store';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { LobbyRepository } from '../../domain/lobby/LobbyRepository';
import { getLobbyCompaniesAction } from './lobbyActions';
import { LobbyRepositoryState } from './lobbyReducer';

const lobbyCompaniesSelector = (state: RootState) => state.lobby;

const lobbyRepositoryImplementation = (): LobbyRepository => {
  const { lobbyCompanies, isLoading, isUpdating } = useSelector<RootState, LobbyRepositoryState>(
    lobbyCompaniesSelector,
  );

  const dispatch = useDispatch();

  const getLobbyCompanies = React.useCallback(
    (userId: number | null) => {
      return getLobbyCompaniesAction(userId)(dispatch);
    },
    [dispatch],
  );

  return {
    lobbyCompanies,
    isLoading,
    isUpdating,
    getLobbyCompanies,
  };
};

export { lobbyRepositoryImplementation };
