import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ExpenseRow } from './ExpenseRow';
import { useReportRepositoryImplementation } from '../../../../data/reports/ReportRepositoryImplementation';
import { useCompaniesRepositoryImplementation } from '../../../../data/user-management/UsersRepositoryImplementation';
import { useWorkTypeRepositoryImplementation } from '../../../../data/worktype/WorkTypeRepositoryImplementation';
import { useInvoiceRepositoryImplementation } from '../../../../data/invoice/InvoiceRepositoryImplementation';
import { useExpenseViewModel } from './ExpenseViewModel';
import { ExpenseUiModel } from '../../../ui-model/reports/ExpenseUiModel';
import { useAlertManagerImplementation } from '../../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../../alert/AlertViewModel';
import { AlertType } from '../../../../domain/alert/AlertManager';
import { ReportsOverviewType } from '../../../ui-model/reports/ReportOverviewUiModel';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

function Expense(props: { companyId: number | null; caseIds: Array<number> }) {
  const navigate = useNavigate();
  const translate = useTranslate();
  const repo = useReportRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const workTypeRepo = useWorkTypeRepositoryImplementation();
  const invoiceRepo = useInvoiceRepositoryImplementation();
  const { expenseList, getReportCasesByIds, generateInvoice } = useExpenseViewModel(
    repo,
    companiesRepo,
    workTypeRepo,
    invoiceRepo,
  );

  const alertManager = useAlertManagerImplementation();
  const { showAlert, hideAlert } = useAlertViewModel(alertManager);

  const [expenseLocalList, setExpenseLocalList] = useState<Array<ExpenseUiModel> | null>(null);

  useEffect(() => {
    getReportCasesByIds(props.caseIds);
  }, [
    workTypeRepo.getWorkTypes,
    workTypeRepo.workTypes,
    companiesRepo.getCompanies,
    companiesRepo.companies,
  ]);

  useEffect(() => {
    setExpenseLocalList([
      ...expenseList.map((e) => {
        const newExpenseCases =
          e.expenseCases === null
            ? null
            : e.expenseCases.map((c) => {
                return {
                  ...c,
                  isSelected: c.invoice === null,
                };
              });
        return {
          ...e,
          expenseCases: newExpenseCases !== null ? newExpenseCases : e.expenseCases,
        };
      }),
    ]);
  }, [expenseList]);

  const handleCheck = (caseId: number, active: boolean) =>
    expenseLocalList &&
    setExpenseLocalList([
      ...expenseLocalList.map((e) => {
        let casePrice = 0;
        const newExpenseCases =
          e.expenseCases === null
            ? null
            : e.expenseCases.map((c) => {
                if (c.caseId === caseId) casePrice = c.allLabworkPrice;
                return {
                  ...c,
                  isSelected: c.isSelected
                    ? !(caseId === -1 && !active) && c.caseId !== caseId
                    : (caseId === -1 && active) || c.caseId === caseId,
                };
              });
        return {
          ...e,
          expenseCases: newExpenseCases !== null ? newExpenseCases : e.expenseCases,
          allCasePrice: active ? e.allCasePrice + casePrice : e.allCasePrice - casePrice,
        };
      }),
    ]);

  const changeCasePrice = (caseId: number, price: number) => {
    expenseLocalList &&
      setExpenseLocalList([
        ...expenseLocalList.map((e) => {
          let oldAllLabworkPrice = 0;
          const newExpenseCases =
            e.expenseCases === null
              ? null
              : e.expenseCases.map((c) => {
                  if (c.caseId === caseId) oldAllLabworkPrice = c.allLabworkPrice;
                  return {
                    ...c,
                    allLabworkPrice: c.caseId === caseId ? price : c.allLabworkPrice,
                  };
                });
          return {
            ...e,
            expenseCases: newExpenseCases !== null ? newExpenseCases : e.expenseCases,
            allCasePrice: e.allCasePrice - oldAllLabworkPrice + price,
          };
        }),
      ]);
  };

  const onClickGenerateInvoice = (param: {
    companyId: number;
    caseIds: Map<number, number>;
    status: number;
  }) => {
    if (Number.isNaN(param.companyId)) {
      showAlert(translate(LANGUAGE_KEYS.ALERT_COMPANY_DATA_MISSING), AlertType.FAILD);
    } else {
      hideAlert();

      generateInvoice(param).then((res) => {
        if (res.statusCode === 200 && res.invoiedata !== null) {
          navigate(`/${props.companyId}/invoice/` + res.invoiedata.id, {
            state: { cases: props.caseIds, type: ReportsOverviewType.EXPENSE },
          });
          showAlert(
            `${translate(LANGUAGE_KEYS.ALERT_GENERATE_INVOICE_SUCCESS)} ${
              res.invoiedata.invoicePrefix
            }${res.invoiedata.invoiceNumber}`,
            AlertType.SUCCESS,
          );
        } else {
          showAlert(translate(LANGUAGE_KEYS.ALERT_SOMETHING_WENT_WRONG), AlertType.FAILD);
        }
      });
    }
  };

  const isSelectedCase = (expenseCases): boolean => {
    let show = false;
    expenseCases?.forEach((c) => {
      if (!show && c.isSelected) show = true;
    });
    return show;
  };

  return (
    <ContentBody>
      {expenseLocalList?.map((expense, index) => {
        return (
          <ExpenseRow
            key={index}
            companyId={props.companyId}
            expenseModel={expense}
            isSelectedCase={isSelectedCase(expense.expenseCases)}
            handleCheck={handleCheck}
            changePrice={changeCasePrice}
            generateInvoice={onClickGenerateInvoice}
          />
        );
      })}
    </ContentBody>
  );
}

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
`;

export default Expense;
