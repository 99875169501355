import { PasswordData } from '../../domain/authenticationFlow/AuthDataTypes';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

type setPasswordUseCasesRepository = Pick<AuthRepository, 'setPassword'>;

const setPasswordUseCases = (
  repo: setPasswordUseCasesRepository,
  password: PasswordData,
  isRecover: boolean,
) => {
  return repo.setPassword(password, isRecover);
};

export { setPasswordUseCases };
export type { setPasswordUseCasesRepository };
