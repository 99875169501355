import React from 'react';
import { useDispatch } from 'react-redux';
import { WorkTypeRepository } from '../../../domain/worktype/WorkTypeRepository';
import {
  getWorkTypesUseCase,
  getSortWorkTypesUseCase,
} from '../../../useCases/worktype/getWorkTypesUseCase';
import { updateWorktypesUseCase } from '../../../useCases/worktype/updateWorktypesUseCase';
import UpdateWorTypeInfo from '../../../domain/worktype/UpdateWorTypeInfo';
import { WorkType } from '../../../domain/worktype/WorkType';
import * as actionTypes from '../../../data/worktype/worktypeActionTypes';
import useTranslate from '../../translations/useTranslate';

function useWorktypesViewModel(repo: WorkTypeRepository) {
  const translate = useTranslate();

  const dispatch = useDispatch();

  const getWorktypes = React.useCallback(
    function () {
      getWorkTypesUseCase({
        getWorkTypes: repo.getWorkTypes,
        workTypes: repo.workTypes,
      });
    },
    [repo.getWorkTypes],
  );

  const getSortWorkTypes = React.useCallback(
    function () {
      return getSortWorkTypesUseCase({
        getWorkTypes: repo.getWorkTypes,
        workTypes: repo.workTypes,
      }).map((wokrtype) => ({ ...wokrtype, name: translate(wokrtype.name) }));
    },
    [repo.workTypes],
  );

  const getWorkTypeNameById = (id: number): string | null => {
    return translate(repo.workTypes?.find((worktype) => worktype.id === id)?.name);
  };

  const updateWorktypes = React.useCallback(
    function (worktypeList: WorkType[], isOrder: boolean) {
      if (isOrder) {
        dispatch({ type: actionTypes.GET_WORK_TYPES_SUCCESS, workTypes: worktypeList });
      }
      updateWorktypesUseCase(
        { updateWorkTypes: repo.updateWorkTypes, getWorkTypes: repo.getWorkTypes },
        chnageWortTypeToWorktypeUpdate(worktypeList),
      );
    },
    [repo.updateWorkTypes],
  );

  return {
    workTypes: repo.workTypes?.map((worktype) => ({ ...worktype, name: translate(worktype.name) })),
    isLoading: repo.isLoading,
    isUpdating: repo.isUpdating,
    getWorktypes,
    getSortWorkTypes,
    getWorkTypeNameById,
    updateWorktypes,
  };
}

function chnageWortTypeToWorktypeUpdate(workTypeList: WorkType[]) {
  return workTypeList.map((workType) => {
    return new UpdateWorTypeInfo(workType.id, workType.active, workType.order);
  });
}

export { useWorktypesViewModel };
