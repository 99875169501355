export default class Extension {
  id: number;
  name: string;
  price: number;
  active: boolean;
  isTeeth: boolean;
  teethIds: number[];

  constructor(
    id: number,
    name: string,
    price: number,
    active: boolean,
    isTeeth: boolean,
    teethIds: number[],
  ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.active = active;
    this.isTeeth = isTeeth;
    this.teethIds = teethIds;
  }
}
