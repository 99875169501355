import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { CaseDetailContext } from '../CaseDetailProvider';
import Select, { SelectedValue } from '../../components/dsm/Select';
// import DateAndTimeInput from '../../components/dsm/DateAndTimeInput';
import {
  changeClient,
  changePatientAge,
  changePatientGender,
  changePatientName,
} from '../../../data/cases/detail/caseDetailReducer';
import { useDispatch } from 'react-redux';
import { Gender, Genders } from '../../../domain/Gender';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { useAuth } from '../../../routeFiles/AuthContext';

const ClientAndPatientInfo = () => {
  const translate = useTranslate();
  const {
    caseDetailObj: caseDetail,
    clientsUi: clients,
    getCompaniesName: getCompaniesName,
    validSubscription,
  } = useContext(CaseDetailContext);
  const dispatch = useDispatch();

  const { userRole } = useAuth();

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  const getComapnyName = (id: number): string => {
    return getCompaniesName(id);
  };

  const clientOptions = useMemo(() => {
    const options: {
      id: number;
      label: string;
      clientCompanyId: number;
      clientCompanyName: string;
    }[] = [];
    clients?.map((client) => {
      client.companies.forEach((companyId) => {
        options.push({
          id: client.id,
          label: client.name,
          clientCompanyId: companyId,
          clientCompanyName: getComapnyName(companyId),
        });
      });
    });
    return options;
  }, [clients]);

  const genderOptions = Genders.map((gender) => ({ id: gender.id, label: translate(gender.name) }));

  const selectedGender = useMemo(() => {
    const gender = Genders.find(
      (gender) =>
        caseDetail !== null &&
        caseDetail.patientGender !== null &&
        gender.id === caseDetail.patientGender,
    );
    return { id: gender?.id, label: translate(gender?.name) || '' };
  }, [caseDetail]);

  const handleClientChange = (value: SelectedValue) => {
    if (validSubscription) {
      if (value.id !== null) {
        dispatch(
          changeClient({
            id: value.id || -1,
            name: value.label,
            clientCompanyId: value.clientCompanyId!,
          }),
        );
      }
    } else {
      showPaymentExpireModal(userRole);
    }
  };

  const handlePatientNameChange = (value: SelectedValue) =>
    dispatch(changePatientName(value.label));

  const handlePatientAgeChange = (value: SelectedValue) =>
    dispatch(changePatientAge(Number(value.label.replace(/\D/g, ''))));

  const handlePatientGenderChange = (value: SelectedValue) =>
    dispatch(changePatientGender(value.id as Gender['id']));

  return (
    <Container>
      <InputContainer>
        {caseDetail !== null && (
          <Select
            values={clientOptions || []}
            selected={{
              id: caseDetail.clientId,
              label:
                caseDetail.clientName !== null && caseDetail.clientName !== null
                  ? caseDetail.clientName + ' - ' + getComapnyName(caseDetail.clientCompanyId)
                  : '',
              clientCompanyId: caseDetail.clientCompanyId,
              clientCompanyName: caseDetail.clientCompanyName,
            }}
            placeHolder={translate(LANGUAGE_KEYS.ADD_CLIENT_NAME)}
            onChange={caseDetail.workSheetEditable ? handleClientChange : () => {}}
            resetOnClick={false}
            disabled={!caseDetail.workSheetEditable}
            validSubscription={validSubscription}
            userRole={userRole}
          />
        )}
      </InputContainer>
      <InputContainer>
        {caseDetail !== null && (
          <Select
            values={[]}
            selected={{ label: caseDetail.patientName || '' }}
            placeHolder={translate(LANGUAGE_KEYS.ADD_PATIENT_NAME)}
            onChange={caseDetail.workSheetEditable ? handlePatientNameChange : () => {}}
            resetOnClick={false}
            disabled={!caseDetail.workSheetEditable}
            validSubscription={validSubscription}
            userRole={userRole}
          />
        )}
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
          {caseDetail !== null && (
            <Select
              values={[]}
              selected={{ label: caseDetail.patientAge || '' }}
              placeHolder={translate(LANGUAGE_KEYS.FILL_AGE)}
              onChange={caseDetail.workSheetEditable ? handlePatientAgeChange : () => {}}
              resetOnClick={false}
              disabled={!caseDetail.workSheetEditable}
              validSubscription={validSubscription}
              userRole={userRole}
            />
          )}
          {caseDetail !== null && (
            <Select
              values={genderOptions}
              selected={selectedGender}
              placeHolder={translate(LANGUAGE_KEYS.GENDER)}
              onChange={caseDetail.workSheetEditable ? handlePatientGenderChange : () => {}}
              resetOnClick={false}
              textEditable={false}
              disabled={!caseDetail.workSheetEditable}
              size='Small'
              validSubscription={validSubscription}
              userRole={userRole}
            />
          )}
        </div>
      </InputContainer>
      {/*<InputContainer>*/}
      {/*  <DateAndTimeInput />*/}
      {/*</InputContainer>*/}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 60px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 16px;
  margin-bottom: 16px;
`;

const InputContainer = styled.div`
  flex: 1;
  padding: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  &:hover {
    border: 1px solid var(--dts_default_blue);
  }
`;

export default ClientAndPatientInfo;
