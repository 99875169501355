import { getFeatureByIdUseCase } from '../../../../useCases/features/getFeatureUseCase';
import { FeaturesRepository } from '../../../../domain/features/FeaturesRepository';
import { FeatureId } from '../../../../domain/features/Features';
import { AuthRepository } from '../../../../domain/authenticationFlow/AuthRepository';

const usePhaseStripeViewModel = (authRepo: AuthRepository, featureRepo: FeaturesRepository) => {
  const isEnabledAllPhaseStatusChange = (userRole: number): boolean => {
    return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.PHASE_STATUS_CHANGE_ACTION);
  };

  const isEnabledExtendLabworkPhase = (userRole: number): boolean => {
    return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.EXTEND_LABWORK_PHASES_ACTION);
  };

  return {
    userId: authRepo.userId,
    isEnabledAllPhaseStatusChange,
    isEnabledExtendLabworkPhase,
  };
};

export { usePhaseStripeViewModel };
