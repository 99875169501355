import React, { createContext, useContext } from 'react';
import { useState } from 'react';
import { ACTIVE, ALL, DRAFT } from './DashboardFilter';

interface DashboardContextType {
  menuSelection: typeof ACTIVE | typeof ALL | typeof DRAFT;
  updateMenuSelection: (selection: typeof ACTIVE | typeof ALL | typeof DRAFT) => void;
}

const DashboardContext = createContext<DashboardContextType | null>(null);

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [menuSelection, setMenuSelection] = useState(ALL);

  const updateMenuSelection = (selection: typeof ACTIVE | typeof ALL | typeof DRAFT) => {
    setMenuSelection(selection);
  };

  return (
    <DashboardContext.Provider value={{ menuSelection, updateMenuSelection }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (context === null) {
    throw new Error('useDashboardContext must be used within an DashboardProvider');
  }
  return context;
};
