import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '../components/dsm/Text';
import Row from '../components/layout/Row';
import { Button } from '../components/dsm/buttons/Button';
import {
  useCompaniesRepositoryImplementation,
  useContactsRepositoryImplementation,
} from '../../data/user-management/UsersRepositoryImplementation';
import { userManagementViewModel } from './UserManagementViewModel';
import { MyCompanyUiModel } from '../ui-model/user-management/CompanyUiModel';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import ToggleHeader from '../components/generic/ToggleHeader';
import DesktopHeader from '../components/generic/DesktopHeader';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ContentLayout from '../components/layout/ContentLayout';
import { DeviceSizeProps } from '../DeviceInformations';
import Avatar from '../components/generic/Avatar';
import { ReactComponent as PencilIcon } from '../../icons/pencil.svg';
import ColorPicker from '../components/dsm/ColorPicker';
import { CountySelector, CountySelectorProps } from '../components/generic/CountySelector';
import { useCountiesRepositoryImplementation } from '../../data/county/countiesRepositoryImplementation';
import { ReactComponent as DropdownIcon } from '../../icons/arrowDown.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { SimpleHint } from '../components/generic/SimpleHint';
import { useAuthRepositoryImplementation } from '../../data/authenticationFlow/authRepositoryImplementation';

function MyCompanyData() {
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);
  const translate = useTranslate();
  const contactRepo = useContactsRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const authRepo = useAuthRepositoryImplementation();
  const countiesRepo = useCountiesRepositoryImplementation();
  const { getMyCompanyData, updateMyCompanyData, getCountiesWithId } = userManagementViewModel(
    contactRepo,
    companiesRepo,
    authRepo,
    countiesRepo,
  );
  const {
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
    isTabletSmall,
    isTabletLarge,
    isMobile,
  } = useDeviceParameters();

  const countySelectRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [companyData, setcompanyData] = useState<MyCompanyUiModel | null>();
  const [name, setName] = useState<string>('');
  const [invoicePrefix, setInvoicePrefix] = useState<string>('');
  const [streetName, setStreetName] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const [countrySubentity, setCountrySubentity] = useState<{
    id: number;
    auto: string;
    nume: string;
  }>();
  const [workPlaceAddress, setWorkPlaceAddress] = useState<string>('');
  const [capitalSocial, setCapitalSocial] = useState<number | ''>('');
  const [cui, setCui] = useState<number | ''>('');
  const [nrRegCom, setNrRegCom] = useState<string>('');
  const [vat, setVat] = useState<string>('');
  const [bank, setBank] = useState<string>('');
  const [iban, setIban] = useState<string>('');
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);
  const [laborColor, setLaborColor] = useState<string>('#18a0fb');
  const [changedDataNumber, setChangedDataNumber] = useState<number>(0);

  useEffect(() => {
    getMyCompanyData().then((company) => {
      if (company !== null) {
        setcompanyData(company);
        setDefaultCompanyData(company);
      }
    });
  }, [companiesRepo.getMyCompanyData]);

  const setDefaultCompanyData = (data) => {
    if (data !== null && data !== null) {
      setName(data.name);
      setInvoicePrefix(data.invoicePrefix);
      setStreetName(data.streetName);
      setCityName(data.cityName);
      const countrySubentityTmp = getCountiesWithId().find(
        (item) => item.auto === data.countrySubentity,
      );
      setCountrySubentity(
        countrySubentityTmp === null ? getCountiesWithId()[0] : countrySubentityTmp,
      );
      setWorkPlaceAddress(data.workPlaceAddress);
      setCapitalSocial(
        data.capitalSocial === null || data.capitalSocial === null ? '' : data.capitalSocial,
      );
      setCui(data.cui);
      setNrRegCom(data.nrRegCom);
      setVat(data.vat);
      setBank(data.bank);
      setIban(data.codIban);
      setLaborColor(
        data.laborColor === null || data.laborColor === null
          ? 'var(--dts_default_blue)'
          : data.laborColor,
      );
    }
  };

  const setChangedData = (isDifferentValue: boolean) => {
    if (isDifferentValue) {
      setChangedDataNumber(changedDataNumber + 1);
    } else {
      setChangedDataNumber(changedDataNumber - 1);
    }
  };

  const handleChangeName = (value) => {
    const nameValue = value.target.value;
    setChangedData(nameValue !== companyData?.name);
    setName(nameValue);
  };
  const handleChangeIdentificationCode = (value) => {
    const identificationCodeValue = value.target.value;
    setChangedData(identificationCodeValue !== companyData?.invoicePrefix);
    setInvoicePrefix(identificationCodeValue);
  };

  const handleChangeStreetName = (value) => {
    const streetNameValue = value.target.value;
    setChangedData(streetNameValue !== companyData?.streetName);
    setStreetName(streetNameValue);
  };

  const handleChangeCityName = (value) => {
    const cityNameValue = value.target.value;
    setChangedData(cityNameValue !== companyData?.cityName);
    setCityName(cityNameValue);
  };

  const handleChangeCountrySubentity = (value) => {
    const countrySubentityValue = getCountiesWithId().find((item) => item.id === value);
    setChangedData(countrySubentityValue?.auto !== companyData?.countrySubentity);
    setCountrySubentity(countrySubentityValue);
  };

  const handleChangeWorkPlaceAddress = (value) => {
    const workPlaceAddressValue = value.target.value;
    setChangedData(workPlaceAddressValue !== companyData?.workPlaceAddress);
    setWorkPlaceAddress(workPlaceAddressValue);
  };

  const handleChangeCapitalSocial = (value) => {
    const capitalSocialValue = value.target.value;
    if (capitalSocialValue === '') {
      setCapitalSocial('');
      setChangedData(capitalSocialValue !== companyData?.capitalSocial);
    }
    if (value.target.validity.valid) {
      setChangedData(Number(capitalSocialValue) !== companyData?.capitalSocial);
      setCapitalSocial(capitalSocialValue);
    }
  };

  const handleChangeCUI = (value) => {
    const cuiValue = value.target.value;
    if (cuiValue === '') {
      setChangedData(cuiValue !== companyData?.cui);
      setCui('');
    }
    setChangedData(cuiValue !== companyData?.cui);
    setCui(cuiValue);
  };

  const handleChangeNrRegCom = (value) => {
    const nrRegComValue = value.target.value;
    setChangedData(nrRegComValue !== companyData?.nrRegCom);
    setNrRegCom(nrRegComValue);
  };

  const handleChangeVat = (value) => {
    const vatValue = value.target.value;
    setChangedData(vatValue !== companyData?.vat);
    setVat(vatValue);
  };

  const handleChangeBank = (value) => {
    const bankValue = value.target.value;
    setChangedData(bankValue !== companyData?.bank);
    setBank(bankValue);
  };

  const handleChangeIban = (value) => {
    const ibanValue = value.target.value;
    setChangedData(ibanValue !== companyData?.codIban);
    setIban(ibanValue);
  };

  const handleChangeProfileColor = (color) => {
    setChangedData(color !== companyData?.laborColor);
    setLaborColor(color);
  };

  const handleSaveData = () => {
    updateMyCompanyData({
      id: companyData?.id,
      streetName: streetName,
      cityName: cityName,
      countrySubentity: countrySubentity?.auto,
      identificationCode: 'RO',
      name: name,
      workPlaceAddress: workPlaceAddress,
      capitalSocial: Number(capitalSocial),
      cui: cui,
      nrRegCom: nrRegCom,
      vat: vat,
      bank: bank,
      codIban: iban,
      color: laborColor,
      invoicePrefix: invoicePrefix,
    }).then((resp) => {
      if (resp) {
        getMyCompanyData().then((company) => {
          if (company !== null) {
            setcompanyData(company);
            setDefaultCompanyData(company);
            setChangedDataNumber(0);
            // DIALOG OK
            showAlert(
              translate(LANGUAGE_KEYS.ALERT_UPDATE_COMPANY_DATA_SUCCESS),
              AlertType.SUCCESS,
            );
          } else {
            // DIALOG NOT OK
            showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
          }
        });
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
      }
    });
  };

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.COMPANY_DATA)}
          enabledClick={undefined}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.COMPANY_DATA)}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <ContentBody ref={modalRef} className={`${changedDataNumber > 0 && 'plus-margin'}`}>
          <Section>
            <SectionBody
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
            >
              <AvatarConatiner
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
              >
                <Avatar
                  textType={TextType.ROBOTO_400_30_34}
                  fullName={name}
                  size={{ outer: 80, inner: 78 }}
                  avatarStyle={{ background: laborColor }}
                />
                <PencilContainer
                  style={{ background: laborColor }}
                  onClick={() => {
                    setIsColorPickerOpen(true);
                  }}
                >
                  <PencilIcon />
                </PencilContainer>
                {isColorPickerOpen && (
                  <ColorPicker
                    selectColor={laborColor}
                    onSelectColor={(color: string) => {
                      handleChangeProfileColor(color);
                    }}
                    onCancel={() => setIsColorPickerOpen(false)}
                  />
                )}
              </AvatarConatiner>
              <InputsContent>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.COMPANY_NAME)}
                  </LabelText>
                  <InputComponent
                    className='name'
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={name}
                    onChange={(it) => {
                      handleChangeName(it);
                    }}
                    required
                  />
                  <HintContainer>
                    <InputComponent
                      className='identification-code'
                      mobile_small={mobileSmallSize}
                      mobile={mobileSize}
                      tablet_small={tabletSmallSize}
                      tablet_large={tabletLargeSize}
                      desktop={desktopSize}
                      type={'text'}
                      value={invoicePrefix}
                      onChange={(it) => {
                        handleChangeIdentificationCode(it);
                      }}
                      required
                    />
                    <SimpleHint
                      icon={<CustomInfoIcon width={16} height={16} />}
                      hintText={translate(LANGUAGE_KEYS.IDENTIFICATION_CODE_HINT_TEXT)}
                    />
                  </HintContainer>
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.CITY)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={cityName}
                    onChange={(it) => {
                      handleChangeCityName(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.ADDRESS)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={streetName}
                    onChange={(it) => {
                      handleChangeStreetName(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer className='county'>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.COUNTY)}
                  </LabelText>
                  <Selected ref={countySelectRef} onClick={() => setIsOpen(!isOpen)}>
                    <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
                      {countrySubentity?.nume}
                    </Text>
                    <DropdownIconStyled className={`${isOpen && 'open'}`} />
                  </Selected>
                  <CountySelector
                    parentRef={countySelectRef}
                    modalRef={modalRef}
                    isOpen={isOpen}
                    values={getCountiesWithId().reduce(
                      (result: CountySelectorProps['values'], county) => {
                        result.push({
                          key: county.id,
                          label: county.nume,
                          selected: county.auto === countrySubentity?.auto,
                        });
                        return result;
                      },
                      [],
                    )}
                    onSelect={(changedValueKey) => {
                      handleChangeCountrySubentity(changedValueKey);
                    }}
                    hasSuccessIcon={true}
                    onChangeOpenState={(state: boolean) => setIsOpen(state)}
                    maxHeightOptions={175}
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.PUNCT_DE_LUCRU)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={workPlaceAddress}
                    onChange={(it) => {
                      handleChangeWorkPlaceAddress(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.CIF)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={cui}
                    onChange={(it) => {
                      handleChangeCUI(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.NR_REG_COM)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={nrRegCom}
                    onChange={(it) => {
                      handleChangeNrRegCom(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.BANCA)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={bank}
                    onChange={(it) => {
                      handleChangeBank(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.COD_IBAN)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={iban}
                    onChange={(it) => {
                      handleChangeIban(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.CAPITAL_SOCIAL)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={capitalSocial}
                    pattern={'[0-9]*'}
                    onChange={(it) => {
                      handleChangeCapitalSocial(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.VAT)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={vat}
                    onChange={(it) => {
                      handleChangeVat(it);
                    }}
                    required
                  />
                </LabelInputContainer>
              </InputsContent>
            </SectionBody>
          </Section>
        </ContentBody>
        {changedDataNumber > 0 && (
          <BottomContainer>
            <Button
              style={{ width: 'auto' }}
              textType={TextType.CAPTION_BOLD}
              secondary={'secondary'}
              onClick={() => {
                setChangedDataNumber(0);
                setDefaultCompanyData(companyData);
              }}
            >
              {translate(LANGUAGE_KEYS.CANCEL)}
            </Button>
            <Button
              style={{ width: 'auto' }}
              onClick={() => {
                handleSaveData();
              }}
            >
              {translate(LANGUAGE_KEYS.SAVE)}
            </Button>
          </BottomContainer>
        )}
      </ContentLayout>
    </>
  );
}

const ContentBody = styled.div`
  background: var(--dts_white);
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  .&plus-margin {
    margin-bottom: 60px;
  }
`;

const InputsContent = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
`;

const Section = styled.div``;

const AvatarConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: relative;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin: auto;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const PencilContainer = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  padding: 6px 7px;
  border: 2px solid var(--dts_white);
  line-height: 1;
  cursor: pointer;
`;

const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 8px;
  width: 100%;

  &.visible {
    opacity: 1;
    transition: all 0.2s;
    visibility: visible;
  }
  &.hidden {
    display: none;
    opacity: 0;
    transition: all 0.2s;
    visibility: hidden;
  }

  &.county {
    .county-container {
      max-width: 344px;
      min-width: 344px;
    }
  }
`;

const HintContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputComponent = styled.input<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    padding: 8px;
    border: 1px solid var(--dts_light_grey);
    border-radius: 3px;
    color: var(--dts_dark);
    box-shadow: none;
    outline: none;
    max-width: 240px;
    min-width: 240px;

    &.name {
      max-width: 178px;
      min-width: 178px;
    }

    &.identification-code {
      max-width: 18px;
      min-width: 18px;
      margin-right: 2px;
    }

    ::placeholder {
      color: var(--dts_dark);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      max-width: calc(100% - 168px);
      min-width: calc(100% - 138px);
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomInfoIcon = styled(InfoIcon)`
  path {
    fill: var(--dts_dark_grey);
  }
`;

const SectionBody = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    margin-top: 16px;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
        flex-direction: column;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const LabelText = styled(Text)`
  max-width: 112px;
  min-width: 112px;
`;

const BottomContainer = styled(Row)`
  position: fixed;
  width: 100%;
  height: 85px;
  right: 0;
  bottom: 0;
  padding: 0 24px;
  background-color: var(--dts_white);
  justify-content: end;
  box-shadow: -1px 2px 11px 0 rgba(0, 0, 0, 0.14);
  gap: 16px;
`;

const Selected = styled.div`
  position: relative;
  background-color: var(--dts_white);
  padding: 8.5px 8px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  max-width: 240px;
  min-width: 240px;
  &.disabled {
    cursor: not-allowed;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const DropdownIconStyled = styled(DropdownIcon)`
  fill: var(--dts_dark);
  flex-shrink: 0;
  &.open {
    transform: rotate(180deg);
  }
`;

export default MyCompanyData;
