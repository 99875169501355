import React from 'react';
import { updateUserUseCases } from '../../useCases/authenticationFlow/updateUserUseCase';
import { resetPasswordUseCases } from '../../useCases/authenticationFlow/resetPasswordUseCase';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';
import { UpdateUserData } from '../../domain/authenticationFlow/AuthDataTypes';

function useMyProfileViewModel(authRepo: AuthRepository) {
  const updateUser = React.useCallback(
    function (data: UpdateUserData) {
      return updateUserUseCases({ updateUser: authRepo.updateUser }, data);
    },
    [authRepo.updateUser],
  );

  const resetPassword = React.useCallback(
    function (username: string) {
      return resetPasswordUseCases({ resetPassword: authRepo.resetPassword }, username);
    },
    [authRepo.resetPassword],
  );

  return {
    updateUser,
    resetPassword,
  };
}

export { useMyProfileViewModel };
