import React, { FC } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../../components/dsm/Text';
import { ExpenseCaseCard } from './ExpenseCaseCard';
import { ExpenseUiModel } from '../../../ui-model/reports/ExpenseUiModel';
import { ACTIVE } from '../../../case/caseStates';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';
import useTranslate from '../../../translations/useTranslate';

export type ExpenseRowProps = {
  companyId: number | null;
  expenseModel: ExpenseUiModel;
  isSelectedCase: boolean;
  handleCheck: (caseId: number, active: boolean) => void;
  changePrice: (caseId: number, price: number) => void;
  generateInvoice: (param: {
    companyId: number;
    caseIds: Map<number, number>;
    status: number;
  }) => void;
};

export const ExpenseRow: FC<ExpenseRowProps> = (expenseRowProps) => {
  const translate = useTranslate();
  const { companyId, expenseModel, isSelectedCase, handleCheck, changePrice, generateInvoice } =
    expenseRowProps;

  const checkGenerateInvoiceButtonActivity = () => {
    const checkCasesStatus = expenseModel.expenseCases?.some(
      (expenseCase) => expenseCase.caseStatus === ACTIVE,
    );
    return checkCasesStatus;
  };

  const handleOnCheckboxClick = (caseId: number, active: boolean) => {
    handleCheck(caseId, active);
  };

  const onChangePrice = (caseId: number, price: number) => {
    changePrice(caseId, price);
  };

  const onClickGenerateInvoice = () => {
    const selectedCaseWithPrice = new Map<number, number>();
    expenseModel.expenseCases?.forEach((c) => {
      selectedCaseWithPrice.set(c.caseId, c.allLabworkPrice);
    });

    generateInvoice({
      companyId: expenseModel.clientCompanyId,
      caseIds: selectedCaseWithPrice,
      status: 0,
    });
  };

  return (
    <RowBody>
      <TitleConatiner>
        <Text type={TextType.TITLE_BOLD_2}>{expenseModel.companyName}</Text>
      </TitleConatiner>
      {expenseModel.expenseCases?.map((expenseCase, index) => {
        return (
          <ExpenseCaseCard
            key={index}
            companyId={companyId}
            expenseCase={expenseCase}
            expensesLength={
              expenseModel.expenseCases === null || expenseModel.expenseCases.length === null
                ? 0
                : expenseModel.expenseCases.length
            }
            handleCheck={handleOnCheckboxClick}
            changePrice={onChangePrice}
          />
        );
      })}
      <SummaryConatiner>
        <PriceContainer>
          <Text type={TextType.TITLE_BOLD_2}>
            {translate(LANGUAGE_KEYS.TOTAL_AMOUNT)}: {expenseModel.allCasePrice.toFixed(2)}
          </Text>
        </PriceContainer>
        <CustomButtonStyle
          className={`${
            checkGenerateInvoiceButtonActivity() && isSelectedCase ? 'active' : 'disable'
          }`}
          onClick={() => {
            if (checkGenerateInvoiceButtonActivity() && isSelectedCase) {
              onClickGenerateInvoice();
            }
          }}
        >
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.GENERATE_INVOICE)}</Text>
        </CustomButtonStyle>
      </SummaryConatiner>
    </RowBody>
  );
};

const RowBody = styled.div`
  padding: 24px;
  background: var(--dts_white);
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleConatiner = styled.div`
  width: 100%;
`;

const SummaryConatiner = styled.div`
  padding: 24px;
  background: var(--dts_withe_background);
  display: flex;
  justify-content: space-between;
`;

const PriceContainer = styled.div``;

const CustomButtonStyle = styled.button`
  padding: 9px 15px 9px 15px;
  border-radius: 3px;

  &.active {
    border: 1px solid var(--dts_primary);
    background: var(--dts_primary);
    cursor: pointer;
    p {
      color: var(--dts_white);
    }
    &:hover {
      border: 1px solid var(--dts_hover_blue);
      background: var(--dts_hover_blue);
    }
    &:active {
      border: 1px solid var(--dts_pressed_blue);
      background: var(--dts_pressed_blue);
    }
  }

  &.disable {
    border: 1px solid var(--dts_dark_grey);
    background: var(--dts_white);
    pointer-events: none;
    p {
      color: var(--dts_black);
    }
  }
`;
