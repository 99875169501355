import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

type logoutUserUseCasesRepository = Pick<AuthRepository, 'logoutUser'>;

const logoutUserUseCases = (repo: logoutUserUseCasesRepository) => {
  return repo.logoutUser();
};

export { logoutUserUseCases };
export type { logoutUserUseCasesRepository };
