import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { CountiesRepository } from '../../domain/county/CountyRepository';
import { CountiesRepositoryState } from './countyReducer';
import { getCountiesAction } from './countyActions';
import { RootState } from '../utils/store';

const countiesSelector = (state: RootState) => state.counties;

const useCountiesRepositoryImplementation = (): CountiesRepository => {
  const { counties } = useSelector<RootState, CountiesRepositoryState>(countiesSelector);

  const dispatch = useDispatch();

  const getCounties = React.useCallback(() => getCountiesAction()(dispatch), [dispatch]);

  return {
    counties,
    getCounties,
  };
};

export { useCountiesRepositoryImplementation };
