import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LeftIcon } from '../../../icons/leftIcon.svg';
import { ReactComponent as RightIcon } from '../../../icons/rightIcon.svg';
import { ReactComponent as ClockIcon } from '../../../icons/clock.svg';
import { Button } from './buttons/Button';
import { Text, TextType } from './Text';
import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';

type DateTimePickerProps = {
  date?: Date;
  onCancel: () => void;
  onSave: (date: Date) => void;
};

const DateTimePicker = (props: DateTimePickerProps) => {
  const { date = new Date(), onCancel, onSave } = props;

  const translate = useTranslate();

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const [selectedDate, setSelectedDate] = useState<Date>(date);

  const [hours, setHours] = useState<string | number>(getFormattedHours(date));
  const [minutes, setMinutes] = useState<string | number>(getFormattedMinutes(date));

  const handleSelect = (day: number) => {
    const mDate = new Date(selectedDate);
    mDate.setDate(day);
    setSelectedDate(mDate);
  };

  const handleHour = (e) => {
    const hoursTmp = e.target.value;
    const hoursPattern1 = /^[0-9]$/;
    const hoursPattern2 = /^[0-1][0-9]|2[0-3]$/;
    if (hoursPattern1.test(hoursTmp) || hoursPattern2.test(hoursTmp)) {
      setHours(hoursTmp);
    } else {
      setHours('');
    }
  };

  const handleMinutes = (e) => {
    const minutesTmp = e.target.value;
    const minutesPattern1 = /^[0-9]$/;
    const minutesPattern2 = /^[0-5][0-9]$/;
    if (minutesPattern1.test(minutesTmp) || minutesPattern2.test(minutesTmp)) {
      setMinutes(minutesTmp);
    } else {
      setMinutes('');
    }
  };

  const handleSave = () => {
    const newDate = new Date(selectedDate);
    if (hours !== '') {
      newDate.setHours(Number(hours));
    }
    if (minutes !== '') {
      newDate.setMinutes(Number(minutes));
    }
    onSave(newDate);
  };

  const jumpToPreviousMonth = () => {
    const mDate = new Date(selectedDate);
    mDate.setMonth(selectedDate.getMonth() - 1);
    if (selectedDate.getMonth() === mDate.getMonth()) {
      mDate.setMonth(mDate.getMonth());
      mDate.setDate(0);
    }
    setSelectedDate(mDate);
  };

  const jumpToNextMonth = () => {
    const mDate = new Date(selectedDate);
    mDate.setMonth(selectedDate.getMonth() + 1);
    if (selectedDate.getMonth() + 2 === mDate.getMonth()) {
      mDate.setMonth(mDate.getMonth());
      mDate.setDate(0);
    }
    setSelectedDate(mDate);
  };

  const getMondayIndex = (date: Date) => {
    const mDate = new Date(date);
    mDate.setDate(1);
    return mDate.getDay();
  };

  const getPreviousMonthDayCount = (date: Date) => {
    const mDate = new Date(date);
    mDate.setMonth(date.getMonth());
    mDate.setDate(0);
    return mDate.getDate();
  };

  const getCurrentMonthDayCount = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  const getDayList = useMemo(() => {
    const days: Array<{ day: number; selectable: boolean; active?: boolean }> = [];

    const mondayIndex = getMondayIndex(selectedDate);
    const previousMonthDayCount = getPreviousMonthDayCount(selectedDate);
    const currentMonthDayCount = getCurrentMonthDayCount(selectedDate);

    for (let i = 1; i <= mondayIndex; i++)
      days.push({ day: previousMonthDayCount - mondayIndex + i, selectable: false });
    for (let i = 1; i <= currentMonthDayCount; i++)
      days.push({ day: i, selectable: true, active: i === selectedDate.getDate() });
    for (let i = 1; i <= days.length % 7; i++) {
      days.push({ day: i, selectable: false });
    }

    return days;
  }, [selectedDate]);

  const label = useMemo(
    () => `${translate(mapMonth(selectedDate.getMonth()))} ${selectedDate.getFullYear()}`,
    [selectedDate],
  );

  return (
    <DateTimePickerContainer>
      <DateTimePickerHeader>
        <PickerHeader>
          <div onClick={jumpToPreviousMonth}>
            <LeftIcon />
          </div>
          <Text type={TextType.BODY_BOLD} style={{ padding: '0 24px' }}>
            {label}
          </Text>
          <div onClick={jumpToNextMonth}>
            <RightIcon />
          </div>
        </PickerHeader>
      </DateTimePickerHeader>
      <CalendarHeader>
        <label>Sun</label>
        <label>Mon</label>
        <label>Tue</label>
        <label>Wed</label>
        <label>Thu</label>
        <label>Fri</label>
        <label>Sat</label>
      </CalendarHeader>
      <Calendar>
        {getDayList.map((day) => {
          return (
            <div
              className={`${day.selectable && 'selectable'} ${day.active && 'active'}`}
              key={day.day + Math.random()}
              onClick={() => handleSelect(day.day)}
            >
              {day.day}
            </div>
          );
        })}
      </Calendar>
      <TimeInputContainer onClick={() => {}}>
        <ClockIcon width={27} height={31.5} />
        <TimeInputs>
          <InputText
            placeholder='00'
            value={`${hours}`}
            onChange={(e) => {
              handleHour(e);
            }}
          />
          <Text type={TextType.BODY_REGULAR}>:</Text>
          <InputText
            placeholder='00'
            value={`${minutes}`}
            onChange={(e) => {
              handleMinutes(e);
            }}
          />
        </TimeInputs>
      </TimeInputContainer>
      <DateTimePickerFooter
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <div>
          <Button onClick={onCancel} secondary={'secondary'} textType={TextType.CAPTION_BOLD}>
            Cancel
          </Button>
          <Button onClick={() => handleSave()} textType={TextType.CAPTION_BOLD}>
            {translate(LANGUAGE_KEYS.SELECT)}
          </Button>
        </div>
      </DateTimePickerFooter>
    </DateTimePickerContainer>
  );
};

const DateTimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  user-select: none;
`;

const DateTimePickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

const PickerHeader = styled.div`
  display: flex;
  alignitems: center;
  width: 100%;
  justify-content: space-between;
`;

const CalendarHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  label {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: var(--dts_dark);
    opacity: 0.3;
  }
`;

const Calendar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    color: var(--dts_dark);
    width: 60px;
    height: 60px;
    opacity: 0.3;

    &.selectable {
      opacity: 1;
      cursor: pointer;
    }

    &.active {
      background-color: rgba(24, 160, 251, 0.2);
      border-radius: 100%;
      color: var(--dts_default_blue);
      font-weight: 700;
    }
    width: 40px;
    height: 40px;
  }
`;
const TimeInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 24px;
  padding: 15px;
  box-sizing: border-box;
  background-color: var(--dts_withe_background);
  justify-content: center;
`;

const TimeInputs = styled.div`
  display: flex;
  gap: 8px;
`;

const InputText = styled.input`
  width: 32px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  padding: 7px;
  border: 1px solid var(--dts_dark_grey);
  border-radius: 3px;
  box-shadow: none;
  outline: none;
`;

export const DateTimePickerFooter = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
        display: flex;
        flex-direction: row;
        gap: 16px;
        padding: 16px;
        background: var(--dts_white);
        width: calc(100% - 32px);
        justify-content: flex-end;
        //Desktop
        @media (min-width: ${desktop}px) {
        }
        // Tablet large
        @media (max-width: ${tablet_large}px) {
        }
        // Tablet small
        @media (max-width: ${tablet_small}px) {
        }
        // Mobile
        @media (max-width: ${mobile}px) {
          position: fixed;
          bottom: 0;
          justify-content: center;
          flex-direction: column;
        }
        // Mobile small
        @media (max-width: ${mobile_small}px) {
        }
    }
`,
);

export const mapMonth = (month, cutAt?: number) => {
  const monthNames = [
    LANGUAGE_KEYS.JANUARY,
    LANGUAGE_KEYS.FEBRUARY,
    LANGUAGE_KEYS.MARCH,
    LANGUAGE_KEYS.APRIL,
    LANGUAGE_KEYS.MAY,
    LANGUAGE_KEYS.JUNE,
    LANGUAGE_KEYS.JULY,
    LANGUAGE_KEYS.AUGUST,
    LANGUAGE_KEYS.SEPTEMBER,
    LANGUAGE_KEYS.OCTOBER,
    LANGUAGE_KEYS.NOVEMBER,
    LANGUAGE_KEYS.DECEMBER,
  ];
  return cutAt ? monthNames[month].substring(0, cutAt) : monthNames[month] || '';
};

export const mapMonthNumber = (month) => `${month + 1 < 10 ? '0' : ''}${month + 1}`;

export const mapDay = (day) => `${day < 10 ? '0' : ''}${day}`;

export const getFormattedHours = (date: Date) => {
  const hours = date.getHours();
  const formattedHours = (hours % 23 || 23) < 10 ? `0${hours % 23 || 23}` : hours % 23 || 23;
  if (formattedHours !== null) {
    return formattedHours;
  }
  return '';
};

export const getFormattedMinutes = (date: Date) => {
  const minutes = date.getMinutes();
  return minutes < 10 ? `0${minutes}` : minutes;
};

export default DateTimePicker;
