import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';
import * as actionTypes from './authActionTypes';

type AuthRepositoryState = Omit<
  AuthRepository,
  | 'handleLogin'
  | 'updateUser'
  | 'logoutUser'
  | 'startSetLaboratory'
  | 'setLaboratory'
  | 'addUser'
  | 'setPassword'
  | 'resetPassword'
>;

const tokenFromStorage = localStorage.getItem('token');
const userIdFromStorage = localStorage.getItem('userId');
const emailFromStorage = localStorage.getItem('email');
const languageFromStorage = localStorage.getItem('language');
const firstNameFromStorage = localStorage.getItem('firstName');
const lastNameFromStorage = localStorage.getItem('lastName');
const nameFromStorage = localStorage.getItem('name');
const phoneNumberFromStorage = localStorage.getItem('phoneNumber');
const profileColorFromStorage = localStorage.getItem('profileColor');
const isFtiFinishedFromStorage = localStorage.getItem('isFtiFinished');

const initialState: AuthRepositoryState = {
  token: tokenFromStorage && tokenFromStorage !== 'null' ? tokenFromStorage : null,
  userId: userIdFromStorage && userIdFromStorage !== 'null' ? Number(userIdFromStorage) : null,
  email: emailFromStorage && emailFromStorage !== 'null' ? emailFromStorage : null,
  language: languageFromStorage && languageFromStorage !== 'null' ? languageFromStorage : null,
  firstName: firstNameFromStorage && firstNameFromStorage !== 'null' ? firstNameFromStorage : null,
  lastName: lastNameFromStorage && lastNameFromStorage !== 'null' ? lastNameFromStorage : null,
  name: nameFromStorage && nameFromStorage !== 'null' ? nameFromStorage : null,
  phoneNumber:
    phoneNumberFromStorage && phoneNumberFromStorage !== 'null' ? phoneNumberFromStorage : null,
  profileColor:
    profileColorFromStorage && profileColorFromStorage !== 'null' ? profileColorFromStorage : null,
  isFtiFinished:
    isFtiFinishedFromStorage && isFtiFinishedFromStorage !== 'null'
      ? Boolean(isFtiFinishedFromStorage)
      : null,

  isLoadingCompanyData: true,
  companyId: null,
  companyName: null,
  color: null,
  address: null,
  userRole: null,
  validSubscription: null,
  packageType: null,
  billingType: null,
  subscriptionEndTime: null,
};

const authReducer = (state = initialState, action: any): AuthRepositoryState => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.toke,
        userId: action.payload.userId,
        email: action.payload.email,
        language: action.payload.language,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        name: action.payload.firstName + ' ' + action.payload.lastName,
        phoneNumber: action.payload.phoneNumber,
        profileColor: action.payload.profileColor,
        isFtiFinished: action.payload.isFtiFinished,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        userId: null,
        email: null,
        language: null,
        firstName: null,
        lastName: null,
        name: null,
        phoneNumber: null,
        profileColor: null,
        isFtiFinished: null,
        companyId: null,
        validSubscription: null,
        packageType: null,
        billingType: null,
        subscriptionEndTime: null,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        userId: action.payload.id,
        email: action.payload.email,
        language: action.payload.language,
        firstName: action.payload.firstname,
        lastName: action.payload.lastname,
        name: action.payload.firstname + ' ' + action.payload.lastname,
        phoneNumber: action.payload.tellNumber,
        profileColor: action.payload.profileColor,
      };
    case actionTypes.SET_LABORATORY_START:
      return { ...state, isLoadingCompanyData: true };
    case actionTypes.SET_LABORATORY_SUCCESS:
      if (action.payload.companyData === null) {
        return {
          ...state,
          isLoadingCompanyData: false,
          companyId: null,
          companyName: null,
          color: null,
          address: null,
          userRole: null,
        };
      }
      return {
        ...state,
        isLoadingCompanyData: false,
        companyId: action.payload.companyData.companyId,
        companyName: action.payload.companyData.companyName,
        color: action.payload.companyData.color,
        address: action.payload.companyData.address,
        userRole: action.payload.companyData.userRole,
      };
    case actionTypes.SET_SUBSCRIPTION:
      return {
        ...state,
        validSubscription: action.payload.subscriptionData.validSubscription,
        packageType:
          action.payload.subscriptionData.packageType !== -1
            ? action.payload.subscriptionData.packageType
            : null,
        billingType:
          action.payload.subscriptionData.billingType !== -1
            ? action.payload.subscriptionData.billingType
            : null,
        subscriptionEndTime:
          action.payload.subscriptionData.subscriptionEndTime !== 1
            ? action.payload.subscriptionData.subscriptionEndTime
            : null,
      };
    default:
      return state;
  }
};

export default authReducer;
export type { AuthRepositoryState };
