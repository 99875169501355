import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './presentation/App';
import { Provider } from 'react-redux';
import store from './data/utils/store';

ReactDOM.render(
  <React.StrictMode>
    {/* Redux store-t körülölelő Provider */}
    <Provider store={store}>
      {/* App komponens, amely tartalmazza a Router-t és az egész alkalmazást */}
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'), // Az App az 'index.html' root eleméhez csatlakozik
);
