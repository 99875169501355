import {
  getContacts,
  updateClient,
  updateWorker,
  answerInvitation,
  sendWorkerInvitation,
  deleteContact,
  getCompanies,
  getMyCompanyData,
  newCompany,
  updateCompany,
  deleteCompany,
  createCompany,
} from './usersService';
import * as actionTypes from './usersActionTypes';
import {
  UpdateClient,
  SendWorkerInvitation,
  UpdateWorker,
  AnswerWorkerInvitation,
} from '../../domain/user-management/Contact';
import { NewCompany } from '../../domain/user-management/NewCompany';
import { checkErrorStatus } from '../ErrorHandling';
import { Plan } from '../../domain/user-management/Plans';

const getContactAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_CONTACT });
  return getContacts().then((contact) => {
    if (checkErrorStatus(contact.code, dispatch)) {
      dispatch({ type: actionTypes.GET_CONTACT_SUCCESS, contacts: contact.data });
      return contact.data;
    } else {
      dispatch({ type: actionTypes.GET_CONTACT_SUCCESS, error: contact.code });
      return contact.data;
    }
  });
};

const updateClientAction = (newContactItem: UpdateClient) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_CLIENT });
  return updateClient(newContactItem).then((resp) => {
    getContacts()
      .then((contact) => {
        if (checkErrorStatus(contact.code, dispatch)) {
          getCompanies().then((company) => {
            if (checkErrorStatus(company.code, dispatch)) {
              dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, companies: company.data });
              return company.data;
            } else {
              dispatch({ type: actionTypes.GET_COMPANIES_FAILD, error: company.code });
              return company.data;
            }
          });
          dispatch({ type: actionTypes.GET_CONTACT_SUCCESS, contacts: contact.data });
          return contact.data;
        } else {
          dispatch({ type: actionTypes.GET_CONTACT_FAILD, error: contact.code });
          return contact.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp.code, dispatch)) {
          dispatch({ type: actionTypes.UPDATE_CLIENT_SUCCESS });
        } else {
          dispatch({ type: actionTypes.UPDATE_CLIENT_FAILD, error: resp.code });
        }
      });
  });
};

const updateWorkerAction = (worker: UpdateWorker) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_WORKER });
  return updateWorker(worker).then((resp) => {
    getContacts()
      .then((contact) => {
        if (checkErrorStatus(contact.code, dispatch)) {
          dispatch({ type: actionTypes.GET_CONTACT_SUCCESS, contacts: contact.data });
          return contact.data;
        } else {
          dispatch({ type: actionTypes.GET_CONTACT_FAILD, error: contact.code });
          return contact.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp.code, dispatch)) {
          dispatch({ type: actionTypes.UPDATE_WORKER_SUCCESS });
        } else {
          dispatch({ type: actionTypes.UPDATE_WORKER_FAILD, error: resp.code });
        }
      });
  });
};

const sendWorkerInvitationAction = (invitationData: SendWorkerInvitation) => (dispatch: any) => {
  dispatch({ type: actionTypes.SEND_WORKER_INVITATION });
  return sendWorkerInvitation(invitationData).then((resp) => {
    getContacts()
      .then((contact) => {
        if (checkErrorStatus(contact.code, dispatch)) {
          dispatch({ type: actionTypes.GET_CONTACT_SUCCESS, contacts: contact.data });
          return contact.data;
        } else {
          dispatch({ type: actionTypes.GET_CONTACT_FAILD, error: contact.code });
          return contact.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp.code, dispatch)) {
          dispatch({ type: actionTypes.SEND_WORKER_INVITATION_SUCCESS });
        } else {
          dispatch({ type: actionTypes.SEND_WORKER_INVITATION_FAILD, error: resp.code });
        }
      });
  });
};

const answerWorkerInvitationAction = (answerInv: AnswerWorkerInvitation) => (dispatch: any) => {
  dispatch({ type: actionTypes.ANSWER_WORKER_INVITATION });
  return answerInvitation(answerInv).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.ANSWER_WORKER_INVITATION_SUCCESS });
      return true;
    } else {
      dispatch({ type: actionTypes.ANSWER_WORKER_INVITATION_FAILD, error: resp.code });
      return false;
    }
  });
};

const deleteContactAction = (id: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_CONTACT });
  return deleteContact(id).then((resp) => {
    getContacts()
      .then((contact) => {
        if (checkErrorStatus(contact.code, dispatch)) {
          getCompanies().then((company) => {
            if (checkErrorStatus(company.code, dispatch)) {
              dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, companies: company.data });
              return company.data;
            } else {
              dispatch({ type: actionTypes.GET_COMPANIES_FAILD, error: company.code });
              return company.data;
            }
          });
          dispatch({ type: actionTypes.GET_CONTACT_SUCCESS, contacts: contact.data });
          return contact.data;
        } else {
          dispatch({ type: actionTypes.GET_CONTACT_FAILD, error: contact.code });
          return contact.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp.code, dispatch)) {
          dispatch({ type: actionTypes.DELETE_CONTACT_SUCCESS });
        } else {
          dispatch({ type: actionTypes.DELETE_CONTACT_FAILD, error: resp.code });
        }
      });
  });
};

const getMyCompanyDataAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_MY_COMPANY_DATA });
  return getMyCompanyData().then((company) => {
    if (company.data !== null && checkErrorStatus(company.code, dispatch)) {
      dispatch({
        type: actionTypes.GET_MY_COMPANY_DATA_SUCCESS,
        myCompanyEtoken: company.data.einvoiceToken,
      });
      return company.data;
    } else {
      dispatch({ type: actionTypes.GET_MY_COMPANY_DATA_FAILD, error: 11 });
      return null;
    }
  });
};

const getCompaniesAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_COMPANIES });
  return getCompanies().then((company) => {
    if (checkErrorStatus(company.code, dispatch)) {
      dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, companies: company.data });
      return company.data;
    } else {
      dispatch({ type: actionTypes.GET_COMPANIES_FAILD, error: company.code });
      return company.data;
    }
  });
};

const newCompanyAction = (newCompanyItem: NewCompany) => (dispatch: any) => {
  dispatch({ type: actionTypes.NEW_COMPANIES });
  return newCompany(newCompanyItem).then((resp) => {
    getCompanies()
      .then((company) => {
        if (checkErrorStatus(company.code, dispatch)) {
          dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, companies: company.data });
          return company.data;
        } else {
          dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, error: company.code });
          return company.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp.code, dispatch)) {
          dispatch({ type: actionTypes.NEW_COMPANIES_SUCCESS });
        } else {
          dispatch({ type: actionTypes.NEW_COMPANIES_FAILD, error: resp.code });
        }
      });
  });
};

const updateCompanyAction = (updateCompanyItem: NewCompany) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_COMPANIES });
  return updateCompany(updateCompanyItem).then((resp) => {
    getCompanies()
      .then((company) => {
        if (checkErrorStatus(company.code, dispatch)) {
          dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, companies: company.data });
          return company.data;
        } else {
          dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, error: company.code });
          return company.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp.code, dispatch)) {
          dispatch({ type: actionTypes.UPDATE_COMPANIES_SUCCESS });
        } else {
          dispatch({ type: actionTypes.UPDATE_COMPANIES_FAILD, error: resp.code });
        }
      });
  });
};

const updateMyCompanyDataAction = (updateCompanyItem: NewCompany) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_MY_COMPANY_DATA });
  return updateCompany(updateCompanyItem).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.UPDATE_MY_COMPANY_DATA_SUCCESS });
      return true;
    } else {
      dispatch({ type: actionTypes.UPDATE_MY_COMPANY_DATA_FAILD });
      return false;
    }
  });
};

const deleteCompanyAction = (id: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_COMPANIES });
  return deleteCompany(id).then((resp) => {
    getCompanies()
      .then((company) => {
        if (checkErrorStatus(company.code, dispatch)) {
          dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, companies: company.data });
          return company.data;
        } else {
          dispatch({ type: actionTypes.GET_COMPANIES_SUCCESS, error: company.code });
          return company.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp.code, dispatch)) {
          dispatch({ type: actionTypes.DELETE_COMPANIES_SUCCESS });
        } else {
          dispatch({ type: actionTypes.DELETE_COMPANIES_FAILD, error: 11 });
        }
      });
  });
};

const createCompanyAction = (plan: Plan) => (dispatch: any) => {
  dispatch({ type: actionTypes.CREATE_COMPANY });
  return createCompany(plan).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      if (resp.data !== null) {
        dispatch({ type: actionTypes.CREATE_COMPANY_SUCCESS });
        return resp.data;
      } else {
        dispatch({ type: actionTypes.CREATE_COMPANY_FAILD, error: 11 });
        return false;
      }
    } else {
      dispatch({ type: actionTypes.CREATE_COMPANY_FAILD, error: 11 });
      return false;
    }
  });
};

export {
  getContactAction,
  updateClientAction,
  updateWorkerAction,
  answerWorkerInvitationAction,
  sendWorkerInvitationAction,
  deleteContactAction,
  getCompaniesAction,
  getMyCompanyDataAction,
  newCompanyAction,
  updateCompanyAction,
  updateMyCompanyDataAction,
  deleteCompanyAction,
  createCompanyAction,
};
