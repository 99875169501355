import React from 'react';

const useMediaQuery = (width: number) => {
  const [isLessThan, setIsLessThan] = React.useState(window.innerWidth < width);

  React.useEffect(() => {
    const handleResize = () => setIsLessThan(window.innerWidth < width);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return isLessThan;
};

export default useMediaQuery;
