export const getFormattedDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const getFormattedInvoiceDate = (timestamp: number | null | undefined) => {
  let lang = window.localStorage.getItem('lang');
  if (lang === null || lang === undefined) {
    lang = 'en';
  }
  if (timestamp === null || timestamp === undefined) return '';
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString(lang, { month: 'short' });
  const year = date.getFullYear();

  return `${day} ${month} ${year} - ${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};
