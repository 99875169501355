import { CompaniesRepository } from '../../domain/user-management/UsersRepository';

type GetCompaniesUseCase = Pick<
  CompaniesRepository,
  'companies' | 'getCompanies' | 'getMyCompanyData'
>;

const getMyCompanyDataUseCase = (repo: GetCompaniesUseCase) => {
  return repo.getMyCompanyData();
};

const getCompaniesUseCase = (repo: GetCompaniesUseCase) => {
  repo.getCompanies();
};

const getCompanyNameUseCase = (id: number, repo: GetCompaniesUseCase): string => {
  const name = repo.companies?.find((com) => com.id === id);
  if (name === undefined) return '';
  return name !== null ? name.name : '';
};

export { getCompaniesUseCase, getCompanyNameUseCase, getMyCompanyDataUseCase };
