import { BASE_URL } from '../Server';
import { Lobby } from '../../domain/lobby/Lobby';
import { fetchWithAuth } from '../utils/fetchWithAuth';

const getHallCompanies = async (id: number): Promise<Lobby> => {
  try {
    const response = await fetchWithAuth(BASE_URL + `/getHallCompanies/${id}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export { getHallCompanies };
