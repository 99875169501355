import React from 'react';

import { Case } from '../../domain/Case';
import { DashboardLabWorkUiModel, DashboardUiModel } from '../ui-model/DashboardUiModel';
import { CaseRepository } from '../../domain/CaseRepository';
import { getCasesUseCase } from '../../useCases/case/getCasesUseCases';
import { ACTIVE, DRAFT } from './DashboardFilter';
import { createCaseUseCase } from '../../useCases/case/createCaseUseCase';
import { ContactsRepository } from '../../domain/user-management/UsersRepository';
import { WorkTypeRepository } from '../../domain/worktype/WorkTypeRepository';
import { getWorkersUseCase } from '../../useCases/user-management/getContactsUseCase';
import { getPhaseStatusStyleNameUseCase } from '../../useCases/worktype/phases/getPhaseStatusStyleNameUseCase';
import { getWorkTypeNameUseCase } from '../../useCases/worktype/getWorkTypeNameUseCase';
import {
  OPEN,
  STARTED,
  FINISHED,
  FINISHED_W_ERROR_CLUMSY,
  FINISHED_W_ERROR_INATTENTIVE,
  FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE,
} from '../../data/cases/detail/PhaseStatus';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { getFeatureByIdUseCase } from '../../useCases/features/getFeatureUseCase';
import { FeatureId } from '../../domain/features/Features';
import useTranslate from '../translations/useTranslate';

// One day in milliseconds
const ExpireTime = 3600 * 1000 * 24;

function useDashboardViewModel(
  repo: CaseRepository,
  cRepo: ContactsRepository,
  workTypeRepo: WorkTypeRepository,
  featureRepo: FeaturesRepository,
) {
  const translate = useTranslate();

  const getCases = React.useCallback(
    function () {
      getCasesUseCase({
        getCases: repo.getCases,
        getCaseById: repo.getCaseById,
      });
    },
    [repo.getCases],
  );

  const getWorkers = React.useCallback(
    function () {
      getWorkersUseCase({
        getContacts: cRepo.getContacts,
        contacts: cRepo.contacts,
      });
    },
    [cRepo.getContacts],
  );

  const createCase = React.useCallback(
    (onSuccess: (id: number) => void) => {
      console.log('useCaseDetailFooterPopUpViewModel');
      createCaseUseCase({ newCase: repo.newCase }, onSuccess);
    },
    [repo.newCase],
  );

  const isEnabledNewCaseFeatureAction = (userRole: number) => {
    return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.NEW_CASE_ACTION);
  };

  return {
    dashboardCases: geDashboardCases(repo.cases),
    isLoading: true,
    getCases,
    getWorkers,
    createCase,
    isEnabledNewCaseFeatureAction,
  };

  function geDashboardCases(cases: Case[] | null): DashboardUiModel[] {
    if (cases === null) {
      return [];
    }
    const list: DashboardUiModel[] = [];

    cases?.forEach((item) => {
      const currentItems = selectActivePhases(item);
      let date;
      let day;
      let month;
      let isExpireDate = false;
      if (currentItems.currentDate !== undefined) {
        date = new Date(currentItems.currentDate.date);
        day = date.getDate();
        month = date.toLocaleString('en', { month: 'short' });
        isExpireDate = new Date(Date.now() + ExpireTime) > date;
      }

      const currentItem = getCurrentPhase(
        currentItems.currentLabworkPhases,
        currentItems.subCategory,
        workTypeRepo,
        currentItems.workType,
        cRepo,
        currentItems.currentDate,
        isExpireDate,
      );
      list.push(
        new DashboardUiModel(
          getMenuId(item.status),
          item.id,
          item.clientName,
          item.patientName,
          item.patientAge,
          currentItem?.labwork,
          item?.labWorks?.length,
          item.creationDate,
          {
            time: currentItems.currentDate !== undefined ? `${day} ${month}` : undefined,
            expire: isExpireDate,
          },
        ),
      );
    });
    return list.sort((a, b) =>
      a.creationDate !== undefined &&
      b.creationDate !== undefined &&
      a.creationDate > b.creationDate
        ? 1
        : -1,
    );
  }

  function selectActivePhases(c: Case) {
    let currentLabworkPhases;
    let currentDate;
    let workType = -1;
    let subCategory;
    let labworkId;
    if (c.labWorks !== null) {
      c.labWorks?.forEach((labwork) => {
        labwork.phases?.forEach((phase, index) => {
          if (phase.date !== undefined) {
            if (
              currentDate === undefined ||
              labwork.labWorkId !== labworkId ||
              phase.date < currentDate
            ) {
              const prevPhase = labwork.phases
                ?.filter((p, ind) => p.type !== 1 && ind < index)
                .at(-1);
              if (
                prevPhase === undefined ||
                (prevPhase !== undefined &&
                  prevPhase.status !== FINISHED &&
                  prevPhase.status !== FINISHED_W_ERROR_CLUMSY &&
                  prevPhase.status !== FINISHED_W_ERROR_INATTENTIVE &&
                  prevPhase.status !== FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE)
              ) {
                currentLabworkPhases = labwork.phases;
                currentDate = phase;
                workType = labwork.workType;
                subCategory = translate(labwork.extensionList[0]?.name);
                labworkId = labwork.labWorkId;
              }
            }
          }
        });
      });
    }

    if (currentLabworkPhases === undefined && c.labWorks !== undefined) {
      if (c.labWorks.length > 0) {
        currentLabworkPhases = c.labWorks[0].phases || [];
        workType = c.labWorks[0].workType;
        subCategory = translate(c.labWorks[0].extensionList[0]?.name);
      } else {
        currentLabworkPhases = [];
        workType = -1;
        subCategory = '';
      }
    }
    return {
      currentLabworkPhases: currentLabworkPhases,
      currentDate: currentDate,
      workType: workType,
      subCategory: subCategory,
    };
  }

  function getCurrentPhase(
    phases,
    subCategory,
    workTypeRepo,
    workType,
    cRepo,
    currentDate,
    isExpireDate,
  ) {
    let currentPhase;
    let edgeRight = false;
    let edgeLeft = false;
    let previousPhaseStatus;

    const phasesWithoutDate = phases.filter((p) => p.type !== 1);
    if (phasesWithoutDate.every((p) => p.status === OPEN)) {
      currentPhase = phases[0];
      edgeRight = true;
      edgeLeft = false;
    } else {
      phases.forEach((phase, index) => {
        if (
          phase.status === STARTED ||
          phase.status === FINISHED ||
          phase.status === FINISHED_W_ERROR_CLUMSY ||
          phase.status === FINISHED_W_ERROR_INATTENTIVE ||
          phase.status === FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE
        ) {
          currentPhase = phase;
          if (index === 0) {
            edgeRight = true;
            edgeLeft = false;
          } else if (index === phases.length - 1) {
            edgeRight = false;
            edgeLeft = true;
          } else {
            edgeRight = true;
            edgeLeft = true;
          }
        }
      });
    }
    if (currentPhase === undefined) {
      return;
    }
    const currentPhaseIndexWithoutdate = phasesWithoutDate.findIndex(
      (phase) => phase.id === currentPhase.id && phase.order === currentPhase.order,
    );
    if (
      currentPhaseIndexWithoutdate !== -1 &&
      phasesWithoutDate[currentPhaseIndexWithoutdate - 1] !== undefined
    ) {
      previousPhaseStatus = phasesWithoutDate[currentPhaseIndexWithoutdate - 1].status;
    }

    const assignee = cRepo?.contacts?.workers?.find(
      (worker) => worker.id === currentPhase?.assigneeId,
    );

    let partialPhasesStatus = getPartialPhaseList(phases, phasesWithoutDate, currentPhase);
    const partialCurrentPhaseIndex = partialPhasesStatus.findIndex(
      (phase) => phase.id === currentPhase.id && phase.order === currentPhase.order,
    );
    partialPhasesStatus =
      partialPhasesStatus.map((phase, index) => {
        if (phase.type === 1) {
          let status = '';
          if (
            currentDate !== undefined &&
            phase.order === currentDate.order &&
            phase.date === currentDate.date &&
            isExpireDate
          ) {
            status = getPhaseStatusStyleNameUseCase(FINISHED_W_ERROR_CLUMSY);
          } else if (index < partialCurrentPhaseIndex) {
            status = getPhaseStatusStyleNameUseCase(FINISHED);
          }
          return {
            index: index,
            status: status,
            icon: true,
            current: phase.id === currentPhase.id && phase.order === currentPhase.order,
          };
        } else {
          let cur = false;
          if (phase.id === currentPhase.id && phase.order === currentPhase.order) {
            if (
              index === partialPhasesStatus.length - 1 &&
              (phase.status === OPEN || phase.status === STARTED)
            ) {
              cur = true;
            } else {
              if (index < partialPhasesStatus.length - 1) {
                cur = true;
              } else {
                cur = false;
              }
            }
          }
          return {
            index: index,
            status: getPhaseStatusStyleNameUseCase(phase.status),
            icon: false,
            current: cur,
          };
        }
      }) || [];

    return {
      labwork: new DashboardLabWorkUiModel(
        translate(getWorkTypeNameUseCase(workTypeRepo, workType)),
        subCategory,
        currentPhase.name,
        getPhaseStatusStyleNameUseCase(currentPhase.status),
        assignee?.name,
        assignee?.user !== null && assignee?.user.profileColor !== null
          ? assignee?.user.profileColor
          : 'var(--dts_default_blue)',
        getPhaseStatusStyleNameUseCase(previousPhaseStatus),
        partialPhasesStatus,
        edgeRight,
        edgeLeft,
      ),
    };
  }

  function getPartialPhaseList(phases, phasesWithoutDate, currentPhase) {
    if (phasesWithoutDate.length < 9) {
      return phases;
    }
    const currentPhaseIndex = phasesWithoutDate.findIndex((phase) => phase.id === currentPhase.id);
    const list: any[] = [];
    let count = 0;
    if (currentPhaseIndex < 5) {
      phases.forEach((phase) => {
        if (count === 9) return;
        list.push(phase);
        if (phase.type !== 1) {
          count++;
        }
      });
      return list;
    } else if (currentPhaseIndex >= phasesWithoutDate.length - 6) {
      const reversePhaseList = phases.reverse();
      reversePhaseList.forEach((phase) => {
        if (count === 9) return;
        list.push(phase);
        if (phase.type !== 1) {
          count++;
        }
      });
      return list.reverse();
    } else {
      const selectedPhases = phasesWithoutDate.slice(currentPhaseIndex - 4, currentPhaseIndex + 5);
      phases.forEach((phase) => {
        let adePhase = false;
        if (count === 9) return;
        if (selectedPhases.some((p) => p.id === p.id && p.order === phase.order)) {
          list.push(phase);
          adePhase = true;
        }
        if (phase.type === 1) {
          list.push(phase);
        }
        if (phase.type !== 1 && adePhase) {
          count++;
        }
      });
      return list;
    }
  }

  function getMenuId(s: number | null) {
    if (s === 0) return DRAFT;
    if (s === 1) return ACTIVE;
    else return -1;
  }
}

export { useDashboardViewModel };
