import { Package } from '../../domain/billing/Package';
import { setSubscription } from '../authenticationFlow/authActionTypes';
import { checkErrorStatus } from '../ErrorHandling';

import * as actionTypes from './billingActionTypes';
import {
  getCompanySubscription,
  getMaxCollaborators,
  getPackages,
  getTrialEnable,
  updateCompanyPackage,
} from './billingService';

const getPackagesAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_PACKAGES });
  return getPackages().then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.GET_PACKAGES_SUCCESS });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.GET_PACKAGES_FAILD, error: resp.code });
      return [];
    }
  });
};

const updateCompanyPackageAction = (params: Package) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_COMPANY_PACKAGE });
  return updateCompanyPackage(params).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.UPDATE_COMPANY_PACKAGE_SUCCESS });
      getCompanySubscriptionAction()(dispatch);
      return resp.data;
    } else {
      dispatch({ type: actionTypes.UPDATE_COMPANY_PACKAGE_FAILD, error: resp.code });
      return false;
    }
  });
};

const getMaxCollaboratorsAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_MAX_PACKAGE_COLLABORATOR });
  return getMaxCollaborators().then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.GET_MAX_PACKAGE_COLLABORATOR_SUCCESS });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.GET_MAX_PACKAGE_COLLABORATOR_FAILD, error: resp.code });
      return false;
    }
  });
};

const getCompanySubscriptionAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_COMPANY_SUBSCRIPTION });
  return getCompanySubscription().then((resp) => {
    if (resp.data !== null && checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.GET_COMPANY_SUBSCRIPTION_SUCCESS });
      dispatch(
        setSubscription({
          validSubscription: new Date().getTime() < resp.data.endTime,
          packageType: resp.data.packageType,
          billingType: resp.data.status,
          subscriptionEndTime: resp.data.endTime,
        }),
      );
      return resp.data;
    } else {
      dispatch({ type: actionTypes.GET_COMPANY_SUBSCRIPTION_FAILD, error: resp.code });
      return resp.data;
    }
  });
};

const getTrialEnableAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_TRIAL_ENABLE });
  return getTrialEnable().then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.GET_TRIAL_ENABLE_SUCCESS });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.GET_TRIAL_ENABLE_FAILD, error: resp.code });
      return false;
    }
  });
};

export {
  getPackagesAction,
  updateCompanyPackageAction,
  getMaxCollaboratorsAction,
  getCompanySubscriptionAction,
  getTrialEnableAction,
};
