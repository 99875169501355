import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

type authenticateUserUseCasesRepository = Pick<AuthRepository, 'handleLogin'>;

const authenticateUserUseCases = (
  repo: authenticateUserUseCasesRepository,
  username: string,
  password: string,
) => {
  return repo.handleLogin({ username: username, password: password });
};

export { authenticateUserUseCases };
export type { authenticateUserUseCasesRepository };
