import { WorkTypeRepository } from '../../domain/worktype/WorkTypeRepository';

type GetWorkTypeNameUseCase = Pick<WorkTypeRepository, 'workTypes'>;

const getWorkTypeNameUseCase = (repo: GetWorkTypeNameUseCase, id: number): string | null => {
  if (repo.workTypes === null) return null;
  const name = repo.workTypes.find((worktype) => worktype.id === id)?.name;
  if (name === undefined) return null;
  return name;
};

export { getWorkTypeNameUseCase };
