export const saveLastUrl = () => {
  const path = window.location.pathname;
  const pathParts = path.split('/');
  const id = pathParts[1];
  const parsedCompanyId = Number(id);
  if (!isNaN(parsedCompanyId)) {
    if (path.includes('reportsoverview')) {
      localStorage.setItem(
        'redirectAfterLogin',
        window.location.origin + '/' + parsedCompanyId + '/reports',
      );
    } else {
      localStorage.setItem('redirectAfterLogin', window.location.href);
    }
  }
  return true;
};
