import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../utils/store';
import {
  AlertManager,
  AlertType,
  SHOW_ALERT,
  HIDE_ALERT,
  SHOW_PAYMENT_EXPIRE_MODAL,
  HIDE_PAYMENT_EXPIRE_MODAL,
} from '../../domain/alert/AlertManager';
import { AlertState } from './alertReducer';

const alertSelector = (state: RootState) => state.alert;

const useAlertManagerImplementation = (): AlertManager => {
  const { message, alertType, isOpenExpireAlert, userRole } = useSelector<RootState, AlertState>(
    alertSelector,
  );

  const dispatch = useDispatch();

  const showAlert = (message: string, type: AlertType) => {
    dispatch({
      type: SHOW_ALERT,
      actionType: type,
      message: message,
    });
  };

  const hideAlert = () => {
    dispatch({ type: HIDE_ALERT });
  };

  const showPaymentExpireModal = (type: AlertType, userRole: number) => {
    dispatch({
      type: SHOW_PAYMENT_EXPIRE_MODAL,
      actionType: type,
      userRole: userRole,
    });
  };

  const hidePaymentExpireModal = () => {
    dispatch({
      type: HIDE_PAYMENT_EXPIRE_MODAL,
    });
  };

  return {
    message,
    alertType,
    isOpenExpireAlert,
    userRole,
    showAlert,
    hideAlert,
    showPaymentExpireModal,
    hidePaymentExpireModal,
  };
};

export { useAlertManagerImplementation };
