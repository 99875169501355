import { BASE_URL } from '../Server';
import {
  InvoiceUpdateResponse,
  InvoiceUpdate,
  InvoiceResponse,
  InvoiceDeleteResponse,
} from '../../domain/invoice/Invoice';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';

const getInvoices = async (): Promise<InvoiceResponse> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/invoices', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: [] };
    }
  } catch (error) {
    return { code: error.status, message: error, data: [] };
  }
};

const getInvoiceById = async (id: number): Promise<InvoiceUpdateResponse> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/invoices/' + id, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateInvoice = async (params: InvoiceUpdate): Promise<InvoiceUpdateResponse> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/updateInvoice', {
      method: 'POST',
      body: JSON.stringify(params),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const deleteInvoice = async (id: number): Promise<InvoiceDeleteResponse> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/invoices/delete/${id}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: false };
    }
  } catch (error) {
    return { code: error.status, message: error, data: false };
  }
};

const checkInvoiceNumber = async (invoicePrefix: string, invoiceNumber: number) => {
  try {
    const response = await fetchWithAuthAndCompanyId(
      BASE_URL + `/checkInvoiceNumber?prefix=${invoicePrefix}&number=${invoiceNumber}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: false };
    }
  } catch (error) {
    return { code: error.status, message: error, data: false };
  }
};

export { getInvoices, getInvoiceById, updateInvoice, checkInvoiceNumber, deleteInvoice };
