import { BASE_URL } from '../Server';
import { WorkTypeData } from '../../domain/worktype/WorkTypeData';
import UpdateWorTypeInfo from '../../domain/worktype/UpdateWorTypeInfo';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';

const getWorkTypes = async (): Promise<WorkTypeData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/workType?includeInactive=true', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateWorkTypes = async (
  worktypeList: UpdateWorTypeInfo[],
): Promise<{ code: number; message: string; data: any }> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/updateWorTypeInfo', {
      method: 'POST',
      body: JSON.stringify({ items: worktypeList }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export { getWorkTypes, updateWorkTypes };
