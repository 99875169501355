import { BASE_URL } from '../Server';
import {
  ReportCasesData,
  ReportCasesRequest,
  ReportCasesByIdsData,
} from '../../domain/reports/Report';
import { SolvedPayOff, SolvedPayOffResponse } from '../../domain/reports/PayOff';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';

const getReportCases = async (params: ReportCasesRequest): Promise<ReportCasesData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/getFilteredList', {
      method: 'POST',
      body: JSON.stringify(params),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const getReportCasesByIds = async (param: Array<number>): Promise<ReportCasesByIdsData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/getCasesById', {
      method: 'POST',
      body: JSON.stringify(param),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: [] };
    }
  } catch (error) {
    return { code: error.status, message: error, data: [] };
  }
};

const accountPhases = async (param: SolvedPayOff): Promise<SolvedPayOffResponse> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/accountPhases', {
      method: 'POST',
      body: JSON.stringify(param),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export { getReportCases, getReportCasesByIds, accountPhases };
