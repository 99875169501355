import {
  ResponseActivateEfactura,
  ResponseCheckEActivationToken,
  ResponseEfacturaStatus,
  ResponseGenerateEActivationToken,
  ResponseUploadEfactura,
} from '../../domain/e-factura/E-factura';
import { BASE_URL } from '../Server';
import { InvoiceUpdateResponse } from '../../domain/invoice/Invoice';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';

const activateEfacturaByAccauntat = async (
  activationToken: string,
  eCode: string,
): Promise<ResponseActivateEfactura> => {
  try {
    const response = await fetchWithAuthAndCompanyId(
      BASE_URL + `/activateEInvoiceWithToken?code=${eCode}&token=${activationToken}`,
      {
        method: 'POST',
        body: JSON.stringify({ code: eCode, token: activationToken }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const generateEActivationToken = async (): Promise<ResponseGenerateEActivationToken> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/generateEActivationToken`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const refreshEToken = async (): Promise<ResponseActivateEfactura> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/refreshEToken`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const checkActivationToken = async (param: {
  companyId: number;
  token: string;
}): Promise<ResponseCheckEActivationToken> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/checkETokenUrl`, {
      method: 'POST',
      body: JSON.stringify(param),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const activateEfacturaByUser = async (code: string): Promise<ResponseActivateEfactura> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/activateEInvoice?code=${code}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: false };
    }
  } catch (error) {
    return { code: error.status, message: error, data: false };
  }
};

const updateInvoiceWithAnafData = async (param: {
  invoiceId: number;
  onlineInvoiceId: string;
  onlineStatus: number;
}): Promise<InvoiceUpdateResponse> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/updateInvoice`, {
      method: 'POST',
      body: JSON.stringify(param),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const uploadEFactura = async (invoiceId: number): Promise<ResponseUploadEfactura> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/sendInvoiceToAnaf/${invoiceId}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: false };
    }
  } catch (error) {
    return { code: error.status, message: error, data: false };
  }
};

const getStatusAnafFactura = async (invoiceId: number): Promise<ResponseEfacturaStatus> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/getEInvoiceStatus/${invoiceId}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export {
  activateEfacturaByAccauntat,
  generateEActivationToken,
  activateEfacturaByUser,
  updateInvoiceWithAnafData,
  checkActivationToken,
  refreshEToken,
  uploadEFactura,
  getStatusAnafFactura,
};
