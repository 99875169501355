import React, { FC } from 'react';
import styled from 'styled-components';
import { DeviceSizeProps } from '../../DeviceInformations';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LogoIcon } from '../../../icons/logo.svg';
import { ReactComponent as SmallLogoIcon } from '../../../icons/smallLogo.svg';
import { ReactComponent as MenuIcon } from '../../../icons/menu.svg';
import * as actionTypes from './menuActionTypes';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { RootState } from '../../../data/utils/store';

const menuSelector = (state: RootState) => state.menu;

export type MenuHeaderPros = {
  companyId: number | null;
};

export const MenuHeader: FC<MenuHeaderPros> = ({ companyId }) => {
  const dispatch = useDispatch();
  const menuState = useSelector<RootState, { open: number }>(menuSelector);
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  return (
    <MenuHeaderWrapper
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
      className={`${!menuState.open && 'menu-close'}`}
    >
      <MenuIconConatiner className={`${!menuState.open && 'menu-close'}`}>
        <MenuIcon
          onClick={() =>
            dispatch({
              type: menuState.open === 0 ? actionTypes.MENU_OPEN : actionTypes.MENU_CLOSE,
            })
          }
        />
      </MenuIconConatiner>
      <MenuHeaderContent
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
        to={companyId === null ? '/' : `/${companyId}`}
      >
        {menuState.open ? <LogoIcon /> : <SmallLogoIcon className='small-logo' />}
      </MenuHeaderContent>
    </MenuHeaderWrapper>
  );
};

const MenuHeaderWrapper = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: relative;
    padding: 8px 10px 12px 10px;
      text-align: right;
      svg{
        cursor: pointer;
        fill: var(--dts_black);
        &:hover {
          fill: var(--dts_hover_blue);
        }
      }
      &.menu-close {
        text-align: left;
        svg{
          &:not(.small-logo) {
            padding-left: 6px;
          }
        }
      }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MenuHeaderContent = styled(Link)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    text-decoration: none;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 8px;

    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MenuIconConatiner = styled.span`
  display: inline-flex;
  &:not(.menu-close) {
    background: var(--dts_withe_background);
    border-radius: 4px;
  }
`;
