import { ToothConnection } from './ToothConnection';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { CaseDetailContext } from '../../CaseDetailProvider';
import { ToothPanoramaPContext } from './ToothPanoramaProvider';
import { Tooths } from '../../../ui-model/CaseDetail';
import { setCaseDetailTooths } from '../../../../data/cases/detail/caseDetailReducer';
import { useDispatch } from 'react-redux';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';

export function ToothConnectionPanorama() {
  const { caseDetailObj } = useContext(CaseDetailContext);
  const { isTabletSmall } = useDeviceParameters();

  const [dragedConnectionElement, setDragedConnectionElement] = useContext(ToothPanoramaPContext);

  const dispatch = useDispatch();

  function setToothconnections(t: Tooths) {
    dispatch(setCaseDetailTooths(t));
  }

  const [zindex, setZindex] = React.useState(1);

  // TODO
  const connectableTeethTypes = [1, 2, 4, 7, 8, 9];

  function isConnectable(
    teeth: {
      width: number;
      teethp: number;
      type: number;
      labwork: number;
      quarter: number;
      leftConnectionType: number;
      cwidth: string;
      height: string;
      left: string;
      top: string;
    } | null,
  ) {
    return teeth !== null && connectableTeethTypes.includes(teeth.type);
  }

  function hasConnection(
    index: number,
    array: {
      width: number;
      teethp: number;
      type: number;
      labwork: number;
      quarter: number;
      leftConnectionType: number;
      cwidth: string;
      height: string;
      left: string;
      top: string;
    }[],
  ) {
    const actualTeeth = array.at(index)!;
    // //console.log("Trying to find neighbour of teeth" + actualTeeth!.teethp + " in quarter " + actualTeeth!.quarter)

    const neighbourTeeth = array.find((v) => {
      if (actualTeeth.teethp === 1) {
        if (actualTeeth.quarter === 1) {
          return actualTeeth.quarter + 1 === v.quarter && v.teethp === actualTeeth.teethp;
        } else if (actualTeeth.quarter === 4) {
          return actualTeeth.quarter - 1 === v.quarter && v.teethp === actualTeeth.teethp;
        }
      } else {
        return v.quarter === actualTeeth.quarter && v.teethp === actualTeeth.teethp - 1;
      }
    });

    // //console.log("We ar looking to define rconnection between teeth" + actualTeeth!.teethp + " in quarter " + actualTeeth!.quarter + " and " + neighbourTeeth?.teethp + " in quarter " + neighbourTeeth?.quarter )
    return (
      actualTeeth.labwork === neighbourTeeth?.labwork &&
      isConnectable(actualTeeth) &&
      isConnectable(neighbourTeeth)
    );
  }

  function applyToothType(
    upper: boolean,
    teethp: number,
    quarter: number,
    nextToothConnectionType: number,
  ) {
    if (isTabletSmall) return;
    if (!caseDetailObj.workSheetEditable) {
      return;
    }

    const newTooths = JSON.parse(JSON.stringify(caseDetailObj.tooths));

    let clickedTeeth;
    if (upper) {
      clickedTeeth = newTooths.upper.find((t) => t.teethp === teethp && t.quarter === quarter);
    } else {
      clickedTeeth = newTooths.lower.find((t) => t.teethp === teethp && t.quarter === quarter);
    }
    // //console.log("set connection type" + nextToothConnectionType + " for " + teethp + " in quarter " + quarter + " upper " + upper + " old connection " + clickedTeeth.leftConnectionType)

    clickedTeeth.leftConnectionType = nextToothConnectionType;

    setToothconnections({ lower: newTooths.lower, upper: newTooths.upper });
  }

  return (
    <CaseWorkSheetConnectionPanorama
      onMouseUpCapture={() =>
        setDragedConnectionElement({
          labWork: -1,
          leftConnectionType: -6,
          teeth: -1,
          quarter: -1,
        })
      }
      onMouseLeave={() => setZindex(0)}
      onMouseUp={() =>
        setDragedConnectionElement({
          labWork: -2,
          leftConnectionType: -8,
          teeth: -1,
          quarter: -1,
        })
      }
      style={{ zIndex: zindex + '' }}
    >
      <>
        <CaseWorkSheetToothPanoramaUpperToothConnections
          pointer={dragedConnectionElement.labWork > -1 ? 'auto' : 'none'}
        >
          {caseDetailObj !== null &&
            caseDetailObj.tooths !== null &&
            caseDetailObj.tooths.upper.map((value, index, array) => {
              // //console.log(`Rajzolom e szr ${value.labwork} es  a ${props.selectedLabWork}`)
              if (value.labwork > -1 && hasConnection(index, array)) {
                return (
                  <ToothConnection
                    key={index}
                    leftTooth={value.teethp}
                    quarter={value.quarter}
                    width={value.cwidth}
                    height={value.height}
                    left={value.left}
                    top={value.top}
                    isSelected={value.leftConnectionType > 0}
                    isActive={value.labwork === caseDetailObj.selectedLabWork}
                    onMouseLeave={() => setZindex(-1)}
                    onClick={() =>
                      applyToothType(
                        true,
                        value.teethp,
                        value.quarter,
                        value.leftConnectionType === 1 ? 0 : 1,
                      )
                    }
                    onMouseDown={() => {
                      setDragedConnectionElement({
                        labWork: value.labwork,
                        leftConnectionType: value.leftConnectionType,
                        teeth: value.teethp,
                        quarter: value.quarter,
                      });
                      // setZindex(-1)
                    }}
                    onMouseUp={() => {
                      setDragedConnectionElement({
                        labWork: -3,
                        leftConnectionType: -9,
                        teeth: -1,
                        quarter: -1,
                      });
                      // setZindex(1)
                    }}
                  />
                );
              }
            })}
        </CaseWorkSheetToothPanoramaUpperToothConnections>
      </>
      <>
        <CaseWorkSheetToothPanoramaLowerToothConnections
          pointer={dragedConnectionElement.labWork > -1 ? 'auto' : 'none'}
        >
          {caseDetailObj !== null &&
            caseDetailObj.tooths !== null &&
            caseDetailObj.tooths.lower.map((value, index, array) => {
              if (value.labwork > -1 && hasConnection(index, array)) {
                return (
                  <ToothConnection
                    key={index}
                    leftTooth={value.teethp}
                    quarter={value.quarter}
                    width={value.cwidth}
                    height={value.height}
                    left={value.left}
                    top={value.top}
                    isSelected={value.leftConnectionType > 0}
                    isActive={value.labwork === caseDetailObj.selectedLabWork}
                    onMouseLeave={() => setZindex(-1)}
                    onClick={() =>
                      applyToothType(
                        false,
                        value.teethp,
                        value.quarter,
                        value.leftConnectionType === 1 ? 0 : 1,
                      )
                    }
                    onMouseDown={() =>
                      setDragedConnectionElement({
                        labWork: value.labwork,
                        leftConnectionType: value.leftConnectionType,
                        teeth: value.teethp,
                        quarter: value.quarter,
                      })
                    }
                    onMouseUp={() =>
                      setDragedConnectionElement({
                        labWork: -4,
                        leftConnectionType: -10,
                        teeth: -1,
                        quarter: -1,
                      })
                    }
                  />
                );
              }
            })}
        </CaseWorkSheetToothPanoramaLowerToothConnections>
      </>
    </CaseWorkSheetConnectionPanorama>
  );
}

const CaseWorkSheetConnectionPanorama = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  pointer-events: none;
`;

const CaseWorkSheetToothPanoramaUpperToothConnections = styled.div<{ pointer: string }>`
  position: absolute;
  width: 529px;
  height: 85px;
  left: 56px;
  bottom: 138px;
  pointer-events: ${(props) => props.pointer};
`;

const CaseWorkSheetToothPanoramaLowerToothConnections = styled.div<{ pointer: string }>`
  position: absolute;
  width: 521px;
  height: 77px;
  left: 60px;
  top: 138px;
  pointer-events: ${(props) => props.pointer};
`;
