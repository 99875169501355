import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { CaseDetailProvider } from './CaseDetailProvider';
import { CaseDetailFooterPopUp } from './CaseDetailFooterPopUp';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import CaseDetailMobileComponent from './mobile/CaseDetailMobileComponent';
import ToggleHeader from '../components/generic/ToggleHeader';
import ContentLayout from '../components/layout/ContentLayout';
import DesktopHeader from '../components/generic/DesktopHeader';
import { CaseDetailHeader } from './CaseDetailHeader';

function CaseDetailComponent() {
  const { id } = useParams();

  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  return (
    <CaseDetailProvider>
      {(isTabletSmall || isTabletLarge) && (
        <ToggleHeader
          hasContent={false}
          title={''}
          enabledClick={false}
          buttonText=''
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={false}
          title={undefined}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      {!isMobile && (
        <ContentLayout>
          <CaseDetailHeader caseId={id} />
          <CaseDetailContent>
            <CaseDetailBody />
          </CaseDetailContent>
          <CaseDetailFooterPopUp />
        </ContentLayout>
      )}
      {isMobile && <CaseDetailMobileComponent />}
    </CaseDetailProvider>
  );
}

const CaseDetailContent = styled.div`
  position: relative;
`;

const CaseDetailBody = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: content-box;
`;
export default CaseDetailComponent;
