import React from 'react';
import { authenticateUserUseCases } from '../../../useCases/authenticationFlow/authenticateUserUseCases';
import { resetPasswordUseCases } from '../../../useCases/authenticationFlow/resetPasswordUseCase';
import { setPasswordUseCases } from '../../../useCases/authenticationFlow/setPasswordUseCase';
import { addUserUseCases } from '../../../useCases/authenticationFlow/addUserUseCase';
import { updateUserUseCases } from '../../../useCases/authenticationFlow/updateUserUseCase';
import { AuthRepository } from '../../../domain/authenticationFlow/AuthRepository';
import { UpdateUserData } from '../../../domain/authenticationFlow/AuthDataTypes';

function useSignUpFlowViewModel(authRepo: AuthRepository) {
  const authenticateUser = React.useCallback(
    function (username: string, password: string) {
      return authenticateUserUseCases({ handleLogin: authRepo.handleLogin }, username, password);
    },
    [authRepo.handleLogin],
  );

  const addUser = React.useCallback(
    function (username: string, isEnabledMailList: boolean) {
      return addUserUseCases(authRepo, username, isEnabledMailList);
    },
    [authRepo.addUser],
  );

  const setPassword = React.useCallback(
    function (emailKey: string, passwordKey: string, isRecover: boolean) {
      return setPasswordUseCases(
        { setPassword: authRepo.setPassword },
        { emailKey: emailKey, passwordKey: passwordKey },
        isRecover,
      );
    },
    [authRepo.setPassword],
  );

  const resetPassword = React.useCallback(
    function (username: string) {
      return resetPasswordUseCases({ resetPassword: authRepo.resetPassword }, username);
    },
    [authRepo.resetPassword],
  );

  const updateUser = React.useCallback(
    function (data: UpdateUserData) {
      return updateUserUseCases({ updateUser: authRepo.updateUser }, data);
    },
    [authRepo.updateUser],
  );
  return {
    authenticateUser,
    addUser,
    setPassword,
    resetPassword,
    updateUser,
  };
}

export { useSignUpFlowViewModel };
