import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Column from '../components/layout/Column';
import FilterBar, { SelectionOption } from './components/FilterBar';
import { CaseStates } from '../case/CaseState';
import { Checkbox } from '../components/dsm/Checkbox';
import { Text, TextType } from '../components/dsm/Text';
import NotFoundContainer from './components/NotFoundContainer';
import LoadingContainer from './components/LoadingContainer';
import { Button } from '../components/dsm/buttons/Button';
import Row from '../components/layout/Row';
import { Filter } from '../../domain/Filter';
import { ReportCasesRequest } from '../../domain/reports/Report';
import { ReactComponent as SortIcon } from '../../icons/filters/sort.svg';
import { ListOrder } from '../../domain/ListOrder';
import { useReportRepositoryImplementation } from '../../data/reports/ReportRepositoryImplementation';
import { useReportViewModel } from './ReportsViewModel';
import { ReportCaseUiModel } from '../ui-model/reports/ReportCaseUiModel';
import { useCompaniesRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import { ReportsOverviewType } from '../ui-model/reports/ReportOverviewUiModel';
import { ReportsRow } from './ReportsRow';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import DesktopHeader from '../components/generic/DesktopHeader';
import ToggleHeader from '../components/generic/ToggleHeader';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import ContentLayout from '../components/layout/ContentLayout';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { useCaseRepositoryImplementation } from '../../data/cases/CaseRepositoryImplementation';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { useAuth } from '../../routeFiles/AuthContext';

function Reports() {
  const translate = useTranslate();
  const repo = useReportRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const caseRepo = useCaseRepositoryImplementation();
  const {
    getReportCases,
    isLoading,
    isEnabledReportsOverviewMovesAction,
    isEnabledNewCaseFeatureAction,
    createCase,
    isEnabledInvoice,
  } = useReportViewModel(repo, companiesRepo, featureRepo, caseRepo);

  const navigate = useNavigate();
  const {
    isTabletSmall,
    isTabletLarge,
    isMobile,
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
  } = useDeviceParameters();

  const { companyId, userRole, validSubscription } = useAuth();

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  // Filters
  const [filters, setFilters] = useState<
    Array<{ filter: Filter; value: SelectionOption['value'] }>
  >([]);

  // Sorting
  const [order, setOrder] = useState<ListOrder>('asc');

  // Cases
  const [cases, setCases] = useState<Array<ReportCaseUiModel & { isSelected: boolean }>>();

  const [enabledCreateCase, setEnabledCreateCase] = useState(true);

  const isCaseSelected = useMemo(() => {
    return cases?.some((c) => c.isSelected);
  }, [cases]);

  useEffect(
    () => fetchCases(),
    [filters, order, companiesRepo.companies, companiesRepo.getCompanies],
  );

  const fetchCases = () => {
    const params: ReportCasesRequest = {
      order: order,
      filters: filters.map((f) => {
        return {
          id: f.filter.id,
          value: f.value.value!,
        };
      }),
    };
    getReportCases(params).then((reportCases) => {
      if (reportCases !== null) {
        setCases([
          ...reportCases.cases.map((reportCase) => {
            return { ...reportCase, isSelected: false };
          }),
        ]);
      }
    });
  };

  const handleCheck = (value: number, active: boolean) => {
    if (userRole !== null && isEnabledReportsOverviewMovesAction(userRole)) {
      cases &&
        setCases([
          ...cases.map((c) => {
            return {
              ...c,
              isSelected: c.isSelected
                ? !(value === -1 && !active) && c.id !== value
                : (value === -1 && active) || c.id === value,
            };
          }),
        ]);
    }
  };

  const openInvoice = (invoiceId: number) => {
    navigate(`/${companyId}/invoice/` + invoiceId);
  };

  function ReportsTableHeader(list) {
    return (
      <ReportsHeaderRowContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <ReportsColumnItem className={'checkbox'}>
          <Checkbox
            disabled={userRole !== null && isEnabledReportsOverviewMovesAction(userRole)}
            checkboxId={-1}
            onClick={handleCheck}
            active={list.cases.every((c) => c.isSelected)}
          />
        </ReportsColumnItem>
        <ReportsColumnItem className={'id'}>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.ID)}</Text>
        </ReportsColumnItem>
        <ReportsColumnItem className={'client'}>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.CLIENT)}</Text>
        </ReportsColumnItem>
        {!isTabletSmall && !isTabletLarge && (
          <ReportsColumnItem className={'cabinet'}>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.CABINET)}</Text>
          </ReportsColumnItem>
        )}
        <ReportsColumnItem className={'patient'}>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.PATIENT)}</Text>
        </ReportsColumnItem>
        {!isTabletSmall && (
          <ReportsColumnItem className={'collaborators'}>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.COLLABORATORS)}</Text>
          </ReportsColumnItem>
        )}
        <ReportsColumnItem
          className={`creation sorting-field ${order === 'desc' ? 'up' : 'down'}`}
          onClick={() => setOrder((prevState) => (prevState === 'asc' ? 'desc' : 'asc'))}
        >
          <Text type={TextType.CAPTION_BOLD}>
            {translate(LANGUAGE_KEYS.CREATION)} <SortIcon />
          </Text>
        </ReportsColumnItem>
        <ReportsColumnItem className={'caseState'}>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.CASE_STATE)}</Text>
        </ReportsColumnItem>
        <ReportsColumnItem className={'invoiceState'}>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.INVOICE_STATE)}</Text>
        </ReportsColumnItem>
      </ReportsHeaderRowContainer>
    );
  }

  function ReportsTableRows(items) {
    return (
      <>
        {items.cases.map((c, index) => (
          <ReportsRow
            companyId={items.comapnyId}
            disabled={items.disabled}
            key={index}
            model={c}
            CaseStates={CaseStates}
            onHandleCheck={handleCheck}
            invoiceEnabled={items.invoiceEnabled}
            onOpenInvoice={openInvoice}
          />
        ))}
      </>
    );
  }

  return (
    <Content>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.CASES)}
          enabledClick={
            userRole !== null && !isMobile ? isEnabledNewCaseFeatureAction(userRole) : false
          }
          buttonText={translate(LANGUAGE_KEYS.NEW_CASE)}
          onClick={() => {
            if (validSubscription) {
              if (enabledCreateCase) {
                setEnabledCreateCase(false);
                createCase((id) => {
                  navigate(`/${companyId}/case/` + id);
                  setEnabledCreateCase(true);
                });
              }
            } else {
              showPaymentExpireModal(userRole);
            }
          }}
          buttonIcon={<PlusIcon />}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.CASES)}
          enabledClick={
            userRole !== null && !isMobile ? isEnabledNewCaseFeatureAction(userRole) : false
          }
          buttonText={translate(LANGUAGE_KEYS.NEW_CASE)}
          onClick={() => {
            if (validSubscription) {
              if (enabledCreateCase) {
                setEnabledCreateCase(false);
                createCase((id) => {
                  navigate(`/${companyId}/case/` + id);
                  setEnabledCreateCase(true);
                });
              }
            } else {
              showPaymentExpireModal(userRole);
            }
          }}
          buttonIcon={<PlusIcon />}
        />
      )}
      <ContentLayout>
        <FilterContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <FilterBar
            onChange={(filters) => {
              setFilters(filters);
            }}
          />
        </FilterContainer>
        {isLoading || !cases ? (
          <LoadingContainer />
        ) : cases?.length === 0 ? (
          <NotFoundContainer />
        ) : (
          <div style={{ marginBottom: `${isCaseSelected ? '75px' : '0px'}` }}>
            {!isTabletLarge && !isTabletSmall && <ReportsTableHeader cases={cases} />}
            <ReportsTableRows
              cases={cases}
              disabled={userRole !== null && isEnabledReportsOverviewMovesAction(userRole)}
              comapnyId={companyId}
              invoiceEnabled={userRole !== null && isEnabledInvoice(userRole)}
            />
          </div>
        )}
      </ContentLayout>
      {isCaseSelected && (
        <BottomContainer>
          <Button
            style={{ width: 'auto' }}
            onClick={() => {
              if (validSubscription) {
                navigate(`/${companyId}/reports/reportsoverview`, {
                  state: {
                    cases: cases?.filter((c) => c.isSelected).map((item) => item.id),
                    type: ReportsOverviewType.EXPENSE,
                  },
                });
              } else {
                showPaymentExpireModal(userRole);
              }
            }}
          >
            {translate(LANGUAGE_KEYS.OVERVIEW)}
          </Button>
        </BottomContainer>
      )}
    </Content>
  );
}

const Content = styled.div`
  position: relative;
`;

const FilterContainer = styled(Column)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    width: 100%;
    align-items: stretch;
    gap: 8px;
    margin-bottom: 24px;

    //Desktop
    @media (min-width: ${desktop}px) {
      margin-top: 0px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      margin-top: 10px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const BaseContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-self: stretch;
    flex-grow: 0;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      gap: 16px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ReportsHeaderRowContainer = styled(BaseContainer)`
  padding: 0px 24px;
  margin-bottom: 8px;
`;

const ReportsColumnItem = styled.div`
  &.checkbox {
    min-width: 24px;
    max-width: 24px;
    text-align: center;
    .checkbox {
      margin: auto;
    }
  }
  &.id {
    min-width: 60px;
    max-width: 60px;
  }
  &.client,
  &.cabinet,
  &.patient {
    min-width: 160px;
    flex-grow: 1;
    flex-basis: 0;
  }
  &.collaborators {
    min-width: 204px;
    max-width: 204px;
    &.list {
      display: flex;
      align-items: center;
      boxsizing: border-box;
      margin-right: -4px;
    }
  }
  &.creation {
    min-width: 72px;
    max-width: 72px;
    p {
      display: inline-flex;
      gap: 4px;
      align-items: center;
      cursor: pointer;
    }
    &.up svg {
      transform: rotate(180deg) !important;
    }
  }
  &.caseState {
    min-width: 82px;
    max-width: 82px;
  }
  &.invoiceState {
    min-width: 83px;
    max-width: 83px;
    display: flex;
    gap: 4px;
    align-items: center;
    P {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      align-items: center;
    }
    svg {
      width: 10px;
      height: 10px;
      fill: var(--dts_primary);
    }
  }
`;

const BottomContainer = styled(Row)`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 85px;
  right: 0;
  bottom: 0;
  padding: 0 24px;
  background-color: var(--dts_white);
  justify-content: end;
  box-shadow: -1px 2px 11px 0 rgba(0, 0, 0, 0.14);
`;

export default Reports;
