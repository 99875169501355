import React from 'react';
import {
  getDesktopMenuUseCase,
  getMobileMenuUseCase,
  getProfileMenuUseCase,
  getTabletMenuUseCase,
} from '../../../useCases/menu/getMenuUseCase';
import { logoutUserUseCases } from '../../../useCases/authenticationFlow/logoutUserUseCases';
import { FeaturesRepository } from '../../../domain/features/FeaturesRepository';
import { AuthRepository } from '../../../domain/authenticationFlow/AuthRepository';

function useMenuViewModel(authRepo: AuthRepository, featuresRepo: FeaturesRepository) {
  const getDesktopMenu = (
    userRole: number | null,
    companyName: string | null,
    companyId: number | null,
  ) => {
    return getDesktopMenuUseCase(featuresRepo, userRole, companyName, companyId);
  };

  const getTabletMenu = (
    userRole: number | null,
    companyName: string | null,
    companyId: number | null,
  ) => {
    return getTabletMenuUseCase(featuresRepo, userRole, companyName, companyId);
  };

  const getMobileMenu = (
    userRole: number | null,
    companyName: string | null,
    companyId: number | null,
  ) => {
    return getMobileMenuUseCase(featuresRepo, userRole, companyName, companyId);
  };

  const getProfileMenu = (companyId: number | null) => {
    return getProfileMenuUseCase(
      featuresRepo,
      authRepo.name,
      authRepo.profileColor !== null ? authRepo.profileColor : 'var(--dts_default_blue)',
      companyId,
    );
  };

  const logoutUser = React.useCallback(
    function () {
      return logoutUserUseCases({ logoutUser: authRepo.logoutUser });
    },
    [authRepo.handleLogin],
  );

  return {
    getDesktopMenu,
    getTabletMenu,
    getMobileMenu,
    getProfileMenu,
    logoutUser,
  };
}

export { useMenuViewModel };
