import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

type resetPasswordUseCasesRepository = Pick<AuthRepository, 'resetPassword'>;

const resetPasswordUseCases = (repo: resetPasswordUseCasesRepository, username: string) => {
  return repo.resetPassword(username);
};

export { resetPasswordUseCases };
export type { resetPasswordUseCasesRepository };
