import React, { FC } from 'react';
import { Text, TextType } from '../components/dsm/Text';
import styled from 'styled-components';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';

export type DashboardEmptyCardComponentProps = {
  onClick: () => void;
};

export const DashboardEmptyCardComponent: FC<DashboardEmptyCardComponentProps> = (
  dashboardEmptyCardProps,
) => {
  const translate = useTranslate();

  const { onClick } = dashboardEmptyCardProps;

  return (
    <DashboardCardContainer onClick={onClick}>
      <CardContainer>
        <PlusIcon width={17} height={16} />
        <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.NEW_CASE)}</Text>
      </CardContainer>
    </DashboardCardContainer>
  );
};

const DashboardCardContainer = styled.div<{ error?: string }>`
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 14px 14px 10px 14px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 3px;
  display: flex;
  gap: 16px;
  min-height: 184px;
  justify-content: center;
  @media (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 601px) {
    flex: 0 0 calc(50% - ((20 / 2) * 1px));
    max-width: calc(50% - ((20 / 2) * 1px));
  }

  @media (min-width: 961px) {
    flex: 0 0 calc(33.333% - ((20 / 3) * 2px));
    max-width: calc(33.333% - ((20 / 3) * 2px));
  }

  @media (min-width: 1025px) {
    flex: 0 0 calc(25% - ((20 / 4) * 3px));
    max-width: calc(25% - ((20 / 4) * 3px));
  }

  @media (min-width: 1441px) {
    flex: 0 0 calc(20% - ((20 / 5) * 4px));
    max-width: calc(20% - ((20 / 5) * 4px));
  }

  &:hover {
    border: 2px solid var(--dts_default_blue);
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: center;
  p {
    color: var(--dts_default_blue);
  }
  svg {
    path {
      fill: var(--dts_default_blue);
    }
  }
`;
