import { BASE_URL } from '../../Server';
import { ExtensionData } from '../../../domain/worktype/extension/Extension';
import UpdateWorkTypeExtensionInfo from '../../../domain/worktype/extension/UpdateWorkTypeExtensionInfo';
import { fetchWithAuthAndCompanyId } from '../../utils/fetchWithAuthAndCompanyId';

const getExtensions = async (): Promise<ExtensionData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/extension', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return { code: 200, message: 'Success', data: data };
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateWorkTypeExtensionInfo = async (extension: UpdateWorkTypeExtensionInfo) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/updateWorkTypeExtensionInfo', {
      method: 'POST',
      body: JSON.stringify(extension),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updateTeethWorkTypeExtensionInfo = async (extension: UpdateWorkTypeExtensionInfo) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/updateTeethTypeExtensionInfo', {
      method: 'POST',
      body: JSON.stringify(extension),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export { updateWorkTypeExtensionInfo, updateTeethWorkTypeExtensionInfo, getExtensions };
