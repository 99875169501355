import React, { useEffect, useState } from 'react';
import { SearchAndAddNew } from '../../components/dsm/SearchAndAddNew';
import { PhaseRow } from './components/PhaseRow';
import PhaseRowHeader from './components/PhaseRowHeader';
import EmptyView from '../../components/generic/EmptyView';
import { PhaseLibraryModel } from '../../ui-model/PhaseLibraryModel';
import { usePhaseRepositoryImplementation } from '../../../data/phase/phasesRepositoryImplementation';
import { usePhasesViewModel } from './PhasesViewModel';
import DesktopHeader from '../../components/generic/DesktopHeader';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import ContentLayout from '../../components/layout/ContentLayout';
import ToggleHeader from '../../components/generic/ToggleHeader';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import SimpleConfirmationDialog from '../../components/generic/SimpleConfirmationDialog';

type ConfirmationDialog = OpenConfirmationDialog | ClosedConfirmationyDialog;

type OpenConfirmationDialog = {
  kind: 'Open';
  value: any;
  description: string;
  firstButtonText: string;
  secondButtonText: string;
  onHandleDelete: (value: any) => void;
  onClose: () => void;
};

type ClosedConfirmationyDialog = { kind: 'Closed' };

function Phases() {
  const translate = useTranslate();
  const PhasesRepo = usePhaseRepositoryImplementation();

  const { allPhase, getSortPhases, newPhase, deletePhase, updatePhase } =
    usePhasesViewModel(PhasesRepo);
  const { isTabletLarge, isTabletSmall, isMobile } = useDeviceParameters();

  const [phases, setPhases] = useState<PhaseLibraryModel[]>(getSortPhases());

  useEffect(() => {
    setPhases(getSortPhases());
  }, [allPhase]);

  // If we not use a copy list of PhasesCopy then in case of drag and drop, the elements will jump
  const [phasesCopy, setPhasesCopy] = useState<PhaseLibraryModel[] | null>(phases);
  const [usePhasesCopy, setUsePhasesCopy] = useState<boolean>(false);

  const emptyPhase = new PhaseLibraryModel(
    phases !== null ? phases.length : 0,
    translate(LANGUAGE_KEYS.PHASE),
  );

  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialog>({
    kind: 'Closed',
  });

  const closeDeleteConfirmationDialog = () => {
    setConfirmationDialog({
      kind: 'Closed',
    });
  };

  const addNewPhase = () => {
    newPhase(emptyPhase.name);
  };

  const onDeletePhase = (p: PhaseLibraryModel) => {
    setConfirmationDialog({
      kind: 'Open',
      value: p.id,
      description: translate(LANGUAGE_KEYS.CONFIRM_DELETE_PHASES_TEXT),
      firstButtonText: translate(LANGUAGE_KEYS.CANCEL),
      secondButtonText: translate(LANGUAGE_KEYS.DELETE),
      onHandleDelete: deletePhase,
      onClose: closeDeleteConfirmationDialog,
    });
  };

  const onHandleText = (phase: PhaseLibraryModel, name: string) => {
    phase.name = name;
    setConfirmationDialog({
      kind: 'Open',
      value: phase,
      description: translate(LANGUAGE_KEYS.CONFIRM_EDIT_PHASES_TEXT),
      firstButtonText: translate(LANGUAGE_KEYS.CANCEL),
      secondButtonText: translate(LANGUAGE_KEYS.EDIT),
      onHandleDelete: updatePhase,
      onClose: closeDeleteConfirmationDialog,
    });
  };

  const onHandleChangeSearchBar = (e) => {
    if (e.target.value.length <= 0) {
      setUsePhasesCopy(false);
    } else {
      if (phases !== null) {
        const list: PhaseLibraryModel[] = phases.filter((item) => {
          if (item.name.toUpperCase().includes(e.target.value.toUpperCase())) {
            return item;
          }
        });
        setUsePhasesCopy(true);
        setPhasesCopy(list);
      }
    }
  };

  const onClickSearchClose = () => {
    setUsePhasesCopy(false);
  };

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={false}
          title={'Phases'}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.PHASES)}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <SearchAndAddNew
          handleChangeSearchBar={onHandleChangeSearchBar}
          clickSearchClose={onClickSearchClose}
          addNewText={translate(LANGUAGE_KEYS.ADD_NEW_PHASE)}
          addNew={addNewPhase}
          isWorker={false}
        />
        {phases !== null && phases.length > 0 ? (
          <>
            <PhaseRowHeader />
            <div>
              <PhasesList
                phasesList={usePhasesCopy && phasesCopy !== null ? phasesCopy : phases}
                onDeletePhase={onDeletePhase}
                onHandleText={onHandleText}
              />
            </div>
            {confirmationDialog.kind === 'Open' && (
              <SimpleConfirmationDialog
                modalName=''
                description={confirmationDialog.description}
                firstButtonText={confirmationDialog.firstButtonText}
                secondButtonText={confirmationDialog.secondButtonText}
                value={confirmationDialog.value}
                onHandleAction={confirmationDialog.onHandleDelete}
                onClose={confirmationDialog.onClose}
              />
            )}
          </>
        ) : (
          <EmptyView allHeightOtherItems={240} />
        )}
      </ContentLayout>
    </>
  );
}

function PhasesList(props: {
  phasesList: PhaseLibraryModel[] | null;
  onDeletePhase: (Phase: PhaseLibraryModel) => void;
  onHandleText: (Phase: PhaseLibraryModel, name: string) => void;
}) {
  return (
    <>
      {props.phasesList !== null &&
        buildPhasesList(
          props.phasesList.filter((value) => {
            return value;
          }),
          props.onDeletePhase,
          props.onHandleText,
        )}
    </>
  );
}

function buildPhasesList(
  PhasesList: PhaseLibraryModel[] | null,
  onDeletePhase: (Phase: PhaseLibraryModel) => void,
  onHandleText: (Phase: PhaseLibraryModel, name: string) => void,
) {
  const PhasesRowView: any[] = [];
  PhasesList?.map(function (t, i) {
    PhasesRowView.push(
      <PhaseRow
        PhaseModel={t}
        index={i}
        key={t.id}
        onDeletePhase={onDeletePhase}
        onHandleText={onHandleText}
      />,
    );
  });
  return PhasesRowView;
}

export default Phases;
