import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

type addUserUseCasesRepository = Pick<AuthRepository, 'addUser'>;

const addUserUseCases = (
  repo: addUserUseCasesRepository,
  username: string,
  isEnabledMailList: boolean,
) => {
  return repo.addUser(username, isEnabledMailList);
};

export { addUserUseCases };
export type { addUserUseCasesRepository };
