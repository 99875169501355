import { BASE_URL } from '../Server';
import {
  CompanySubscriptiondata,
  MaxPackageCollaborators,
  Package,
  PackageModelResponse,
  TrialEnable,
  UpdatepackageResponse,
} from '../../domain/billing/Package';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';
import { fetchWithAuth } from '../utils/fetchWithAuth';

const getPackages = async (): Promise<PackageModelResponse> => {
  try {
    const response = await fetchWithAuth(BASE_URL + '/packages', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: [] };
    }
  } catch (error) {
    return { code: error.status, message: error, data: [] };
  }
};

const updateCompanyPackage = async (params: Package): Promise<UpdatepackageResponse> => {
  try {
    const response = await fetchWithAuth(BASE_URL + `/updateCompanyPackage`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: false };
    }
  } catch (error) {
    return { code: error.status, message: error, data: false };
  }
};

const getMaxCollaborators = async (): Promise<MaxPackageCollaborators> => {
  try {
    const response = await fetchWithAuth(BASE_URL + '/getMaxBasicCollaborators', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: -1 };
    }
  } catch (error) {
    return { code: error.status, message: error, data: -1 };
  }
};

const getCompanySubscription = async (): Promise<CompanySubscriptiondata> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/companySubscription', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const getTrialEnable = async (): Promise<TrialEnable> => {
  try {
    const response = await fetchWithAuth(BASE_URL + '/trialEnable', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: false };
    }
  } catch (error) {
    return { code: error.status, message: error, data: false };
  }
};

export {
  getPackages,
  updateCompanyPackage,
  getMaxCollaborators,
  getCompanySubscription,
  getTrialEnable,
};
