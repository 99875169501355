import React from 'react';
import { TemplateRepository } from '../../../../domain/worktype/templates/TemplateRepository';
import {
  getTemplatesUseCases,
  getSortTemplatesUseCase,
} from '../../../../useCases/worktype/templates/getTemplatesUseCases';
import { newTemplateUseCase } from '../../../../useCases/worktype/templates/newTemplateUseCase';
import { duplicateTemplateUseCase } from '../../../../useCases/worktype/templates/duplicateTemplateUseCase';
import { deleteTemplateUseCase } from '../../../../useCases/worktype/templates/deleteTemplateUseCase';
import {
  updateTemplateUseCase,
  updateTemplateOrderOrActiveUseCase,
} from '../../../../useCases/worktype/templates/updateTemplatesUseCase';
import { addTemplateBySubWorkTypeUseCase } from '../../../../useCases/worktype/templates/addTemplateBySubWorkTypeUseCase';
import { removeTemplateFromSubWorkTypeUseCase } from '../../../../useCases/worktype/templates/removeTemplateFromSubWorkTypeUseCase';
import { TemplateUiModel } from '../../../ui-model/worktype/TemplateUiModel';
import { TemplatePhaseUiModel } from '../../../ui-model/worktype/TemplatePhaseUiModel';
import { NewTemplate } from '../../../../domain/worktype/templates/NewTemplate';
import { UpdateTemplate } from '../../../../domain/worktype/templates/UpdateTemplate';
import { UpdateTemplateOrderOrActive } from '../../../../domain/worktype/templates/UpdateTemplateOrderOrActive';
import { TemplateBySubWorkType } from '../../../../domain/worktype/templates/TemplateBySubWorkType';
import { WorkPhaseGroup } from '../../../../domain/WorkPhaseGroup';

function useTemplatesViewModel(repo: TemplateRepository) {
  const getTemplates = React.useCallback(
    function () {
      getTemplatesUseCases({
        getTemplates: repo.getTemplates,
      });
    },
    [repo.getTemplates],
  );

  const getTemplatesByWorkTypeId = (id: number): TemplateUiModel[] | [] => {
    const workTypeTemplates: TemplateUiModel[] = [];
    repo.templates
      ?.filter((template) => template.workTypeId === id)
      .forEach((template) => {
        workTypeTemplates.push(
          new TemplateUiModel(
            template.id,
            template.name,
            template.order === null ? 0 : template.order,
            template.active === null ? 0 : template.active,
            template.phases !== null ? template.phases.length : 0,
            template.phases !== null
              ? getTemplatePhasesToTemplatePhaseUiModel(template.phases)
              : [],
            template.workTypeId!,
          ),
        );
      });
    return workTypeTemplates;
  };

  const getSortTemplatesByWorkTypeId = (id: number): TemplateUiModel[] | [] => {
    return getSortTemplatesUseCase(getTemplatesByWorkTypeId(id));
  };

  const newTemplate = React.useCallback(
    function (newTemplateItem: { name: string; workTypeId: number; phases: number[] }) {
      newTemplateUseCase(
        repo,
        new NewTemplate(newTemplateItem.name, newTemplateItem.workTypeId, newTemplateItem.phases),
      );
    },
    [repo.newTemplate],
  );

  const duplicateTemplate = React.useCallback(
    function (id: number) {
      duplicateTemplateUseCase(repo, id);
    },
    [repo.duplicateTemplate],
  );

  const deleteTemplate = React.useCallback(
    function (id: number) {
      deleteTemplateUseCase(repo, id);
    },
    [repo.deleteTemplate],
  );

  const updateTemplate = React.useCallback(
    function (template: TemplateUiModel) {
      updateTemplateUseCase(repo, new UpdateTemplate(template.id, template.name));
    },
    [repo.updateTemplate],
  );

  const updateTemplateOrderOrActive = React.useCallback(
    function (templates: TemplateUiModel[]) {
      updateTemplateOrderOrActiveUseCase(repo, getUpdateTemplateOrderOrActiveModelList(templates));
    },
    [repo.updateTemplateOrderOrActive],
  );

  const addTemplateBySubWorkType = React.useCallback(
    function (templateBySubWorkType: {
      workTypeId: number;
      groupId: number;
      extensionId: number;
      templateId: number;
    }) {
      addTemplateBySubWorkTypeUseCase(
        repo,
        new TemplateBySubWorkType(
          templateBySubWorkType.workTypeId,
          templateBySubWorkType.groupId,
          templateBySubWorkType.extensionId,
          templateBySubWorkType.templateId,
        ),
      );
    },
    [repo.addTemplateBySubWorkType],
  );

  const removeTemplateFromSubWorkType = React.useCallback(
    function (templateId: number, extensionId: number | null) {
      removeTemplateFromSubWorkTypeUseCase(repo, templateId, extensionId);
    },
    [repo.addTemplateBySubWorkType],
  );

  const addPhasesToTemplate = React.useCallback(
    function (newPhases: { templateId: number; templateName: string; phases: number[] }) {
      updateTemplateUseCase(
        repo,
        new UpdateTemplate(newPhases.templateId, newPhases.templateName, newPhases.phases),
      );
    },
    [repo.updateTemplate],
  );

  const removePhasesFromTemplate = React.useCallback(
    function (deletePhase: { templateId: number; templateName: string; phases: number[] }) {
      updateTemplateUseCase(
        repo,
        new UpdateTemplate(deletePhase.templateId, deletePhase.templateName, deletePhase.phases),
      );
    },
    [repo.updateTemplate],
  );

  // Functions

  function getUpdateTemplateOrderOrActiveModelList(
    templates: TemplateUiModel[],
  ): UpdateTemplateOrderOrActive[] {
    return templates.map((template) => {
      return new UpdateTemplateOrderOrActive(template.active, template.id, template.order);
    });
  }

  function getTemplatePhasesToTemplatePhaseUiModel(
    phases: WorkPhaseGroup[],
  ): TemplatePhaseUiModel[] {
    return phases.map((phase) => {
      return new TemplatePhaseUiModel(
        phase.type,
        phase.order,
        phase.id,
        phase.name,
        phase.status,
        phase.assigneeId,
        phase.date,
        phase.id,
      );
    });
  }

  return {
    templates: repo.templates,
    isLoading: repo.isLoading,
    isUpdating: repo.isUpdating,
    getTemplates,
    getTemplatesByWorkTypeId,
    getSortTemplatesByWorkTypeId,
    newTemplate,
    duplicateTemplate,
    deleteTemplate,
    updateTemplate,
    updateTemplateOrderOrActive,
    addTemplateBySubWorkType,
    removeTemplateFromSubWorkType,
    addPhasesToTemplate,
    removePhasesFromTemplate,
  };
}

export { useTemplatesViewModel };
