import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../components/dsm/Text';
import InputField from '../../components/dsm/InputField';
import FormAction from '../components/FormAction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSignUpFlowViewModel } from './SignUpFlowViewModel';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { AlertType } from '../../../domain/alert/AlertManager';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { MobileBackButton } from '../../components/generic/MobileBackButton';
import { DeviceSizeProps } from '../../DeviceInformations';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';
import { useAuthRepositoryImplementation } from '../../../data/authenticationFlow/authRepositoryImplementation';

const SetPasswordFields = [
  {
    labelText: LANGUAGE_KEYS.PASSWORD,
    labelFor: 'password',
    id: 'password',
    name: 'password',
    type: 'password',
    autoComplete: 'new-password',
    isRequired: true,
    placeholder: LANGUAGE_KEYS.PASSWORD,
  },
  {
    labelText: LANGUAGE_KEYS.CONFIRMATION,
    labelFor: 'confirmation',
    id: 'confirmation',
    name: 'confirmation',
    type: 'password',
    autoComplete: 'new-password',
    isRequired: true,
    placeholder: LANGUAGE_KEYS.PASSWORD,
  },
];

const SetPassword = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const location = useLocation();
  const { userIdentifier } = useParams();

  const authRepo = useAuthRepositoryImplementation();
  const { setPassword } = useSignUpFlowViewModel(authRepo);

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const fields = SetPasswordFields;
  const fieldsState = {};
  fields.forEach((field) => (fieldsState[field.id] = ''));
  const [passwordsState, setPasswordsState] = useState(fieldsState);
  const [title, setTitle] = useState('');
  const [information, setInformation] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.startsWith('/password/recover')) {
      setTitle(translate(LANGUAGE_KEYS.RESET_YOUR_PASSWORD));
    } else {
      setTitle(translate(LANGUAGE_KEYS.SET_YOUR_PASSWORD));
    }
    setInformation(translate(LANGUAGE_KEYS.PICK_A_PASSWORD));
  }, []);

  const handleChange = (e) => {
    setPasswordsState({ ...passwordsState, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const password = passwordsState['password'];
    const confirmation = passwordsState['confirmation'];
    if (password !== confirmation) {
      setInformation(translate(LANGUAGE_KEYS.ERROR_MESSAGE_PASSWROD_CONFIRMATION_DO_NOT_MATCH));
      setHasError(true);
    } else {
      if (userIdentifier !== undefined) {
        const isRecover = location.pathname.startsWith('/password/recover');
        setPassword(userIdentifier, password, isRecover).then((resp) => {
          if (resp.hasError) {
            showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
            return;
          }
          navigate('/');
          if (isRecover) {
            showAlert(translate(LANGUAGE_KEYS.ALERT_RESET_PASSWORD_SUCCESSFULL), AlertType.SUCCESS);
          }
        });
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
      }
      setHasError(false);
    }
  };

  return (
    <>
      {isMobile && <MobileBackButton />}
      <SetPasswordContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <ContentContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <TitleText type={TextType.TITLE_BOLD_2}>{title}</TitleText>
          <InformationText type={TextType.BODY_2} className={`${hasError && 'error'}`}>
            {information}
          </InformationText>
          <CustomForm>
            <InputFields>
              {SetPasswordFields.map((field) => {
                return (
                  <InputField
                    key={field.id}
                    handleChange={handleChange}
                    value={passwordsState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={field.placeholder}
                    error={false}
                    autoComplete={field.autoComplete}
                    strengthPassword={true}
                  />
                );
              })}
            </InputFields>
          </CustomForm>
          <FormAction
            handleSubmit={handleSubmit}
            text={translate(LANGUAGE_KEYS.SET_PASSWORD)}
            action='submit'
            type='Button'
            disabled={null}
          />
        </ContentContainer>
      </SetPasswordContainer>
    </>
  );
};

const SetPasswordContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: ${desktop}px) {
      height: calc(100% - 68px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ContentContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 288px;
    min-width: 288px;
    margin-top: 68px;
    //Desktop
    @media (min-width: ${desktop}px) {
      margin-top: 68px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-top: 20px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomForm = styled.form`
  margin-bottom: 24px;
`;

const TitleText = styled(Text)`
  margin-bottom: 24px;
`;
const InformationText = styled(Text)`
  text-align: left;
  margin-bottom: 24px;
  &.error {
    color: var(--dts_red);
  }
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
`;

export default SetPassword;
