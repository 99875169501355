import type { AnyAction } from 'redux';
import * as actionTypes from './invoiceActionTypes';
import { InvoiceRepository } from '../../domain/invoice/InvoiceRepository';
import * as generalActionTypes from '../generalActionTypes';

type InvoiceRepositoryState = Omit<
  InvoiceRepository,
  'getInvoices' | 'getInvoiceById' | 'updateInvoice' | 'checkInvoiceNumber' | 'deleteInvoice'
>;

const INITIAL_STATE: InvoiceRepositoryState = {
  invoices: null,
  isLoading: false,
  isUpdating: false,
};

const invoiceReducer = (state: InvoiceRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_INVOICES:
      return { ...state, isLoading: true };
    case actionTypes.GET_INVOICES_SUCCESS:
      return { ...state, isLoading: false, invoices: action.invoices };
    case actionTypes.GET_INVOICES_FAILD:
      return { ...state, isLoading: false };
    case actionTypes.GET_INVOICE_BY_ID:
      return { ...state, isLoading: true };
    case actionTypes.GET_INVOICE_BY_ID_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GET_INVOICE_BY_ID_FAILD:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_INVOICE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_INVOICE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_INVOICE_FAILD:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_INVOICES:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_INVOICES_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_INVOICES_FAILD:
      return { ...state, isLoading: false };
    case generalActionTypes.RESET_LABOR_DATA:
      return { ...state, invoices: null, isLoading: false, isUpdating: false };
    default:
      return state;
  }
};
export default invoiceReducer;
export type { InvoiceRepositoryState };
