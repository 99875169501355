import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ReportRepository } from '../../domain/reports/ReportRepository';
import {
  getReportCasesAction,
  getReportCasesByIdsAction,
  accountPhasesAction,
} from './reportActions';
import { ReportRepositoryState } from './reportReducer';
import { ReportCasesRequest } from '../../domain/reports/Report';
import { SolvedPayOff } from '../../domain/reports/PayOff';
import { RootState } from '../utils/store';

const reportSelector = (state: RootState) => state.report;

const useReportRepositoryImplementation = (): ReportRepository => {
  const { reportCases, isLoading } = useSelector<RootState, ReportRepositoryState>(reportSelector);

  const dispatch = useDispatch();

  const getReportCases = React.useCallback(
    (params: ReportCasesRequest) => getReportCasesAction(params)(dispatch),
    [dispatch],
  );

  const getReportCasesByIds = React.useCallback(
    async (param: Array<number>) => {
      const result = await getReportCasesByIdsAction(param)(dispatch);
      return result;
    },
    [dispatch],
  );

  const accountPhases = React.useCallback(
    (param: SolvedPayOff) => accountPhasesAction(param)(dispatch),
    [dispatch],
  );

  return {
    reportCases,
    isLoading,
    getReportCases,
    getReportCasesByIds,
    accountPhases,
  };
};

export { useReportRepositoryImplementation };
