import React from 'react';
import { RootState } from '../../data/utils/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContactsRepository,
  CompaniesRepository,
} from '../../domain/user-management/UsersRepository';
import {
  UpdateClient,
  SendWorkerInvitation,
  UpdateWorker,
  AnswerWorkerInvitation,
} from '../../domain/user-management/Contact';
import { NewCompany } from '../../domain/user-management/NewCompany';
import { ContactsRepositoryState, CompaniesRepositoryState } from './usersReducer';
import {
  getContactAction,
  updateClientAction,
  updateWorkerAction,
  sendWorkerInvitationAction,
  deleteContactAction,
  getCompaniesAction,
  getMyCompanyDataAction,
  newCompanyAction,
  updateCompanyAction,
  updateMyCompanyDataAction,
  deleteCompanyAction,
  createCompanyAction,
  answerWorkerInvitationAction,
} from './usersActions';
import { Plan } from '../../domain/user-management/Plans';

const contactsSelector = (state: RootState) => state.contacts;
const companiesSelector = (state: RootState) => state.companies;

const useContactsRepositoryImplementation = (): ContactsRepository => {
  const { contacts, isLoading, isUpdating } = useSelector<RootState, ContactsRepositoryState>(
    contactsSelector,
  );

  const dispatch = useDispatch();

  const getContacts = React.useCallback(() => getContactAction()(dispatch), [dispatch]);

  const updateClient = React.useCallback(
    (client: UpdateClient) => updateClientAction(client)(dispatch),
    [dispatch],
  );

  const updateWorker = React.useCallback(
    (worker: UpdateWorker) => updateWorkerAction(worker)(dispatch),
    [dispatch],
  );

  const sendWorkerInvitation = React.useCallback(
    (invitationData: SendWorkerInvitation) => sendWorkerInvitationAction(invitationData)(dispatch),
    [dispatch],
  );

  const answerWorkerInvitation = React.useCallback(
    (answerInv: AnswerWorkerInvitation) => answerWorkerInvitationAction(answerInv)(dispatch),
    [dispatch],
  );

  const deleteContact = React.useCallback(
    (id: number) => deleteContactAction(id)(dispatch),
    [dispatch],
  );

  return {
    contacts,
    isLoading,
    isUpdating,
    getContacts,
    updateClient,
    updateWorker,
    answerWorkerInvitation,
    sendWorkerInvitation,
    deleteContact,
  };
};

const useCompaniesRepositoryImplementation = (): CompaniesRepository => {
  const { companies, myCompanyEtoken, isLoading, isUpdating } = useSelector<
    RootState,
    CompaniesRepositoryState
  >(companiesSelector);

  const dispatch = useDispatch();

  const getCompanies = React.useCallback(() => getCompaniesAction()(dispatch), [dispatch]);

  const getMyCompanyData = React.useCallback(() => getMyCompanyDataAction()(dispatch), [dispatch]);

  const newCompany = React.useCallback(
    (newCompany: NewCompany) => newCompanyAction(newCompany)(dispatch),
    [dispatch],
  );
  const deleteCompany = React.useCallback(
    (id: number) => deleteCompanyAction(id)(dispatch),
    [dispatch],
  );

  const updateCompany = React.useCallback(
    (updateContact: NewCompany) => updateCompanyAction(updateContact)(dispatch),
    [dispatch],
  );

  const updateMyCompanyData = React.useCallback(
    (updateContact: NewCompany) => updateMyCompanyDataAction(updateContact)(dispatch),
    [dispatch],
  );

  const createCompany = React.useCallback(
    (plan: Plan) => createCompanyAction(plan)(dispatch),
    [dispatch],
  );

  return {
    companies,
    myCompanyEtoken,
    isLoading,
    isUpdating,
    getCompanies,
    getMyCompanyData,
    newCompany,
    updateCompany,
    updateMyCompanyData,
    deleteCompany,
    createCompany,
  };
};

export { useContactsRepositoryImplementation, useCompaniesRepositoryImplementation };
