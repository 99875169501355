import { navigateToLogin } from './navigateToLogin';
import store from './store';
import * as actionTypes from '../authenticationFlow/authActionTypes';
import { saveLastUrl } from './saveLastUrl';

export const fetchWithAuth = async (url: string, options?: RequestInit) => {
  const state = store.getState();
  const token = state.auth.token;

  const headers = {
    ...options?.headers,
    Authorization: `${token}`,
    'Access-Control-Request-Headers': 'X-Custom-Header',
    'Content-Type': 'application/json',
  };

  const response = await fetch(url, {
    ...options,
    headers: headers,
  });

  if (response.status === 401) {
    if (saveLastUrl()) {
      navigateToLogin();
      store.dispatch(actionTypes.logout());
      throw new Error('Unauthorized');
    }
  }

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response;
};
