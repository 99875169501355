import React from 'react';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';
import { logoutUserUseCases } from '../../useCases/authenticationFlow/logoutUserUseCases';

function useErrorPageViewModel(authRepo: AuthRepository) {
  const logoutUser = React.useCallback(
    function () {
      return logoutUserUseCases({ logoutUser: authRepo.logoutUser });
    },
    [authRepo.handleLogin],
  );

  return {
    logoutUser,
  };
}

export { useErrorPageViewModel };
