import { useDispatch, useSelector } from 'react-redux';
import { CaseRepository } from '../../domain/CaseRepository';
import React from 'react';
import { CaseRepositoryState } from './caseReducer';
import {
  getCaseByIdAction,
  getCasesAction,
  newCaseAction,
  uploadCaseDetailActions,
  deleteCaseActions,
  closeReopenCaseActions,
} from './caseActions';
import { RootState } from '../utils/store';

const caseSelector = (state: RootState) => state.cases;

const useCaseRepositoryImplementation = (): CaseRepository => {
  const { cases, allCase, isLoading, isUpdating, error } = useSelector<
    RootState,
    CaseRepositoryState
  >(caseSelector);

  const dispatch = useDispatch();

  const getCases = React.useCallback(() => getCasesAction()(dispatch), [dispatch]);
  const getCaseById = React.useCallback(
    (caseId: number) => getCaseByIdAction(caseId)(dispatch),
    [dispatch],
  );
  const newCase = React.useCallback(
    (onSuccess: (id: number) => void) => newCaseAction(onSuccess)(dispatch),
    [dispatch],
  );

  const updateCase = React.useCallback(
    (caseId: number, stackOfChanges: string[][]) =>
      uploadCaseDetailActions(caseId, stackOfChanges)(dispatch),
    [dispatch],
  );

  const deleteCases = React.useCallback(
    (deletedCases: Array<number>) => deleteCaseActions(deletedCases)(dispatch),
    [dispatch],
  );

  const closeReopenCase = React.useCallback(
    (caseId: number, stackOfChanges: string[][]) =>
      closeReopenCaseActions(caseId, stackOfChanges)(dispatch),
    [dispatch],
  );

  return {
    cases,
    allCase,
    isLoading,
    isUpdating,
    error,
    getCases,
    getCaseById,
    newCase,
    updateCase,
    deleteCases,
    closeReopenCase,
  };
};

export { useCaseRepositoryImplementation };
