import { ContactsRepository } from '../../domain/user-management/UsersRepository';

type GetContactsUseCase = Pick<ContactsRepository, 'contacts' | 'getContacts'>;

const getContactsUseCase = (repo: GetContactsUseCase) => {
  repo.getContacts();
};

const getWorkersUseCase = (repo: GetContactsUseCase) => {
  if (repo.contacts !== null && repo.contacts?.workers !== null) {
    return repo.contacts?.workers;
  } else {
    return [];
  }
};

const getClientsUseCase = (repo: GetContactsUseCase) => {
  if (repo.contacts !== null && repo.contacts.clients !== null) {
    return repo.contacts.clients;
  } else {
    return [];
  }
};

const getClientNameUseCase = (id: number, repo: GetContactsUseCase): string => {
  if (repo.contacts !== null) {
    const client = repo.contacts.clients.find((client) => client.id === id);
    if (client === undefined) return '';
    return client !== null && client !== null && client.name !== null ? client.name : '';
  }
  return '';
};

const getWorkertNameUseCase = (id: number, repo: GetContactsUseCase): string => {
  if (repo.contacts !== null) {
    const worker = repo.contacts.workers.find((client) => client.id === id);
    if (worker === undefined) return '';
    return worker !== null && worker !== null && worker.name !== null ? worker.name : '';
  }
  return '';
};

const getWorkertProfileColorUseCase = (id: number, repo: GetContactsUseCase): string => {
  if (repo.contacts !== null) {
    const worker = repo.contacts.workers.find((client) => client.id === id);
    if (worker === undefined) return 'var(--dts_default_blue)';
    return worker !== null && worker.user !== null && worker.user.profileColor !== null
      ? worker.user.profileColor
      : 'var(--dts_default_blue)';
  }
  return 'var(--dts_default_blue)';
};

export {
  getContactsUseCase,
  getWorkersUseCase,
  getClientsUseCase,
  getClientNameUseCase,
  getWorkertNameUseCase,
  getWorkertProfileColorUseCase,
};
