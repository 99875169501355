import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ExtensionRepository } from '../../../domain/worktype/extension/ExtensionRepository';
import {
  getExtensionsAction,
  updateExtensionAction,
  updateExtensionTeethAction,
} from './extensionActions';
import { ExtensionRepositoryState } from './extensionReducer';
import UpdateWorkTypeExtensionInfo from '../../../domain/worktype/extension/UpdateWorkTypeExtensionInfo';
import { RootState } from '../../utils/store';

const extensionSelector = (state: RootState) => state.extension;

const useExtensionRepositoryImplementation = (): ExtensionRepository => {
  const { extensions, isLoading, isUpdating } = useSelector<RootState, ExtensionRepositoryState>(
    extensionSelector,
  );

  const dispatch = useDispatch();

  const getExtensions = React.useCallback(() => getExtensionsAction()(dispatch), [dispatch]);

  const updateExtension = React.useCallback(
    (extension: UpdateWorkTypeExtensionInfo) => updateExtensionAction(extension)(dispatch),
    [dispatch],
  );

  const updateTeethExtension = React.useCallback(
    (extension: UpdateWorkTypeExtensionInfo) => updateExtensionTeethAction(extension)(dispatch),
    [dispatch],
  );

  return {
    extensions,
    isLoading,
    isUpdating,
    getExtensions,
    updateExtension,
    updateTeethExtension,
  };
};

export { useExtensionRepositoryImplementation };
