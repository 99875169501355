import { BASE_URL } from '../Server';
import { WorkPhaseGroupData } from '../../domain/WorkPhaseGroupData';
import { UpdatePhase } from '../../domain/worktype/UpdatePhase';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';

const getPhases = async (): Promise<WorkPhaseGroupData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/phases', {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: [] };
    }
  } catch (error) {
    return { code: error.status, message: error, data: [] };
  }
};

const newPhase = async (name: string) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/addPhase', {
      method: 'POST',
      body: JSON.stringify({ name: name }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const deletePhase = async (id: number) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + `/phase/delete/${id}`, {
      method: 'GET',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

const updatePhase = async (phase: UpdatePhase) => {
  try {
    const response = await fetchWithAuthAndCompanyId(BASE_URL + '/phase/update', {
      method: 'POST',
      body: JSON.stringify(phase),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return { code: response.status, message: 'Faild', data: null };
    }
  } catch (error) {
    return { code: error.status, message: error, data: null };
  }
};

export { getPhases, newPhase, updatePhase, deletePhase };
