import React from 'react';

import { InvoiceRepository } from '../../domain/invoice/InvoiceRepository';
import { Invoice } from '../../domain/invoice/Invoice';
import { getInvoiceUseCase, getInvoiceByIdUseCase } from '../../useCases/invoice/getInvoiceUseCase';
import { InvoiceListItemUi, InvoiceUi, InvoiceCaseUi } from '../ui-model/invoice/InvoiceUiModel';
import { InvoiceUpdate } from '../ui-model/invoice/InvoiceUpdateUiModel';
import { updateInvoiceUseCase } from '../../useCases/invoice/updateInvoiceUseCase';
import { checkInvoiceNumberUseCase } from '../../useCases/invoice/checkInvoiceNumberUseCase';
import { getFormattedDate } from '../../data/DateFormatter';
import { getFeatureByIdUseCase } from '../../useCases/features/getFeatureUseCase';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { FeatureId } from '../../domain/features/Features';
import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';
import {
  getStatusAnafFacturaFromCacheUseCase,
  getStatusAnafFacturaUseCase,
} from '../../useCases/e-factura/getStatusAnafFacturaUseCase';
import { deleteInvoiceUseCase } from '../../useCases/invoice/deleteInvoiceUseCase';
import useTranslate from '../translations/useTranslate';

const useInvoiceViewModel = (
  repo: InvoiceRepository,
  featureRepo: FeaturesRepository,
  e_facturaRepo: E_facturaRepository,
) => {
  const translate = useTranslate();

  const getInvoices = React.useCallback(() => {
    return getInvoiceUseCase(repo).then((res) => {
      return {
        invoiedata: setInvoiceUiModel(res.invoiedata).sort((a, b) =>
          a.invoiceNumber < b.invoiceNumber ? 1 : -1,
        ),
        statusCode: res.statusCode,
      };
    });
  }, [repo.getInvoices]);

  const getInvoiceById = React.useCallback(
    (id: number) => {
      return getInvoiceByIdUseCase(repo, id).then((res) => {
        if (res !== null && res.invoiedata !== null) {
          return {
            invoiedata: setInvoiceUpdateUiModel(res.invoiedata),
            statusCode: res.statusCode,
          };
        } else {
          return {
            invoiedata: null,
            statusCode: null,
          };
        }
      });
    },
    [repo.getInvoiceById],
  );

  function setInvoiceUiModel(invoiceList: Array<Invoice>): Array<InvoiceListItemUi> {
    const invoiceUiList: Array<InvoiceListItemUi> = invoiceList.map((invoice) => {
      return {
        id: invoice.id,
        invoiceNumber: invoice.invoicePrefix + ' ' + invoice.invoiceNumber,
        clientCompany: invoice.clientCompany.name,
        creationDate: getFormattedDate(invoice.invoiceDate),
        invoiceState: invoice.status,
        caseIds: invoice.cases.map((c) => c.caseId),
        einvoiceId: invoice.einvoiceId,
      };
    });
    return invoiceUiList;
  }

  function setInvoiceUpdateUiModel(invoice: Invoice): InvoiceUi | null {
    if (invoice === null) {
      return null;
    }
    const uiCases: Array<InvoiceCaseUi> = [];
    let totalSum = 0;
    invoice.cases.forEach((c) => {
      totalSum += c.price;
      uiCases.push({
        caseId: c.caseId,
        price: c.price,
        labworksNames: c.labWorkInvoiceDao.map((labworkname) => translate(labworkname)).join('; '),
      });
    });

    let ownerCompanyAddress = '';
    invoice.ownerCompany.streetName !== null
      ? (ownerCompanyAddress = ownerCompanyAddress + invoice.ownerCompany.streetName + ' ')
      : '';
    invoice.ownerCompany.cityName !== null
      ? (ownerCompanyAddress = ownerCompanyAddress + invoice.ownerCompany.cityName + ' ')
      : '';
    invoice.ownerCompany.countrySubentity !== null
      ? (ownerCompanyAddress = ownerCompanyAddress + invoice.ownerCompany.countrySubentity + ' ')
      : '';
    invoice.ownerCompany.identificationCode !== null
      ? (ownerCompanyAddress = ownerCompanyAddress + invoice.ownerCompany.identificationCode + ' ')
      : '';

    let clientCompanyAddress = '';
    invoice.clientCompany.streetName !== null
      ? (clientCompanyAddress = clientCompanyAddress + invoice.clientCompany.streetName + ' ')
      : '';
    invoice.clientCompany.cityName !== null
      ? (clientCompanyAddress = clientCompanyAddress + invoice.clientCompany.cityName + ' ')
      : '';
    invoice.clientCompany.countrySubentity !== null
      ? (clientCompanyAddress = clientCompanyAddress + invoice.clientCompany.countrySubentity + ' ')
      : '';
    invoice.clientCompany.identificationCode !== null
      ? (clientCompanyAddress =
          clientCompanyAddress + invoice.clientCompany.identificationCode + ' ')
      : '';

    return {
      id: invoice.id,
      ownerCompany: {
        id: invoice.ownerCompany.id,
        address: ownerCompanyAddress === null ? '' : ownerCompanyAddress,
        name: invoice.ownerCompany.name,
        workPlaceAddress: invoice.ownerCompany.workPlaceAddress,
        cui: invoice.ownerCompany.cui,
        nrRegCom: invoice.ownerCompany.nrRegCom,
        bank: invoice.ownerCompany.bank,
        codIban: invoice.ownerCompany.codIban,
        vat: invoice.ownerCompany.vat,
        capitalSocial: invoice.ownerCompany.capitalSocial,
      },
      clientCompany: {
        id: invoice.clientCompany.id,
        address: clientCompanyAddress !== null ? clientCompanyAddress : '',
        name: invoice.clientCompany.name,
        workPlaceAddress: invoice.clientCompany.workPlaceAddress,
        cui: invoice.clientCompany.cui,
        nrRegCom: invoice.clientCompany.nrRegCom,
        color: invoice.clientCompany.color,
        bank: invoice.clientCompany.bank,
        vat: invoice.clientCompany.vat,
        capitalSocial: invoice.clientCompany.capitalSocial,
        codIban: invoice.clientCompany.codIban,
        collaborators: invoice.clientCompany.collaborators,
        packageType: invoice.clientCompany.packageType,
        workersType: invoice.clientCompany.workersType,
        einvoiceToken: invoice.clientCompany.einvoiceToken,
      },
      invoiceDate: new Date(invoice.invoiceDate),
      invoiceNumber: invoice.invoiceNumber,
      invoicePrefix: invoice.invoicePrefix,
      payType: invoice.payType,
      cases: uiCases,
      totalSum: totalSum,
      einvoiceId: invoice.einvoiceId,
      einvoiceSentDate: invoice.einvoiceSentDate,
    };
  }

  const updateInvoice = React.useCallback(
    (params: InvoiceUpdate) => {
      if (params.invoicePrefix !== undefined && params.invoiceNumber !== undefined) {
        return checkInvoiceNumberUseCase(repo, params.invoicePrefix, params.invoiceNumber).then(
          (resp) => {
            if (resp.statusCode && resp.data) {
              updateInvoiceUseCase(repo, params).then((invoice) => {
                if (invoice.statusCode === 200) {
                  return getInvoices();
                }
              });
            }
            return resp;
          },
        );
      } else {
        return updateInvoiceUseCase(repo, params).then((invoice) => {
          if (invoice.statusCode === 200) {
            return getInvoices();
          }
        });
      }
    },
    [repo.updateInvoice],
  );

  const deleteInvoice = React.useCallback(
    (id: number) => {
      return deleteInvoiceUseCase(repo, id).then((response) => {
        if (response) {
          return getInvoices();
        }
      });
    },
    [repo.updateInvoice],
  );

  const isEnabledEFacturaFeatureAction = (userRole: number) => {
    return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.EFACTURA_ACTION);
  };

  const getEfacturInvoiceStatusById = React.useCallback((id: number) => {
    return getStatusAnafFacturaUseCase(e_facturaRepo, id);
  }, []);

  const getEfacturInvoiceStatusByIdFromCache = React.useCallback((id: number) => {
    return getStatusAnafFacturaFromCacheUseCase(e_facturaRepo, id);
  }, []);

  return {
    getInvoices,
    getInvoiceById,
    updateInvoice,
    isEnabledEFacturaFeatureAction,
    getEfacturInvoiceStatusById,
    getEfacturInvoiceStatusByIdFromCache,
    deleteInvoice,
  };
};

export { useInvoiceViewModel };
