import React, { ReactElement } from 'react';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import styled from 'styled-components';

const ReactHint = ReactHintFactory(React);

export type SimpleHintProps = {
  icon: ReactElement<any, any>;
  hintText: string;
};

export const SimpleHint = (props: SimpleHintProps) => {
  const { icon, hintText } = props;

  return (
    <Container>
      <ReactHint autoPosition events delay={{ show: 100, hide: 100 }} />
      <CustomButton data-rh={`${hintText}`}>{icon}</CustomButton>
    </Container>
  );
};

const Container = styled.div`
  .react-hint__content {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
`;

const CustomButton = styled.button`
  background: none;
  padding: 0;
  border: none;
`;
